import * as React from "react";
import {Link} from "react-router-dom";
import * as B from "../../Models/BoardMember";
import BoardMember from "../General/BoardMember";

// component properties
export interface AdvisorsProps {
    waitHandler: any;
    errorHandler: any;
}

// component stats
export interface AdvisorsState {
    board:B.BoardMember[];
}

// Advisors component
class Advisors extends React.Component<AdvisorsProps, AdvisorsState> {
    constructor(props:AdvisorsProps, context:AdvisorsState) {
        super(props, context);
        this.state = { 
            board: []
        }
    }

    componentDidMount = () => {
        this.props.waitHandler(true);

        // fetch the guitar details
        fetch("/api/boardmember").then((res: any) => {
            if (!res.ok) {
                this.props.waitHandler(false);
                this.props.errorHandler(res.statusText);
            }
            else
                return res.json();
        }).then((board: B.BoardMember[]) => {
            if (board) {
                this.setState({board: board});
                this.props.waitHandler(false);
            }
        });
    };

    // renders the component
    render = () => {
        let members = this.state.board.map((member:B.BoardMember, index:number) => (
            <BoardMember boardMember={member}></BoardMember>
        ))

        return (
        <div className="page">
            <h1>Board of Advisors</h1>
            {members}
            <div>&nbsp;</div>
        </div>
        );
    };
}
 
export default Advisors;