import * as React from "react";

// component properties for the alert comonent
export interface AlertProps { 
    error:string;
    errorDismissHandler: any
}
 
// alert component for displaying error messages
class Alert extends React.Component<AlertProps> {
    // renders the component
    render() {
        return (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                {this.props.error}
                <button type="button" className="close" onClick={this.props.errorDismissHandler} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    };
}
 
export default Alert;