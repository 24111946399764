import * as React from "react";
 
// About component
class About extends React.Component {
    // renders the component
    render() {
        return (
            <>
                <p>As brilliant as Leo Fender was, he did not keep detailed shipping logs of guitars produced during the golden years. The purpose of GuitarLogs.com is to community-source data on early Fender musical instruments to gain (evidence-based) insights and dispel myths/speculations. Additional benefits include:</p>
                <ul>
                    <li>Detailed lineage of guitar ownership, history, and modifications</li>
                    <li>Interesting statistics of known Fenders models</li>
                    <li>Facilitating dealer and seller accountability</li>
                    <li>Quick identification of guitars offered for sale, stolen, or parted out</li>
                    <li>Non-profit marketplace for connecting owners and buyers</li>
                    <li>Comprehensive online gallery of the best guitars Fender ever made</li>
                </ul>
            </>
        );
    };
}
 
export default About;