import * as React from "react";
import {
    withRouter
} from "react-router-dom";
//import ReactAI from "react-appinsights";
//ReactAI.init({instrumentationKey:"1e1f575d-ff3e-4bcb-8575-22fe3e3962e9"});


// properties for the RouteListener component
export interface RouteListenerProps { 
    routeChangedHandler: any;
}

// RouteListener component
class RouteListener extends React.Component<any> {
    // componentWillMount
    componentWillMount() {
        let context = this;
        this.props.history.listen((location:any, action:any) => {
            context.props.routeChangedHandler();

            /*
            // Application Insights tracking
            var appInsights = (window as any).appInsights;
            var config = appInsights.config;
            console.log(config);
            config.enableDebug = true;
            config.verboseLogging = true;
            appInsights.config = config;
            var configData = {
                App: "BlackguardLogs.com"
            }
            appInsights.trackPageView(location.pathname, location.pathname, configData);
            */
        });
    };

    // renders the component
    render() {
        return (
            <div className="container page-inner">{this.props.children}</div>
        );
    };
}
 
export default withRouter(RouteListener);