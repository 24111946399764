import * as React from "react";
import ImageUploaderItem from "./ImageUploadItem";

// properties for the ImageUploader component
export interface ImageUploaderProps {
    immediateUpload:boolean;
    filesPicked:any;
    images: string[];
    refresh: any;
    multiple: boolean;
    header: string;
    allowDelete: boolean;
    onDelete:any;
    onReorder:any;
}
  
// state for the ImageUploader component
export interface ImageUploaderState {
    images: any[];
}
 
// ImageUploader
class ImageUploader extends React.Component<ImageUploaderProps, ImageUploaderState> {
    constructor(props:ImageUploaderProps, context:ImageUploaderState) {
        super(props, context);
        this.state = {
            images: this.props.images
        };
    };

    // componentWillReceiveProps 
    componentWillReceiveProps(props:ImageUploaderProps) {
        this.setState({ images: props.images});
    };

    // fires when files are picked from the file picker
    filesPicked(evt:any) {
        if (evt.target.files && evt.target.files) {
            let context = this;
            for (var i = 0; i < evt.target.files.length; i++) {
                let index = i;
                this.readFile(evt.target.files, index).then((result:any) => {
                    this.props.filesPicked(result);
                    if (!this.props.multiple) {
                        context.setState({images: [ result.photo ]});
                    }
                });
            }
        }
    };

    // reads the file as a base64 encoded string
    readFile(files:any, index:number) {
        return new Promise(async (resolve, reject) => {
            var reader = new FileReader();
            reader.onloadend = function() {
                resolve({ photo: reader.result, index: index, name: files[index].name, file: files[index]});
            }
            reader.readAsDataURL(files[index]);
        });
    };

    // renders the component
    render() {
        let itemStyle = (!this.props.multiple) ? {marginTop: "-204px", marginLeft: "-4px"} : null;
        let imgs = this.state.images.map((img:string, index:number) => {
            // determine disabled status
            //console.log(img);
            let disabled = (this.props.immediateUpload && img.indexOf("https://") == -1);
            return (<ImageUploaderItem image={img} onDelete={this.props.onDelete.bind(this, index)} onReorder={this.props.onReorder.bind(this)} allowDelete={this.props.allowDelete} style={itemStyle} index={index} size={this.state.images.length} disabled={disabled} />)
        });
        let img = null;
        if (!this.props.multiple) {
            img = imgs;
            imgs = null;
        }
        
        const headerText = (this.props.header != "") ? (<div className="img-uploader-header">Images</div>) : null;
        const containerStyle = (!this.props.multiple) ? {paddingLeft: "calc(50% - 110px)"} : null;
        const input = (this.props.multiple) ? 
            (<input type="file" className="img-uploader-file" onChange={this.filesPicked.bind(this)} accept="image/x-png,image/jpeg,image/webp,image/heic" multiple />) : 
            (<input type="file" className="img-uploader-file" onChange={this.filesPicked.bind(this)} accept="image/x-png,image/jpeg,image/webp,image/heic" />);
        return (
            <div className="img-uploader">
                {headerText}
                <div className="img-uploader-container" style={containerStyle}>
                    {imgs}
                    <div className="img-uploader-picker">
                        {input}
                        {img}
                    </div>
                </div>
            </div>
        );
    };
}
 
export default ImageUploader;