import * as React from "react";
import {
    RouteComponentProps,
    Link
} from "react-router-dom";
import { Gear } from "../../Models/Gear";
import { GearQuery } from "../../Models/GearQuery";
import { GearQueryResult } from "../../Models/GearQueryResult";
import { ModelConfig } from "../../Models/ModelConfig";
 
// component properties
export interface ResultsProps extends RouteComponentProps {
    waitHandler: any;
    errorHandler: any
}

// component state
export interface ResultsState {
    makes: string[];
    gear: Gear[];
    viewTable:boolean;
    showFilters:boolean;
    gearQuery:GearQuery;
    filtersDirty:boolean;
    totalResults:number;
    models:ModelConfig[];
    filterText:string;
}

// Results component for listing gear
class Results extends React.Component<ResultsProps, ResultsState> {
    username = (window as any).username;
    search:string = null;
    constructor(props:ResultsProps, context:ResultsState) {
        super(props, context);
        this.state = {
            makes: [],
            gear: [],
            viewTable: false,
            showFilters: false,
            gearQuery: { 
                search: "",
                category: "",
                make: "",
                model: "",
                yearMin: null,
                yearMax: null,
                page: 1,
                status: "",
                collection: "",
                hasPics: false,
                famous: false,
                serial: "",
                serialMin: "",
                serialMax: "",
                sortBy: ""
            },
            filtersDirty: false,
            totalResults: 0,
            models: null,
            filterText: ""
        } 
    };

    // componentDidMount
    componentDidMount() {
        this.props.waitHandler(true);
        
        // get all model information for filtering
        let context = this;
        fetch("/api/modelconfig/lite").then((res: any) => {
            if (!res.ok) {
                this.props.waitHandler(false);
                this.props.errorHandler(res.statusText);
            }
            else
                return res.json();
        }).then((models: ModelConfig[]) => {
            if (models) {
                let makes = models.map(item => item.make)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .sort();

                // save state
                context.setState({
                    models: models,
                    makes: makes
                });

                // query the gear
                context.queryGear();
            }
        });
    };

    // componentWillReceiveProps...load is done here to handle different filters
    componentWillReceiveProps(nextProps:any) {
        if (nextProps.location.search != this.search) {
            this.search = nextProps.location.search;
            this.props.waitHandler(true);
            this.queryGear();
        }
    };

    queryGear = () => {
        // determine parameters and query gear
        let urlParams = new URLSearchParams(window.location.search);
        let gearQuery = { 
            search: "",
            category: "",
            make: "",
            model: "",
            yearMin: null,
            yearMax: null,
            page: 1,
            status: "",
            collection: "",
            hasPics: false,
            famous: false,
            serial: "",
            serialMin: "",
            serialMax: "",
            sortBy: ""
        };
        let filterText = "";
        if (urlParams.has("search")) {
            gearQuery.search = urlParams.get("search");
            filterText += `Search: ${gearQuery.search}, `;
        }
        if (urlParams.has("collection")) {
            gearQuery.collection = urlParams.get("collection");
            filterText += `Collection: ${gearQuery.collection}, `;
        }
        if (urlParams.has("category")) {
            gearQuery.category = urlParams.get("category");
            filterText += `Category: ${gearQuery.category}, `;
        }
        if (urlParams.has("make")) {
            gearQuery.make = urlParams.get("make");
            filterText += `Make: ${gearQuery.make}, `;
        }
        if (urlParams.has("model")) {
            gearQuery.model = urlParams.get("model");
            filterText += `Model: ${gearQuery.model}, `;
        }
        if (urlParams.has("yearMin")) {
            gearQuery.yearMin = parseInt(urlParams.get("yearMin"));
            filterText += `Year (min): ${gearQuery.yearMin}, `;
        }
        if (urlParams.has("yearMax")) {
            gearQuery.yearMax = parseInt(urlParams.get("yearMax"));
            filterText += `Year (max): ${gearQuery.yearMax}, `;
        }
        if (urlParams.has("page")) {
            gearQuery.page = parseInt(urlParams.get("page"));
        }
        if (urlParams.has("status")) {
            gearQuery.status = urlParams.get("status");
            filterText += `Status: ${gearQuery.status}, `;
        }
        if (urlParams.has("hasPics")) {
            gearQuery.hasPics = true;
            filterText += `Has pics, `;
        }
        if (urlParams.has("famous")) {
            gearQuery.famous = true;
            filterText += `Famous, `;
        }
        if (urlParams.has("serial")) {
            gearQuery.serial = urlParams.get("serial");
            filterText += `Serial: ${gearQuery.serial}, `;
        }
        if (urlParams.has("serialmin")) {
            gearQuery.serial = urlParams.get("serialmin");
            filterText += `Serial (min): ${gearQuery.serialMin}, `;
        }
        if (urlParams.has("serialmax")) {
            gearQuery.serial = urlParams.get("serialmax");
            filterText += `Serial (max): ${gearQuery.serialMax}, `;
        }
        if (urlParams.has("sortBy")) {
            gearQuery.sortBy = urlParams.get("sortBy");
            let sortField = gearQuery.sortBy.substr(0, gearQuery.sortBy.lastIndexOf(' '));
            switch (sortField) {
                case "createdDate":
                    sortField = "Date added";
                    break;
                case "serialNumber":
                    sortField = "Serial number";
                    break;
                case "year":
                    sortField = "Year";
                    break;
            }
            let direction = gearQuery.sortBy.substr(gearQuery.sortBy.lastIndexOf(' '));
            let directionSym = (direction == " ASC") ? "↑" : "↓";
            filterText += `Sort: ${sortField} ${directionSym}, `;
        }
        if (filterText.length > 0)
            filterText = filterText.substr(0, filterText.length - 2);

        let context = this;
        fetch("/api/gear/query", {
            method: "POST",
            body: JSON.stringify(gearQuery),
            headers: {
                "accept": "application/json",
                "content-type": "application/json"
            }
        }).then(function(res) {
            if (!res.ok) {
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            else {
                return res.json();
            }
        }).then((result:GearQueryResult) => {
            context.setState({
                gear: result.results,
                totalResults: result.totalResults,
                filterText: filterText,
                gearQuery: gearQuery
            });
            this.props.waitHandler(false);
        });
    };

    // builds query string for applied filters
    buildQueryString = (page: number) => {
        let queryString = `/gear?page=${page}`;
        let gearQuery = this.state.gearQuery;
        if (gearQuery.search && gearQuery.search.length > 0)
            queryString += `&search=${gearQuery.search}`;
        if (gearQuery.category && gearQuery.category.length > 0)
            queryString += `&category=${gearQuery.category}`;
        if (gearQuery.make && gearQuery.make.length > 0)
            queryString += `&make=${gearQuery.make}`;
        if (gearQuery.model && gearQuery.model.length > 0)
            queryString += `&model=${gearQuery.model}`;
        if (gearQuery.yearMin && gearQuery.yearMin > 0)
            queryString += `&yearMin=${gearQuery.yearMin}`;
        if (gearQuery.yearMax && gearQuery.yearMax > 0)
            queryString += `&yearMax=${gearQuery.yearMax}`;
        if (gearQuery.status && gearQuery.status.length > 0)
            queryString += `&status=${gearQuery.status}`;
        if (gearQuery.collection && gearQuery.collection.length > 0)
            queryString += `&collection=${gearQuery.collection}`;
        if (gearQuery.hasPics)
            queryString += "&hasPics=true";
        if (gearQuery.famous)
            queryString += "&famous=true";
        if (gearQuery.serial && gearQuery.serial.length > 0)
            queryString += `&serial=${gearQuery.serial}`;
        if (gearQuery.sortBy && gearQuery.sortBy.length > 0)
            queryString += `&sortBy=${gearQuery.sortBy}`;
            
        return queryString;
    }

    filterApplied(filter:string) : boolean {
        return true; //TODO
    };

    updateFilters() {

    };

    // applies any filter changes into a query 
    applyFilters = () => {
        // update for selected
        let url = this.buildQueryString(1);
        this.props.history.push(url);
    };

    // toggles between table and gallery views
    toggleView() {
        this.setState({viewTable: !this.state.viewTable});
    };

    // handles min/max year updates
    handleMinMaxYear = (isMin:boolean, evt:any) => {
        let gearQuery = this.state.gearQuery;
        if (isMin) 
            gearQuery.yearMin = parseInt(evt.target.value);
        else
            gearQuery.yearMax = parseInt(evt.target.value);
        this.setState({gearQuery: gearQuery, filtersDirty: true});
    };

    // handles changes to serial number look-up
    handleSerial = (evt:any) => {
        let val = evt.target.value;
        this.setState(prevState => ({gearQuery: { ...prevState.gearQuery, serial: val}, filtersDirty: true}))
    };

    // handle sort
    handleSort = (evt:any) => {
        let val = evt.target.value;
        this.setState(prevState => ({gearQuery: { ...prevState.gearQuery, sortBy: val}, filtersDirty: true}))
    };

    // renders the component
    render() {
        if (!this.state.gear)
            return (<></>);
        let rows:any = this.state.gear.map((gear:Gear, index:number) => (
            <tr>
                <td><Link to={"/gear/detail/" + gear.id}>{gear.serialNumber}</Link></td>
                <td>{gear.year}</td>
                <td>{gear.make}</td>
                <td>{gear.model}</td>
                <td><span>{(gear.ownedBy && gear.ownedBy != "") ? (<Link to={`/profile/${gear.ownedBy}`}>{gear.ownedBy}</Link>) : "Unknown"}&nbsp;{(this.username && this.username != "") ? (<span>(<Link to={`/gear/claim/${gear.id}`}>claim</Link>)</span>) : ""}</span></td>
            </tr>
            ));
            if (this.state.gear.length == 0)
                rows = (<tr><td colSpan={4} style={{textAlign: "center"}}>No results returned</td></tr>);
   
            let cards:any = this.state.gear.map((gear:Gear, index:number) => (
                <div className="col-lg-3 col-md-4 col-6">
                    <Link to={`/gear/detail/${gear.id}`} className="d-block tile-img" style={{backgroundImage: (gear.photos[0]) ? `url(${(gear.applyWatermark) ? ("/api/images/" + gear.id + "/thumbnail/" + gear.photos[0]) : ("https://guitarlogsv2.blob.core.windows.net/gear/" + gear.id + "/thumb_" + gear.photos[0])})` : "url(/images/nopic.png)"}}></Link>
                    <div className="gallery-text mb-4">
                        <div className="serial"><Link to={`/gear/detail/${gear.id}`}>{gear.serialNumber}</Link>&nbsp;{(gear.status == "For sale" ? <span style={{fontSize: "10px", marginTop: "11px", verticalAlign: "top"}} className="badge badge-pill badge-success">For sale</span> : <></>)}</div>
                        <div className="ymodel"><Link to={`/gear/detail/${gear.id}`}>{(gear.year) ? `${gear.year} ${gear.make} ${gear.model}` : `${gear.make} ${gear.model}`}</Link></div>
                    </div>
                </div>
            ));
            if (this.state.gear.length == 0) {
                cards = (
                <div className="col-lg-3 col-md-4 col-6">
                    <h4>No results</h4>
                </div>
                )
            }
   
            let filterText = this.state.filterText;

            // build model filter
            let makeModel = [];
            if (this.state.models && this.state.gearQuery.make && this.state.gearQuery.make.length > 0) {
                // get models for this make
                let models = this.state.models.filter(i => i.make == this.state.gearQuery.make)
                    .sort((a,b) => { 
                        let fa = a.model.toLowerCase(),
                        fb = b.model.toLowerCase();

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                makeModel.push(
                    <div className="form-check">    
                        <input type="checkbox" className="form-check-input" id={"ctrl" + this.state.gearQuery.make} name={this.state.gearQuery.make} checked={true} onChange={() => this.setState(prevState => ({gearQuery: { ...prevState.gearQuery, make: null}}), this.applyFilters) } />
                        <label className="form-check-label" htmlFor={"ctrl" + this.state.gearQuery.make}>{this.state.gearQuery.make}</label>
                    </div>
                );
                if (this.state.gearQuery.model && this.state.gearQuery.model.length > 0) {
                    makeModel.push(
                        <div className="form-check form-check-sub">
                            <input type="checkbox" className="form-check-input" id={"ctrl" + this.state.gearQuery.model} name={this.state.gearQuery.model} checked={true} onChange={() => this.setState(prevState => ({gearQuery: { ...prevState.gearQuery, model: null}}), this.applyFilters) } />
                            <label className="form-check-label" htmlFor={"ctrl" + this.state.gearQuery.model}>{this.state.gearQuery.model}</label>
                        </div>
                    );
                }
                else {
                    models.forEach((model:ModelConfig, index:number) => {
                        makeModel.push(
                            <div className="form-check form-check-sub">
                                <input type="checkbox" className="form-check-input" id={"ctrl" + model.model} name={model.model} checked={this.state.gearQuery.model == model.model} onChange={() => this.setState(prevState => ({gearQuery: { ...prevState.gearQuery, model: model.model}}), this.applyFilters) } />
                                <label className="form-check-label" htmlFor={"ctrl" + model.model}>{model.model}</label>
                            </div>
                        );
                    });
                }
            }
            else if (this.state.models) {
                makeModel = this.state.makes.map((make:string, index: number) => (
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id={"ctrl" + make} name={make} checked={false} onChange={() => this.setState(prevState => ({gearQuery: { ...prevState.gearQuery, make: make}}), this.applyFilters) } />
                        <label className="form-check-label" htmlFor={"ctrl" + make}>{make}</label>
                    </div>
                ))
            }

            let paging = [];
            let totalPages = Math.ceil(this.state.totalResults / 48);
            let pointer = this.state.gearQuery.page;
            // add the current page
            paging.push(<li className="page-item active"><a className="page-link" href="#">{pointer}</a></li>)
            
            // add previous pages
            if (pointer > 1)
                paging.splice(0, 0, <li className="page-item"><a className="page-link" href={this.buildQueryString(pointer - 1)}>{pointer - 1}</a></li>)
            if (pointer > 2)
                paging.splice(0, 0, <li className="page-item"><a className="page-link" href={this.buildQueryString(pointer - 2)}>{pointer - 2}</a></li>)
            if (pointer > 3)
                paging.splice(0, 0, <li className="page-item disabled"><a className="page-link" href="#" aria-disabled="true">...</a></li>)
            paging.splice(0, 0, (pointer == 1) ? (<li className="page-item disabled"><a className="page-link" href="#" aria-disabled="true">&lt;&nbsp;Prev</a></li>) : (<li className="page-item"><a className="page-link" href={this.buildQueryString(pointer - 1)}>&lt;&nbsp;Prev</a></li>));
            paging.splice(0, 0, (pointer == 1) ? (<li className="page-item disabled"><a className="page-link" href="#" aria-disabled="true">First</a></li>) : (<li className="page-item"><a className="page-link" href={this.buildQueryString(1)}>First</a></li>));

            // add next pages
            if ((pointer + 1) <= totalPages)
                paging.push(<li className="page-item"><a className="page-link" href={this.buildQueryString(pointer + 1)}>{pointer + 1 }</a></li>)
            if ((pointer + 2) <= totalPages)
                paging.push(<li className="page-item"><a className="page-link" href={this.buildQueryString(pointer + 2)}>{pointer + 2 }</a></li>)
            if ((pointer + 3) <= totalPages)
                paging.push(<li className="page-item disabled"><a className="page-link" href="#" aria-disabled="true">...</a></li>)
            paging.push((pointer == totalPages) ? (<li className="page-item disabled"><a className="page-link" href="#" aria-disabled="true">Next&nbsp;&gt;</a></li>) : (<li className="page-item"><a className="page-link" href={this.buildQueryString(pointer + 1)}>Next&nbsp;&gt;</a></li>));
            paging.push((pointer == totalPages) ? (<li className="page-item disabled"><a className="page-link" href="#" aria-disabled="true">Last</a></li>) : (<li className="page-item"><a className="page-link" href={this.buildQueryString(totalPages)}>Last</a></li>));

            let filters = (
            <div className="data-filters">
                <div className="data-filters-shaddow" onClick={() => this.setState({showFilters: false})}></div>
                <div className="data-filters-header">
                    <h3>Filter Your Search</h3>
                </div>
                <div className="data-filters-body">
                    <h4>Make/Model</h4>
                    {makeModel}

                    <h4>Year</h4>
                    <div className="input-group">
                        <input type="number" aria-label="Min" className="form-control" placeholder="Min" value={this.state.gearQuery.yearMin} onChange={this.handleMinMaxYear.bind(this, true)} />
                        <input type="number" aria-label="Max" className="form-control" placeholder="Max" value={this.state.gearQuery.yearMax} onChange={this.handleMinMaxYear.bind(this, false)} />
                    </div>

                    <h4>Serial</h4>
                    <div className="input-group">
                        <input type="text" aria-label="Min" className="form-control" placeholder="Serial number" value={this.state.gearQuery.serial} onChange={this.handleSerial.bind(this)} />
                    </div>

                    <h4>Other</h4>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="ctrlForsale" name="forSale" checked={this.state.gearQuery.status == "For sale"} onChange={() => this.setState(prevState => ({gearQuery: { ...prevState.gearQuery, status: ((this.state.gearQuery.status == "For sale") ? "" : "For sale")}, filtersDirty: true}), this.applyFilters)} />
                        <label className="form-check-label" htmlFor="ctrlForsale">For sale</label>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="ctrlHasPics" name="hasPics" checked={this.state.gearQuery.hasPics} onChange={() => this.setState(prevState => ({gearQuery: { ...prevState.gearQuery, hasPics: !this.state.gearQuery.hasPics}, filtersDirty: true}), this.applyFilters)} />
                        <label className="form-check-label" htmlFor="ctrlHasPics">Has pics</label>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="ctrlFamous" name="famous" checked={this.state.gearQuery.famous} onChange={() => this.setState(prevState => ({gearQuery: { ...prevState.gearQuery, famous: !this.state.gearQuery.famous}, filtersDirty: true}), this.applyFilters)} />
                        <label className="form-check-label" htmlFor="ctrlFamous">Famous</label>
                    </div>

                    <h4>Sort By</h4>
                    <div>
                        <select className="form-control" id="ctrlSortBy" name="sortBy" value={this.state.gearQuery.sortBy} onChange={this.handleSort.bind(this)}>
                            <option></option>
                            <option value="year ASC">Year (oldest first)</option>
                            <option value="year DESC">Year (newest first)</option>
                            <option value="serialNumber ASC">Serial number (lowest first)</option>
                            <option value="serialNumber DESC">Serial number (highest first)</option>
                            <option value="createdDate DESC">Date added (new to old)</option>
                            <option value="createdDate ASC">Date added (old to new)</option>
                        </select>
                    </div>
                </div>
                <div className="data-filters-footer">
                    <button className="btn btn-primary btn-block" onClick={this.applyFilters.bind(this)} disabled={!this.state.filtersDirty}>View Results</button>
                </div>
           </div>
           );
           if (!this.state.showFilters)
               filters = (<span></span>);
           
   
           if (this.state.viewTable) {
               return (
               <div className="page">
                   <h1>The Logs</h1>
                   <div className="list-toolbar">
                       <div className="views">
                           <button className="btn btn-outline-secondary" onClick={this.toggleView.bind(this)}><i className="fas fa-th"></i></button>
                           <button className="btn btn-secondary" onClick={this.toggleView.bind(this)}><i className="fas fa-bars"></i></button>
                       </div>
                       <div className="filters">
                           <span style={{paddingRight: "10px"}}>{filterText}</span>
                           <button className="btn btn-outline-secondary" onClick={() => this.setState({showFilters: !this.state.showFilters})}><i className="fas fa-filter"></i></button>
                           {filters}
                       </div>
                   </div>
                   <table className="table table-striped table-hover">
                       <thead>
                           <tr>
                               <th style={{width: "15%"}}>Serial</th>
                               <th style={{width: "15%"}}>Year</th>
                               <th style={{width: "20%"}}>Make</th>
                               <th style={{width: "20%"}}>Model</th>
                               <th style={{width: "30%"}}>Owner</th>
                           </tr>
                       </thead>
                       <tbody>
                           {rows}
                       </tbody>
                   </table>
                   <div className="row justify-content-center">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                {paging}
                            </ul>
                        </nav>
                   </div> 
                   <div>&nbsp;</div>
               </div>
               );
           }
           else {
               return (
               <div className="page">
                   <h1>The Logs</h1>
                   <div className="list-toolbar">
                       <div className="views">
                           <button className="btn btn-secondary" onClick={this.toggleView.bind(this)}><i className="fas fa-th"></i></button>
                           <button className="btn btn-outline-secondary" onClick={this.toggleView.bind(this)}><i className="fas fa-bars"></i></button>
                           <div style={{display: "inline-block", paddingLeft: "5px"}}>{this.state.totalResults} Results</div>
                       </div>
                       <div className="filters">
                       <span style={{paddingRight: "10px"}}>{filterText}</span>
                           <button className="btn btn-outline-secondary" onClick={() => this.setState({showFilters: !this.state.showFilters})}><i className="fas fa-filter"></i></button>
                           {filters}
                       </div>
                   </div>
                   <div className="row text-center text-lg-left">
                       {cards}
                   </div>
                   <div className="row justify-content-center">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                {paging}
                            </ul>
                        </nav>
                   </div>
               </div>
               );
           }
    };
}
 
export default Results;