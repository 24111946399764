import * as React from "react";
import {
    RouteComponentProps,
    Link
} from "react-router-dom";
import { Account } from "../../Models/Account";
import { Gear } from "../../Models/Gear";
import { GearClaim } from "../../Models/GearClaim";
import { InfoSubmission } from "../../Models/InfoSubmission";

// properties for the Approvals component
export interface ApprovalsProps extends RouteComponentProps<any> {
    waitHandler: any;
    errorHandler: any;
}

// state for the Approvals component
export interface ApprovalsState {
    submissions: Gear[];
    submissionsLoaded: boolean;
    claims: GearClaim[];
    claimsLoaded: boolean;
    info: InfoSubmission[];
    infoLoaded: boolean;
}

// the Approvals component
class Approvals extends React.Component<ApprovalsProps, ApprovalsState> {
    // get the username of the user off the window object
    username = (window as any).username;
    user = (window as any).user;
    constructor(props:ApprovalsProps, context:ApprovalsState) {
        super(props, context);
        this.state = {
            submissions: [],
            submissionsLoaded: false,
            claims: [],
            claimsLoaded: false,
            info: [],
            infoLoaded: false
        };
    }

    // componentDidMount function to load approvals
    componentDidMount() {
        if (!this.user || !this.user.IsAdmin) { 
            this.props.history.replace("/"); 
        }
        else {
            this.props.waitHandler(true);

            // make request for submissions
            fetch("/api/gear/admin/approvals").then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((submissions: Gear[]) => {
                this.setState({submissions: submissions, submissionsLoaded: true});
                if (this.state.claimsLoaded && this.state.infoLoaded)
                    this.props.waitHandler(false);
            });

            // make request for claims
            fetch("/api/gear/admin/claims").then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((claims: GearClaim[]) => {
                this.setState({claims: claims, claimsLoaded: true});
                if (this.state.submissionsLoaded && this.state.infoLoaded)
                    this.props.waitHandler(false);
            });

            // make request for all submitted info
            fetch("/api/info").then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((info: InfoSubmission[]) => {
                this.setState({info: info, infoLoaded: true});
                if (this.state.submissionsLoaded && this.state.claimsLoaded)
                    this.props.waitHandler(false);
            });
        }
    };

    // called to approve/reject log submissions
    approveSubmission(approved:boolean, index:number) {
        this.props.waitHandler(true);
        var item = this.state.submissions[index];

        // set approvalStatus (1 for approve and 2 for reject)
        item.approvalStatus = (approved) ? 1 : 2;

        // submit the creation
        let context = this;
        fetch(`/api/gear/${item.id}/admin/approvals`, {
            method: "POST",
            body: JSON.stringify(item),
            headers: {
                "accept": "application/json",
                "content-type": "application/json"
            }
        }).then(function(res) {
            if (!res.ok) {
                // save failed...show error message
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            else {
                // save was successful...remove item from index
                context.props.waitHandler(false);
                context.props.errorHandler(null);
                
                var submissions = context.state.submissions;
                submissions.splice(index, 1);
                context.setState({submissions: submissions});
            }
        });
    };

    // called to approve/reject ownership claims
    approveClaim(approved:boolean, index:number) {
        this.props.waitHandler(true);
        var claim = this.state.claims[index];

        // set approvalStatus (1 for approve and 2 for reject)
        claim.approvalStatus = (approved) ? 1 : 2;

        // submit the creation
        let context = this;
        fetch(`/api/gear/${claim.id}/admin/claims`, {
            method: "POST",
            body: JSON.stringify(claim),
            headers: {
                "accept": "application/json",
                "content-type": "application/json"
            }
            }).then(function(res) {
            if (!res.ok) {
                // save failed...show error message
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            else {
                // save was successful...remove the item from the index
                context.props.waitHandler(false);
                context.props.errorHandler(null);
                
                var claims = context.state.claims;
                claims.splice(index, 1);
                context.setState({claims: claims});
            }
        });
    };

    // renders the component
    render() {
        if (!this.state.submissionsLoaded && !this.state.claimsLoaded && !this.state.infoLoaded)
            return (<></>);

        // render new submissions
        let submissions:any = this.state.submissions.map((gear:Gear, index) => (
            <tr>
                <td><Link to={`/gear/detail/${gear.id}?approvals=true`}>{gear.year}&nbsp;{gear.make}&nbsp;{gear.model}&nbsp;({gear.serialNumber})</Link></td>
                <td><Link to={`/profile/${gear.createdBy}`}>{gear.createdBy}</Link></td>
                <td>{(gear.source.indexOf('https://') != -1) ? (<a href={gear.source}>Link</a>) : (<span>{gear.source}</span>)}</td>
                <td style={{padding: "8px"}}><button className="btn btn-link" onClick={this.approveSubmission.bind(this, true, index)}>Approve</button> / <button className="btn btn-link" onClick={this.approveSubmission.bind(this, false, index)}>Reject</button></td>
            </tr>
        ));
        if (this.state.submissions.length == 0)
            submissions = (<tr><td colSpan={4} style={{textAlign: "center"}}>No submission approvals exist</td></tr>);

        // render new ownership claims
        let claims:any = this.state.claims.map((claim:GearClaim, index) => (
            <tr>
                <td><Link to={`/gear/detail/${claim.gearId}?approvals=true`}>{claim.year}&nbsp;{claim.make}&nbsp;{claim.model}&nbsp;({claim.serialNumber})</Link></td>
                <td><Link to={`/profile/${claim.createdBy}`}>{claim.createdBy}</Link></td>
                <td><a href={claim.validationSource}>Link</a></td>
                <td style={{padding: "8px"}}><button className="btn btn-link" onClick={this.approveClaim.bind(this, true, index)}>Approve</button> / <button className="btn btn-link" onClick={this.approveClaim.bind(this, false, index)}>Reject</button></td>
            </tr>
        ));
        if (this.state.claims.length == 0)
            claims = (<tr><td colSpan={4} style={{textAlign: "center"}}>No claims approvals exist</td></tr>);

        // render information submitted
        let info:any = this.state.info.map((infoItem:InfoSubmission, index:number) => (
            <tr style={(infoItem.read) ? {fontWeight: "normal"} : {fontWeight: "bold"} }>
                <td><Link to={`/gear/admin/${infoItem.gearId}?approvals=true`}>{infoItem.year}&nbsp;{infoItem.make}&nbsp;{infoItem.model}&nbsp;({infoItem.serialNumber})</Link></td>
                <td>{new Date(infoItem.createdDate).toLocaleString()}</td>
            </tr>
        ));
        if (this.state.info.length == 0)
            info = (<tr><td colSpan={2} style={{textAlign: "center"}}>No info exist</td></tr>);
        
        return (
        <div className="page">
            <h1>Submissions</h1>
            <h3>New Entries</h3>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th style={{width: "30%"}}>Submission</th>
                    <th style={{width: "20%"}}>By</th>
                    <th style={{width: "30%"}}>Validation Source</th>
                    <th style={{width: "20%"}}>Action</th>
                </tr>
                </thead>
                <tbody>
                {submissions}
                </tbody>
            </table>
            <h3>Ownership Claims</h3>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th style={{width: "30%"}}>Submission</th>
                    <th style={{width: "20%"}}>By</th>
                    <th style={{width: "30%"}}>Validation Source</th>
                    <th style={{width: "20%"}}>Action</th>
                </tr>
                </thead>
                <tbody>
                {claims}
                </tbody>
            </table>
            <h3>Unsolicited Info</h3>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th style={{width: "70%"}}>Submission</th>
                    <th style={{width: "30%"}}>Date</th>
                </tr>
                </thead>
                <tbody>
                {info}
                </tbody>
            </table>
            <div>&nbsp;</div>
        </div>
        );
    };
}
 
export default Approvals;