import * as React from "react";
import {
    RouteComponentProps
} from "react-router-dom";
import { ModelConfig } from "../../Models/ModelConfig";

// component properties
export interface ModelsProps extends RouteComponentProps<any> {
    waitHandler: any;
    errorHandler: any;
}

// component stats
export interface ModelsState {
    models: ModelConfig[];
    editModel: ModelConfig;
    editIndex: number;
}

// Models component
class Models extends React.Component<ModelsProps, ModelsState> {
    username = (window as any).username;
    user = (window as any).user;
    constructor(props:ModelsProps, context:ModelsState) {
        super(props, context);
        this.state = {
            models: [],
            editModel: {category: "", make: "", model: "", years: []},
            editIndex: -1
        };
    };

    // componentDidMount
    componentDidMount() {
        if (!this.user || !this.user.IsAdmin) { 
            this.props.history.replace("/"); 
        }
        else {
            this.props.waitHandler(true);

            // fetch the guitar details
            fetch("/api/modelconfig").then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((models: ModelConfig[]) => {
                if (models) {
                    this.setState({models: models});
                    this.props.waitHandler(false);
                }
            });
        }
    };

    navModel = (index:number) => {
        this.props.history.push(`/admin/models/${this.state.models[index].id}`);
    };

    close = ()  => {
        ($ as any)("#modalConfirm").modal("hide");

        var context = this;
        $("#modalConfirm").on('hidden.bs.modal', function (e) {
            context.props.waitHandler(false);
            context.props.errorHandler(null);
        });
    };

    addModel = () => {
        ($ as any)("#modalConfirm").modal({backdrop: "static"}, "show");
    };

    save = () => {
        // start waiting indicator
        this.props.waitHandler(true);
        let editModel = this.state.editModel;

        // create
        editModel.years = [];
        let context = this;
        fetch(`/api/modelconfig`, {
            method: "POST",
            body: JSON.stringify(editModel),
            headers: {
                "accept": "application/json",
                "content-type": "application/json"
            }
        }).then(function(res) {
            if (!res.ok) {
                // save failed...show error message
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            else {
                return res.json();
            }
        }).then(function(model:ModelConfig) {
            // add to the model
            let models = context.state.models;
            models.push(model);
            context.setState({models: models, editModel: {category: "", make: "", model: "", years: []}});

            context.props.waitHandler(false);
            context.props.errorHandler(null);
            ($ as any)("#modalConfirm").modal("hide");

            // navigate to the new model
            this.props.history.push(`/admin/models/${model.id}`);
        });
    };

    handleInputChange = (event:any) => {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        let editModel = this.state.editModel;
        if (event.target.type == "checkbox") {
            fieldVal = event.target.checked;
        }

        editModel[fieldName] = fieldVal;
        this.setState({editModel: editModel});
    };

    // renders the component
    render() {
        let rows:any = this.state.models.map((model:any, index:number) => (
        <tr onClick={this.navModel.bind(this, index)} style={{cursor: "pointer"}}>
            <td>{model.category}</td>
            <td>{model.make}</td>
            <td>{model.model}</td>
        </tr>
        ));
        if (this.state.models.length == 0)
            rows = (<tr><td colSpan={4} style={{textAlign: "center"}}>No models exist</td></tr>);

        return (
        <div className="page">
            <h1>Models</h1>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Make</th>
                        <th>Model</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3} style={{textAlign: "center", cursor: "pointer"}} onClick={this.addModel.bind(this)}>
                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Add Model
                        </td>
                    </tr>
                    {rows}
                </tbody>
            </table>
            <div>&nbsp;</div>

            <div className="modal fade" id="modalConfirm" role="dialog" aria-labelledby="modalConfirmTitle" aria-hidden="false">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalConfirmTitle">Add Model</h5>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                    <label htmlFor="ctlCategory">Category&nbsp;</label>
                                </div>
                                <div className="col-xl-8 col-lg-7 col-md-6">
                                    <select className="form-control" id="ctlCategory" name="category" value={this.state.editModel.category} onChange={this.handleInputChange.bind(this)} tabIndex={1} >
                                        <option></option>
                                        <option value="Electric Guitars">Electric Guitars</option>
                                        <option value="Loose Serial Numbers">Loose Serial Numbers</option>
                                        <option value="Bass Guitars">Bass Guitars</option>
                                        <option value="Acoustic Guitars">Acoustic Guitars</option>
                                        <option value="Amplifiers">Amplifiers</option>
                                        <option value="Pedals">Pedals</option>
                                    </select>
                                    <div className="invalid-feedback">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                    <label htmlFor="ctlMake">Make&nbsp;</label>
                                </div>
                                <div className="col-xl-8 col-lg-7 col-md-6">
                                    <input type="text" className="form-control" id="ctlMake" placeholder="Enter a make" name="make" value={this.state.editModel.make} onChange={this.handleInputChange.bind(this)} tabIndex={2} />
                                    <div className="invalid-feedback">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                    <label htmlFor="ctlModel">Model&nbsp;</label>
                                </div>
                                <div className="col-xl-8 col-lg-7 col-md-6">
                                    <input type="text" className="form-control" id="ctlModel" placeholder="Enter a model" name="model" value={this.state.editModel.model} onChange={this.handleInputChange.bind(this)} tabIndex={3} />
                                    <div className="invalid-feedback">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.save.bind(this)}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={this.close.bind(this)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    };
}
 
export default Models;