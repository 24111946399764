import * as React from "react";
import {
    RouteComponentProps
} from "react-router-dom";
import Thread from "./Thread";

// component properties
export interface ThreadsProps extends RouteComponentProps<any> {
    waitHandler: any;
    errorHandler: any;
}

// component stats
export interface ThreadsState {
    threads: Thread[];
}

// Threads component
class Threads extends React.Component<ThreadsProps, ThreadsState> {
    username = (window as any).username;
    constructor(props:ThreadsProps, context:ThreadsState) {
        super(props, context);
        this.state = {
            threads: []
        };
    };

    // componentDidMount
    componentDidMount() {
        // ensure user context
        if (!this.username) { 
            this.props.history.replace("/"); 
        }
        else {
            this.props.waitHandler(true);
            // fetch the guitar details
            fetch("/api/thread").then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((threads: Thread[]) => {
                if (threads) {
                    this.setState({threads: threads});
                    this.props.waitHandler(false);
                }
            });
        }
    };

    // fires when thread selected
    threadSelected(id:string) {
        this.props.history.push(`/threads/detail/${id}`);
    };

    // renders the component
    render() {
        let rows:any = this.state.threads.map((thread:any) => (
        <tr onClick={this.threadSelected.bind(this, thread.id)} style={{cursor: "pointer", fontWeight: ((thread.messages[0].senderUsername == this.username || thread.messages[0].read) ? "normal" : "bold")}}>
            <td>{(thread.messages[0].senderUsername == this.username || thread.messages[0].read) ? (<i className="far fa-envelope-open"></i>) : (<i className="far fa-envelope"></i>)}</td>
            <td>{(this.username == thread.senderUsername) ? thread.recipientUsername : thread.senderUsername}</td>
            <td>{thread.subject}</td>
            <td>{(new Date(thread.modifiedDate)).toLocaleString()}</td>
        </tr>
        ));
        if (this.state.threads.length == 0)
            rows = (<tr><td colSpan={4} style={{textAlign: "center"}}>No threads exist</td></tr>);

        return (
        <div className="page">
            <h1>Threads</h1>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th style={{width: "20px"}}></th>
                        <th>Member</th>
                        <th>Subject</th>
                        <th>Last Message</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            <div>&nbsp;</div>
        </div>
        );
    };
}
 
export default Threads;