import * as React from "react";
import { Link } from "react-router-dom";
import Cookie from "universal-cookie";
 
// alert component for displaying error messages
class UpgradeWarning extends React.Component {
    private cookie:Cookie;
    componentDidUpdate = () => {
        this.cookie = new Cookie();
        setTimeout(() => {
            if (!this.cookie.get("upgrade-warned-cookie"))
                ($ as any)("#upgradeWarningModal").modal({backdrop: "static"}, "show");
        }, 1000);
    };

    accept = () => {
        // write the cookie and close the warning
        this.cookie.set("upgrade-warned-cookie", "true", { path: '/' });
        ($ as any)("#upgradeWarningModal").modal("hide");
        this.setState({});
    };

    // renders the component
    render() {
        if (!this.cookie)
            return (<></>);

        // look for accepted cookie
        let accepted = this.cookie.get("upgrade-warned-cookie");
        if (accepted)
            return (<></>);

        return (
            <div className="modal fade" id="upgradeWarningModal" data-backdrop="static" aria-labelledby="upgradeWarningModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="upgradeWarningModalLabel">Notice</h5>
                            <button type="button" className="close" onClick={this.accept.bind(this)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span>As part of our efforts to provide a highly secure platform for the community, we recently upgraded our login process. You will need to re-register to update/contribute to the log. However, if you use the same email as your original account, we will automatically move your ownerships and submissions to the new account. Sorry for any inconvenience. Feel free to email <a href="mailto:officialguitarlogs@gmail.com">officialguitarlogs@gmail.com</a> if you have questions or concerns.</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.accept.bind(this)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
 
export default UpgradeWarning;