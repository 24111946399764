import * as React from "react";
import {
    RouteComponentProps
} from "react-router-dom";
import { GearAttribute } from "../../Models/GearAttribute";
import { ModelConfig } from "../../Models/ModelConfig";
import { Draggable, Droppable, DragDropContext, DropResult } from "react-beautiful-dnd";

// route parameters
type RouteParams = {
    modelId: string;
    yearId: string;
}

// component properties
export interface YearProps extends RouteComponentProps<RouteParams> {
    waitHandler: any;
    errorHandler: any;
}

// component stats
export interface YearState {
    model: ModelConfig;
    yearIndex: number;
    editAttribute: GearAttribute;
    editIndex: number;
    editOptions: string;
    fieldDefs: GearAttribute[];
}

// Year component
class Year extends React.Component<YearProps, YearState> {
    username = (window as any).username;
    user = (window as any).user;
    attributeTypes:string[] = ["Write-in", "Yes/No", "Choice", "Choice/Other"];
    constructor(props:YearProps, context:YearState) {
        super(props, context);
        this.state = {
            model: null,
            yearIndex: -1,
            editAttribute: {displayName: "", tooltip: "", tooltipPhotoUri: "", defaultValue: "", examples: "", required: false, attributeType: 0, options: []},
            editIndex: -1,
            editOptions: "",
            fieldDefs: []
        };
    };

    // componentDidMount
    componentDidMount() {
        if (!this.user || !this.user.IsAdmin) { 
            this.props.history.replace("/"); 
        }
        else {
            // fetch the guitar details and all gear attributes
            this.props.waitHandler(true);
            fetch("/api/gearattribute").then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((attributes: GearAttribute[]) => {
                this.setState({fieldDefs: attributes});
            });
            fetch(`/api/modelconfig/${this.props.match.params.modelId}`).then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((Model: ModelConfig) => {
                if (Model) {
                    // get the index of the year being edited
                    let yearIndex = -1;
                    for (var i = 0; i < Model.years.length; i++) {
                        if (Model.years[i].id === this.props.match.params.yearId) {
                            yearIndex = i;
                            break;
                        }
                    }

                    // save state
                    this.setState({model: Model, yearIndex: yearIndex});
                    this.props.waitHandler(false);
                }
            });
        }
    };

    close = ()  => {
        ($ as any)("#modalConfirm").modal("hide");
        this.setState({
            editIndex: -1, 
            editAttribute: {displayName: "", tooltip: "", tooltipPhotoUri: "", defaultValue: "", examples: "", required: false, attributeType: 0, options: []},
            editOptions: ""
        });

        var context = this;
        $("#modalConfirm").on('hidden.bs.modal', function (e) {
            context.props.waitHandler(false);
            context.props.errorHandler(null);
        });
    };

    addAttribute = () => {
        ($ as any)("#modalConfirm").modal({backdrop: "static"}, "show");
    };

    editAttribute = (index:number) => {
        let attribute = this.state.model.years[this.state.yearIndex].attributes[index];
        let editAttribute:GearAttribute = {
            id: attribute.id,
            createdDate: attribute.createdDate,
            modifiedDate: attribute.modifiedDate,
            displayName: attribute.displayName,
            tooltip: attribute.tooltip,
            tooltipPhotoUri: attribute.tooltipPhotoUri,
            defaultValue: attribute.defaultValue,
            examples: attribute.examples,
            required: attribute.required,
            attributeType: attribute.attributeType,
            options: attribute.options
        };
        
        this.setState({editAttribute: editAttribute, editIndex: index, editOptions: attribute.options.join('\n')});
        ($ as any)("#modalConfirm").modal({backdrop: "static"}, "show");
    };

    deleteAttribute = (index:number) => {
        let attribute = this.state.model.years[this.state.yearIndex].attributes[index];
        var r = confirm(`Are you sure you want to delete this ${attribute.displayName} attribute?`);

        if (r)
        {
            // start waiting indicator
            this.props.waitHandler(true);
            let context = this;
            fetch(`/api/modelconfig/${this.state.model.id}/year/${this.state.model.years[this.state.yearIndex].id}/attributes/${attribute.id}`, {
                method: "DELETE"
            }).then(function(res) {
                if (!res.ok) {
                    // save failed...show error message
                    context.props.waitHandler(false);
                    context.props.errorHandler(res.statusText);
                }
                else {
                    // remove the item from the collection
                    let model = context.state.model;
                    model.years[context.state.yearIndex].attributes.splice(index, 1);
                    context.setState({model: model});
                    context.props.waitHandler(false);
                    context.props.errorHandler(null);
                }
            });
        }
    };

    save = () => {
        // start waiting indicator
        this.props.waitHandler(true);
        let editAttribute = this.state.editAttribute;
        editAttribute.options = this.state.editOptions.split('\n');
        //editAttribute.attributeType = editAttribute.attributeType;
        console.log(editAttribute);

        // determine create or update, or clone
        if (this.state.editIndex === -1) {
            // create
            let context = this;
            fetch(`/api/modelconfig/${this.state.model.id}/year/${this.state.model.years[this.state.yearIndex].id}/attributes`, {
                method: "POST",
                body: JSON.stringify(editAttribute),
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            }).then(function(res) {
                if (!res.ok) {
                    // save failed...show error message
                    context.props.waitHandler(false);
                    context.props.errorHandler(res.statusText);
                }
                else {
                    return res.json();
                }
            }).then(function(attribute: GearAttribute) {
                // add to the model
                let model = context.state.model;
                model.years[context.state.yearIndex].attributes.push(attribute);
                context.setState({
                    model: model, 
                    editAttribute: {displayName: "", tooltip: "", tooltipPhotoUri: "", defaultValue: "", examples: "", required: false, attributeType: 0, options: []},
                    editOptions: ""
                });

                context.props.waitHandler(false);
                context.props.errorHandler(null);
                ($ as any)("#modalConfirm").modal("hide");
            });
        }
        else {
            // update
            let item = this.state.model.years[this.state.yearIndex].attributes[this.state.editIndex];
            item.displayName = this.state.editAttribute.displayName;
            item.tooltip = this.state.editAttribute.tooltip;
            item.tooltipPhotoUri = this.state.editAttribute.tooltipPhotoUri;
            item.defaultValue = this.state.editAttribute.defaultValue;
            item.examples = this.state.editAttribute.examples;
            item.required = this.state.editAttribute.required;
            item.attributeType = this.state.editAttribute.attributeType;
            item.options = this.state.editOptions.split('\n');

            let context = this;
            fetch(`/api/modelconfig/${this.state.model.id}/year/${this.state.model.years[this.state.yearIndex].id}/attributes/${item.id}`, {
                method: "PATCH",
                body: JSON.stringify(item),
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            }).then(function(res) {
                if (!res.ok) {
                    // save failed...show error message
                    context.props.waitHandler(false);
                    context.props.errorHandler(res.statusText);
                }
                else {
                    // update the item in the collection
                    let model = context.state.model;
                    model.years[context.state.yearIndex].attributes[context.state.editIndex] = item;
                    context.setState({
                        model: model, 
                        editIndex: -1, 
                        editAttribute: {displayName: "", tooltip: "", tooltipPhotoUri: "", defaultValue: "", examples: "", required: false, attributeType: 0, options: []},
                        editOptions: ""
                    });

                    context.props.waitHandler(false);
                    context.props.errorHandler(null);
                    ($ as any)("#modalConfirm").modal("hide");
                }
            });
        }
    };

    handleInputChange = (event:any) => {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        let editAttribute = this.state.editAttribute;
        if (event.target.type == "checkbox") {
            fieldVal = event.target.checked;
        }
        editAttribute[fieldName] = fieldVal;

        if (fieldName == "attributeType")
            editAttribute[fieldName] = parseInt(fieldVal);

        this.setState({editAttribute: editAttribute});
    };

    reorder = (result:DropResult) => {
        console.log(result);
        if (result.source.index != result.destination.index) {
            // reorder
            let model = this.state.model;
            let year = model.years[this.state.yearIndex];

            // start waiting indicator
            this.props.waitHandler(true);
            let temp = year.attributes[result.source.index];
            let context = this;
            fetch(`/api/modelconfig/${this.state.model.id}/year/${year.id}/attributes/${temp.id}/${result.destination.index}`, {
                method: "POST"
            }).then(function(res) {
                if (!res.ok) {
                    // save failed...show error message
                    context.props.waitHandler(false);
                    context.props.errorHandler(res.statusText);
                }
                else {
                    model.years[context.state.yearIndex].attributes.splice(result.source.index, 1);
                    model.years[context.state.yearIndex].attributes.splice(result.destination.index, 0, temp);
                    context.props.waitHandler(false);
                    context.props.errorHandler(null);
                    context.setState({model: model});
                }
            });
        }
    };

    baseAttributeChange = (evt:any) => {
        let displayName = evt.target.value;
        let items = this.state.fieldDefs.find(i => i.displayName == displayName);
        this.setState({editAttribute: items});
    };

    // renders the component
    render() {
        if (!this.state.model)
            return (<></>);
        else {
            let aTypeOptions = this.attributeTypes.map((option:string, index:number) => (
                <option value={index+1}>{option}</option>
            ));
            let optionsBlockTitle = (this.state.editAttribute.attributeType == 3 || this.state.editAttribute.attributeType == 4) ? "Options" : "Suggestions"
            let optionsBlock = (this.state.editAttribute.attributeType == 1 || this.state.editAttribute.attributeType == 3 || this.state.editAttribute.attributeType == 4) ? (
                <div className="form-group row">
                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                        <label htmlFor="ctlOptions">{optionsBlockTitle}&nbsp;</label>
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-6">
                        <textarea className="form-control" id="ctlOptions" placeholder="Enter options separated by new lines" name="options" tabIndex={8} value={this.state.editOptions} onChange={(event:any) => { this.setState({editOptions: event.target.value}) }}></textarea>
                        <div className="invalid-feedback">
                            
                        </div>
                    </div>
                </div>
            ) : (<></>);
            let rows:any = this.state.model.years[this.state.yearIndex].attributes.map((attribute:any, index:number) => (
                <Draggable key={attribute.id} draggableId={attribute.id} index={index}>
                    {(provided) => (
                    <tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <td>
                            <i className="fas fa-edit" onClick={this.editAttribute.bind(this, index)} style={{cursor: "pointer", float: "left", padding: "0px 4px"}}></i>
                            <i className="fas fa-trash-alt" onClick={this.deleteAttribute.bind(this, index)} style={{cursor: "pointer", float: "left", padding: "0px 4px"}}></i>
                        </td>
                        <td>{attribute.displayName}</td>
                        <td>{this.attributeTypes[attribute.attributeType-1]}</td>
                    </tr>
                    )}
                </Draggable>
            ));
            if (this.state.model.years.length == 0)
                rows = (<tr><td colSpan={4} style={{textAlign: "center"}}>No attributes exist</td></tr>);

            let fieldOptions = this.state.fieldDefs.map((attr:GearAttribute, index:number) => (
                <option value={attr.displayName}>{attr.displayName}</option>
            ));

            return (
            <div className="page">
                <h1>{this.state.model.years[this.state.yearIndex].year} {this.state.model.make} {this.state.model.model}</h1>
                <DragDropContext onDragEnd={this.reorder.bind(this)}>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th style={{width: "80px"}}>Actions</th>
                            <th>Attribute (<span className="btn-link" style={{cursor: "pointer"}} onClick={this.addAttribute.bind(this)}>add</span>)</th>
                            <th>Attribute Type</th>
                        </tr>
                    </thead>
                    <Droppable droppableId="id">
                        {(provided) => (
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                            {rows}
                            {provided.placeholder}
                        </tbody>
                        )}
                    </Droppable>
                </table>
                </DragDropContext>
                <div>&nbsp;</div>

                <div className="modal fade" id="modalConfirm" role="dialog" aria-labelledby="modalConfirmTitle" aria-hidden="false">
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modalConfirmTitle">{this.state.model.make} {this.state.model.model}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                        <label htmlFor="ctrlBaseAttribute">Base attribute&nbsp;</label>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6">
                                        <select className="form-control" id="ctrlBaseAttribute" value={this.state.editAttribute.displayName} onChange={this.baseAttributeChange.bind(this)}>
                                            <option></option>
                                            {fieldOptions}
                                        </select>
                                        <div className="invalid-feedback">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                        <label htmlFor="ctrlTooltip">Tooltip&nbsp;</label>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6">
                                        <textarea className="form-control" id="ctrlTooltip" name="tooltip" value={this.state.editAttribute.tooltip} onChange={this.handleInputChange.bind(this)} tabIndex={2}></textarea>
                                        <div className="invalid-feedback">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                        <label htmlFor="ctrlTooltipPhotoUri">Tooltip photo URI&nbsp;</label>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6">
                                        <input type="text" className="form-control" id="ctrlTooltipPhotoUri" name="tooltipPhotoUri" value={this.state.editAttribute.tooltipPhotoUri} onChange={this.handleInputChange.bind(this)} tabIndex={3} />
                                        <div className="invalid-feedback">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                        <label htmlFor="ctrlExamples">Examples&nbsp;</label>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6">
                                        <input type="text" className="form-control" id="ctrlExamples" name="examples" value={this.state.editAttribute.examples} onChange={this.handleInputChange.bind(this)} tabIndex={4} />
                                        <div className="invalid-feedback">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                        <label>Required&nbsp;</label>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6">
                                        <div className="custom-control custom-switch" style={{paddingTop: "7px", paddingBottom: "7px"}}>
                                            <input type="checkbox" className="custom-control-input" id="ctrlRequired" name="required" checked={this.state.editAttribute.required} onChange={this.handleInputChange.bind(this)} tabIndex={5} />
                                            <label className="custom-control-label" htmlFor="ctrlRequired">required</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                        <label htmlFor="ctlAttributeType">Attribute type&nbsp;</label>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6">
                                        <select className="form-control" id="ctlAttributeType" name="attributeType" value={this.state.editAttribute.attributeType} onChange={this.handleInputChange.bind(this)} tabIndex={6} >
                                            <option></option>
                                            {aTypeOptions}
                                        </select>
                                        <div className="invalid-feedback">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                        <label htmlFor="ctrlDefaultValue">Default value&nbsp;</label>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6">
                                        <input type="text" className="form-control" id="ctrlDefaultValue" name="defaultValue" value={this.state.editAttribute.defaultValue} onChange={this.handleInputChange.bind(this)} tabIndex={7} />
                                        <div className="invalid-feedback">
                                            
                                        </div>
                                    </div>
                                </div>
                                {optionsBlock}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={this.save.bind(this)}>Save</button>
                                <button type="button" className="btn btn-secondary" onClick={this.close.bind(this)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
    };
}
 
export default Year;