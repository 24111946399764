import * as React from "react";
import {RouteComponentProps,Link} from "react-router-dom";
import { Gear } from "../../Models/Gear";
import { GearAttribute } from "../../Models/GearAttribute";
import { ModelConfig } from "../../Models/ModelConfig";
import { Ownership } from "../../Models/Ownership";

// route parameters
type RouteParams = {
    id: string;
}

// component properties
export interface DetailProps extends RouteComponentProps<RouteParams> {
    waitHandler: any;
    errorHandler: any
}

// component state
export interface DetailState {
    gearItem: Gear;
    modelConfig: ModelConfig;
    spotlight: string;
    zoom: boolean;
    picIndex: number
}

// Edit component
class Edit extends React.Component<DetailProps, DetailState> {
    username = (window as any).username;
    constructor(props:DetailProps, context:DetailState) {
        super(props, context);
        this.state = {
            gearItem: null,
            modelConfig: null,
            spotlight: "/images/nopic.png",
            zoom: false,
            picIndex: 0
        };
    };

    // componentDidMount
    componentDidMount() {
        // fetch the gear item
        this.props.waitHandler(true);
        fetch(`/api/gear/${this.props.match.params.id}`).then((res: any) => {
            if (!res.ok) {
                this.props.waitHandler(false);
                this.props.errorHandler(res.statusText);
            }
            else
                return res.json();
        }).then((gearItem: Gear) => {
            if (gearItem) {
                // save state
                let spotlight = this.state.spotlight;
                if (gearItem.photos.length > 0) {
                    spotlight = (gearItem.applyWatermark) ? `/api/images/${gearItem.id}/${gearItem.photos[0]}` : `https://guitarlogsv2.blob.core.windows.net/gear/${gearItem.id}/${gearItem.photos[0]}`
                }
                this.setState({gearItem: gearItem, spotlight: spotlight});

                // fetch the modelConfig
                fetch(`/api/modelconfig/${gearItem.modelId}`).then((r: any) => {
                    if (!r.ok) {
                        this.props.waitHandler(false);
                        this.props.errorHandler(r.statusText);
                    }
                    else
                        return r.json();
                }).then((modelConfig: ModelConfig) => {
                    if (modelConfig) {
                        this.setState({modelConfig: modelConfig});
                        this.props.waitHandler(false);
                    }
                });
            }
        });
    };

    // fires when a different photo is selected in the main spotlight area
    spotlightChanged(index:number) {
        let gearItem = this.state.gearItem;
        if (index < 0)
            index = gearItem.photos.length - 1;
        if (index == gearItem.photos.length)
            index = 0;
        let pic = gearItem.photos[index];
        if (this.state.gearItem.applyWatermark)
            this.setState({spotlight: `/api/images/${gearItem.id}/${gearItem.photos[index]}`, picIndex: index});
        else
            this.setState({spotlight: `https://guitarlogsv2.blob.core.windows.net/gear/${gearItem.id}/${gearItem.photos[index]}`, picIndex: index});
    };

    // handles scrolling of the thumbnails
    scroll(right:boolean) {
        let offset = (right) ? -90 : 90;
        var objDiv = document.getElementById("thumbnails");
        objDiv.scrollLeft = objDiv.scrollLeft + offset;
    };

    // toggles the zoom of a photo in spotlight
    toggleZoom(zoom:boolean) {
        this.setState({zoom: zoom});
        if (zoom) {
            document.body.addEventListener("keydown", this.keydown.bind(this));
        }
        else {
            document.body.removeEventListener("keydown", this.keydown.bind(this));
        }
    };

    keydown(evt:any) {
        if (evt.key == "ArrowLeft") {
            this.spotlightChanged(this.state.picIndex - 1);
        }
        else if (evt.key == "ArrowRight" || evt.key == " ") {
            this.spotlightChanged(this.state.picIndex + 1);
        }
        else if (evt.key == "Escape") {
            this.toggleZoom(false);
        }
    }

    getAttributeValue = (attribute:GearAttribute) => {
        let val = this.state.gearItem.attributes.find(i => i.attributeId == attribute.id);
        let display = (val) ? val.attributeValue : "";
        if (attribute.attributeType == 2)
            display = (!val) ? "No" : ((val.attributeValue == "true") ? "Yes" : "No");
        return display;
    };

    // renders the component
    render() {
        if (!this.state.gearItem || !this.state.modelConfig)
            return (<></>);

        let carouselWidth = (90 * this.state.gearItem.photos.length) + "px";
        let imgs = this.state.gearItem.photos.map((img:string, index:number) => ( 
            <li>
                <div className="img-wrapper">
                    <div className="b-block gallery-img" style={{backgroundImage: (this.state.gearItem.applyWatermark) ? `url(/api/images/${this.state.gearItem.id}/thumbnail/${img})` : `url(https://guitarlogsv2.blob.core.windows.net/gear/${this.state.gearItem.id}/thumb_${img})`}} onClick={this.spotlightChanged.bind(this, index)}></div>
                </div> 
            </li>
        ));

        // Actions-inquire: non-owner and owned
        let actions = [];
        if (this.username && this.username.length > 0 &&
            this.state.gearItem.ownedBy && this.state.gearItem.ownedBy.length > 0
            && this.username != this.state.gearItem.ownedBy)
            actions.push(<Link to={`/threads/create/${this.state.gearItem.ownedBy}/${this.state.gearItem.year} ${this.state.gearItem.make} ${this.state.gearItem.model} (${this.state.gearItem.serialNumber})`}>inquire</Link>);
        
        // Actions-claim: non-owner
        if (this.username && this.username.length > 0 && this.username != this.state.gearItem.ownedBy)
            actions.push(<Link to={`/gear/claim/${this.state.gearItem.id}`}>claim</Link>);

        // Actions-edit: admin or owner or unowned submitter
        let isAdmin = ((window as any).user && (window as any).user.MemberType == "Admin");
        let canEdit = (this.username && this.username.length > 0 && 
            (isAdmin || 
            this.username == this.state.gearItem.ownedBy || 
            ((!this.state.gearItem.ownedBy || this.state.gearItem.ownedBy == "") && this.state.gearItem.createdBy == this.username)));
        if (canEdit)
            actions.push(<Link to={`/gear/edit/${this.state.gearItem.id}`}>edit</Link>);

        // Actions-sign-in: no user
        if (!this.username || this.username.length == 0)
            actions.push(<a href={(window as any).login}>sign-in to claim</a>);

        // Actions-gotInfo: non-admin or non-owner
        if (!canEdit)
            actions.push(<Link to={"/gear/info/" + this.state.gearItem.id}>got info?</Link>);
        
        let ownedBy = (!this.state.gearItem.ownedBy || this.state.gearItem.ownedBy == "") ? "UNCLAIMED" : ((!this.username || this.username.length == 0) ? (<span>{this.state.gearItem.ownedBy}</span>) : (<Link to={"/profile/" + this.state.gearItem.ownedBy}>{this.state.gearItem.ownedBy}</Link>));
        let actionsJsx = [];
        actions.forEach((comp:any, i:number) => {
            actionsJsx.push(comp);
            if (i < actions.length - 1)
                actionsJsx.push(<>&nbsp;/&nbsp;</>);
        });
        let ownedByJsx = (
            <div className="row">
                <div className="col-sm-12">
                    Owned by: {ownedBy} (<>{actionsJsx}</>)
                </div>
            </div>
        );

        // build the submitted by header
        let user = (!this.username || this.username.length == 0) ? (<span>{this.state.gearItem.createdBy}</span>) : (<Link to={"/profile/" + this.state.gearItem.createdBy}>{this.state.gearItem.createdBy}</Link>);
        let submittedBy = (
            <div className="row">
                <div className="col-sm-12">
                    Submitted by:&nbsp;{user}&nbsp;({new Date(this.state.gearItem.createdDate).toLocaleString()})
                </div>
            </div>
        );

        let year = this.state.modelConfig.years.find(i => i.id == this.state.gearItem.yearId);
        let title = (this.state.gearItem.year) ? `${this.state.gearItem.year} ${this.state.gearItem.make} ${this.state.gearItem.model} (${this.state.gearItem.serialNumber})` : `${this.state.gearItem.make} ${this.state.gearItem.model} (${this.state.gearItem.serialNumber})`;
        let dynamicFields = [];
        if (year) {
            dynamicFields = year.attributes.map((attribute:GearAttribute, index:number) => (
                <div className="detail-row">
                    <div className="profile-prop">{attribute.displayName}</div>
                    <div className="profile-prop-val">{this.getAttributeValue(attribute)}</div>
                </div>  
            ));
        }

        let adminDownload = (isAdmin) ? (<div className="imgDownload" onClick={() => window.location.href = this.state.spotlight }><i className="fas fa-download"></i></div>) : (<></>);
        
        let ownerHistory = (<tr><td colSpan={2} style={{textAlign: "center"}}>No ownership history available</td></tr>);
        if (this.state.gearItem && this.state.gearItem.ownershipHistory && this.state.gearItem.ownershipHistory.length > 0) {
            let ownerHistory:any = this.state.gearItem.ownershipHistory.map((owner:Ownership) => ( 
                <tr>
                    <td><Link to={`/profile/${owner.username}`}>{owner.username}</Link></td>
                    <td>{new Date(owner.effectiveDate).toLocaleString()}</td>
                </tr>
            ));
        }

        let price = (<></>);
        if (this.state.gearItem.status == "For sale") {
            price = (
                <div className="detail-row">
                    <div className="profile-prop">Price</div>
                    <div className="profile-prop-val">{this.state.gearItem.price}</div>
                </div>
            );
        }

        return (
        <div className="page">
            <h1>{title}&nbsp;{(this.state.gearItem.status == "For sale" ? <span style={{fontSize: "16px", marginTop: "14px", verticalAlign: "top"}} className="badge badge-pill badge-success">For sale</span> : <></>)}</h1>
            {ownedByJsx}
            {submittedBy}
            <div className="row">
                <div className="col-sm-6">
                    <div className="guitar-gallery">
                        <div className="imgFullContainer" style={{display: (this.state.zoom) ? "block" : "none"}}>
                            <div className="imgFull" style={{backgroundImage: "url(" + this.state.spotlight + ")"}}></div>
                            <div className="imgClose" onClick={this.toggleZoom.bind(this, false)}><i className="far fa-times-circle"></i></div>
                            <div className="imgLeft" onClick={this.spotlightChanged.bind(this, this.state.picIndex - 1)}><i className="fas fa-chevron-left"></i></div>
                            <div className="imgRight" onClick={this.spotlightChanged.bind(this, this.state.picIndex + 1)}><i className="fas fa-chevron-right"></i></div>
                            {adminDownload}
                        </div>
                        <div className="d-block gallery-img" style={{backgroundImage: "url(" + this.state.spotlight + ")"}} onClick={this.toggleZoom.bind(this, true)}></div>
                        <div className="thumbnails" id="thumbnails">
                            <div className="thumbnail-carousel">
                                <div className="carousel-move carousel-left" onClick={this.scroll.bind(this, true)}>
                                    <i className="fas fa-angle-left"></i>
                                </div>
                                <div className="carousel-move carousel-right" onClick={this.scroll.bind(this, false)}>
                                    <i className="fas fa-angle-right"></i>
                                </div>
                                <ul style={{width: carouselWidth}}>
                                    {imgs}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="detail-row">
                        <div className="profile-prop">Serial number</div>
                        <div className="profile-prop-val">{this.state.gearItem.serialNumber}</div>
                    </div>  
                    {dynamicFields}
                    <div className="detail-row">
                        <div className="profile-prop">Description</div>
                        <div className="profile-prop-val">{this.state.gearItem.description}</div>
                    </div>
                    <div className="detail-row">
                        <div className="profile-prop">Publications</div>
                        <div className="profile-prop-val">{(this.state.gearItem.publications) ? this.state.gearItem.publications.join(", ") : ""}</div>
                    </div>
                    <div className="detail-row">
                        <div className="profile-prop">Noteable ownership</div>
                        <div className="profile-prop-val">{this.state.gearItem.notableOwnership}</div>
                    </div>
                    <div className="detail-row">
                        <div className="profile-prop">Status</div>
                        <div className="profile-prop-val">{this.state.gearItem.status}</div>
                    </div>
                    {price}
                    <div className="detail-row">
                        <div className="profile-prop">Video link</div>
                        <div className="profile-prop-val">{(this.state.gearItem.videoLink && this.state.gearItem.videoLink.indexOf("http") != -1) ? <a href={this.state.gearItem.videoLink} target="_blank">{this.state.gearItem.videoLink}</a> : <span>{this.state.gearItem.videoLink}</span>}</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <h3>Ownership History</h3>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Effective Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ownerHistory}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        );
    };
}
 
export default Edit;