import * as React from "react";
import {Link} from "react-router-dom";

// Contact component
class Contact extends React.Component {
    // renders the component
    render() {
        return (
            <div className="page">
            <h1>Contact</h1>
            <div className="row">
                <div className="col-sm-12">
                    <p>Hey vintage guitar enthusiast! The site is the result of a grassroots effort and built with community-sourced data. Have a question not covered in the site? Have concerns over information or images contained in the site? Feel free to send me (<Link to="/threads/create/richdizz">richdizz</Link>) a message (must be signed in) or email <a href="mailto:officialguitarlogs@gmail.com">officialguitarlogs@gmail.com</a>. Keep in mind this is a one-man operation, done on the side, for "fun", and not for profit. You can also submit information anonymously to any log entry by clicking on the &quot;got info?&quot; link on the detail page for the entry (see <Link to="/help/provide">Provide info on entry</Link> help topic for more on this). Thanks in advance for your patience in my response.</p>
                </div>
            </div>
        </div>
        );
    };
}
 
export default Contact;