import * as React from "react";
import { Link } from "react-router-dom";
import Cookie from "universal-cookie";
 
// alert component for displaying error messages
class CookieWarning extends React.Component {
    private cookie:Cookie;
    componentDidMount = () => {
        this.cookie = new Cookie();
    };

    acceptCookie = () => {
        // write the cookie and close the warning
        this.cookie.set("accepted-cookies", "true", { path: '/' });
        this.setState({});
    };

    // renders the component
    render() {
        if (!this.cookie)
            return (<></>);

        // look for accepted cookie
        let accepted = this.cookie.get("accepted-cookies");
        if (accepted)
            return (<></>);

        return (
            <div className="cookie-warning">
                <div style={{width: "calc(100% - 50px)", display: "inline-block"}}>
                    <h4>This website uses technically required cookies, which are necessary for the technical functionality of our website and the detection of errors. Read more information about the cookies we use and their purpose at <Link to="/privacy">privacy</Link>.</h4>
                </div>
                <div style={{width: "50px", display: "inline-block", verticalAlign: "top"}}>
                    <h4><i className="fas fa-times" onClick={this.acceptCookie.bind(this)}></i></h4>
                </div>
            </div>
        );
    };
}
 
export default CookieWarning;