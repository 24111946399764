import * as React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import { Feature } from "../../Models/Feature";
import About from "../General/About";
import CoverageMap from "../General/CoverageMap";

// component properties
export interface HomeProps extends RouteComponentProps<any> {
    waitHandler: any;
    errorHandler: any;
}

// component state
export interface HomeState {
    sponsor:any;
    serial:string;
}

// About component
class Home extends React.Component<HomeProps, HomeState> {
    username = (window as any).username;
    constructor(props:HomeProps, context:HomeState) {
        super(props, context);
        this.state = {
            sponsor: {
                name: "",
                description: "",
                photo: "",
                featurePhoto: ""
            },
            serial: ""
        } 
    };

    componentDidMount = () => {
        // perform the sponsors
        fetch("/api/sponsor/random").then((res: any) => {
            return res.json();
        }).then((jsonResponse: any) => {
            this.setState({sponsor: jsonResponse});
        });
    };

    // navigates to a feature
    nav = (feature:Feature) => {
        this.props.history.push("/calendar");
    };

    // renders the component
    render() {
        return (
        <div className="page">
            <div id="home">
                <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item gallery-img2 active" style={{backgroundImage: `url(https://guitarlogsv2.blob.core.windows.net/features/Feature.png)`, cursor: "pointer"}} onClick={this.nav.bind(this)}>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <h1>Welcome to GuitarLogs.com</h1>
                    <About/>
                    <p>Please take the time to look around and contribute if you know of a Fender that isn't represented in the log. This site could not exist without the information provided by members. Each submission gets the community a better picture of Fender's early years and will perhaps save a potential buyer from an uneducated purchase.</p>
                    <h2>Serial Number Coverage</h2>
                    <CoverageMap></CoverageMap>
                </div>
                <div className="col-md-4">
                <h2>Quick Actions</h2>
                    <div style={{width: "100%"}} className="form-inline">
                        <input style={{width: "65%"}} id="ctrlSearch" className="form-control mr-sm-2" type="search" placeholder="Serial number" onChange={(e) => this.setState({serial: e.target.value})} onKeyUp={(e) => (e.key == "Enter") ? this.props.history.push(`/gear?serial=${this.state.serial}`) : false } />
                        <button style={{width: "30%"}} className="btn btn-primary my-2 my-sm-0" type="submit" onClick={() => this.props.history.push(`/gear?serial=${this.state.serial}`)}>Search</button>
                    </div>
                    <div style={{width: "100%", paddingTop: "10px"}}>
                        <ul>
                            <li><Link to="/gear/create">Register gear</Link></li>
                            <li><Link to="/gear?sortBy=createdDate%20DESC">Newly Added</Link></li>
                            <li><Link to="/gear?status=For%20sale">For sale</Link></li>
                            <li>{(this.username && this.username.length > 0) ? (<Link to="/profile">My profile</Link>) : (<a href={(window as any).login}>Register</a>)}</li>
                            <li><Link to="/help">Help</Link></li>
                        </ul>
                    </div>
                    <h2>Supporter Spotlight</h2>
                    <div>{this.state.sponsor.name}</div>
                    <a href={this.state.sponsor.uri}><img src={this.state.sponsor.photo} alt={this.state.sponsor.name} className="img img-fluid" /></a>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    &nbsp;
                </div>
            </div>
        </div>
        );
    };
}
 
export default Home;