import { SSL_OP_NO_TLSv1_1 } from "constants";
import * as React from "react";
import {
    RouteComponentProps
} from "react-router-dom";
import { ModelConfig } from "../../Models/ModelConfig";
import { YearConfig } from "../../Models/YearConfig";

// route parameters
type RouteParams = {
    id: string;
}

// component properties
export interface ModelProps extends RouteComponentProps<RouteParams> {
    waitHandler: any;
    errorHandler: any;
}

// component stats
export interface ModelState {
    model: ModelConfig;
    cloneFrom: YearConfig;
    editYear: YearConfig;
    editIndex: number;
}

// Model component
class Model extends React.Component<ModelProps, ModelState> {
    username = (window as any).username;
    user = (window as any).user;
    attributeTypes:string[] = ["Write-in", "Yes/No", "Choice", "Choice/Other"];
    constructor(props:ModelProps, context:ModelState) {
        super(props, context);
        this.state = {
            model: null,
            cloneFrom: null,
            editYear: {serialRegEx: "", active: true, attributes: []},
            editIndex: -1
        };
    };

    // componentDidMount
    componentDidMount() {
        if (!this.user || !this.user.IsAdmin) { 
            this.props.history.replace("/"); 
        }
        else {
            this.props.waitHandler(true);

            // fetch the guitar details
            fetch(`/api/modelconfig/${this.props.match.params.id}`).then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((model: ModelConfig) => {
                if (model) {
                    this.setState({model: model});
                    this.props.waitHandler(false);
                }
            });
        }
    };

    close = ()  => {
        ($ as any)("#modalConfirm").modal("hide");
        this.setState({editIndex: -1, editYear: {serialRegEx: "", active: true, attributes: []}, cloneFrom: null});

        var context = this;
        $("#modalConfirm").on('hidden.bs.modal', function (e) {
            context.props.waitHandler(false);
            context.props.errorHandler(null);
        });
    };

    cloneYear = (index:number) => {
        let year = this.state.model.years[index];
        this.setState({cloneFrom: year});
        ($ as any)("#modalConfirm").modal({backdrop: "static"}, "show");
    };

    editYear = (index:number) => {
        let year = this.state.model.years[index];
        let editYear:YearConfig = {
            id: year.id,
            year: year.year,
            serialRegEx: year.serialRegEx,
            active: year.active,
            createdDate: year.createdDate,
            modifiedDate: year.modifiedDate,
            attributes: year.attributes
        };
        this.setState({editYear: editYear, editIndex: index});
        ($ as any)("#modalConfirm").modal({backdrop: "static"}, "show");
    };

    deleteYear = (index:number) => {
        let year = this.state.model.years[index];
        var r = confirm(`Are you sure you want to delete ${year.year} ${this.state.model.make} ${this.state.model.model}`);

        if (r)
        {
            // start waiting indicator
            this.props.waitHandler(true);
            let context = this;
            fetch(`/api/modelconfig/${this.state.model.id}/year/${year.id}`, {
                method: "DELETE"
            }).then(function(res) {
                if (!res.ok) {
                    // save failed...show error message
                    context.props.waitHandler(false);
                    context.props.errorHandler(res.statusText);
                }
                else {
                    // remove the item from the collection
                    let model = context.state.model;
                    model.years.splice(index, 1);
                    context.setState({model: model});
                    context.props.waitHandler(false);
                    context.props.errorHandler(null);
                }
            });
        }
    };

    addYear = () => {
        ($ as any)("#modalConfirm").modal({backdrop: "static"}, "show");
    };

    save = () => {
        // start waiting indicator
        this.props.waitHandler(true);
        let editYear = this.state.editYear;

        // determine create, update, or clone
        if (this.state.editIndex === -1 && this.state.cloneFrom === null) {
            // create
            editYear.attributes = [];
            let context = this;
            fetch(`/api/modelconfig/${this.state.model.id}/year`, {
                method: "POST",
                body: JSON.stringify(editYear),
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            }).then(function(res) {
                if (!res.ok) {
                    // save failed...show error message
                    context.props.waitHandler(false);
                    context.props.errorHandler(res.statusText);
                }
                else {
                    return res.json();
                }
            }).then(function(year: YearConfig) {
                // add to the model
                let model = context.state.model;
                model.years.push(year);
                context.setState({model: model, editYear: {serialRegEx: "", active: true, attributes: []}});

                context.props.waitHandler(false);
                context.props.errorHandler(null);
                ($ as any)("#modalConfirm").modal("hide");
            });
        }
        else if (this.state.editIndex === -1 && this.state.cloneFrom) {
            // clone
            let context = this;
            let editYear = this.state.editYear;
            editYear.attributes = this.state.cloneFrom.attributes;
            fetch(`/api/modelconfig/${this.state.model.id}/year/${this.state.cloneFrom.id}/clone`, {
                method: "POST",
                body: JSON.stringify(editYear),
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            }).then(function(res) {
                if (!res.ok) {
                    // save failed...show error message
                    context.props.waitHandler(false);
                    context.props.errorHandler(res.statusText);
                }
                else {
                    return res.json();
                }
            }).then(function(year: YearConfig) {
                // add to the model
                let model = context.state.model;
                model.years.push(year);
                context.setState({model: model, editYear: {serialRegEx: "", active: true, attributes: []}, cloneFrom: null});

                context.props.waitHandler(false);
                context.props.errorHandler(null);
                ($ as any)("#modalConfirm").modal("hide");
            });
        }
        else {
            // update
            let item = this.state.model.years[this.state.editIndex];
            item.year = this.state.editYear.year;
            item.serialRegEx = this.state.editYear.serialRegEx;
            item.active = this.state.editYear.active;

            let context = this;
            fetch(`/api/modelconfig/${this.state.model.id}/year/${item.id}`, {
                method: "PATCH",
                body: JSON.stringify(item),
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            }).then(function(res) {
                if (!res.ok) {
                    // save failed...show error message
                    context.props.waitHandler(false);
                    context.props.errorHandler(res.statusText);
                }
                else {
                    // update the item in the collection
                    let model = context.state.model;
                    model.years[context.state.editIndex] = item;
                    context.setState({model: model, editYear: {serialRegEx: "", active: true, attributes: []}, editIndex: -1});

                    context.props.waitHandler(false);
                    context.props.errorHandler(null);
                    ($ as any)("#modalConfirm").modal("hide");
                }
            });
        }
    };

    reorder = (index: number, offset:number) => {
        let model = this.state.model;
        if ((index == 0 && offset == -1) || (index == (model.years.length - 1) && offset == 1))
            return;

        // start waiting indicator
        this.props.waitHandler(true);
        let temp = model.years[index];
        let context = this;
        fetch(`/api/modelconfig/${this.state.model.id}/year/${temp.id}/${offset}`, {
            method: "POST"
        }).then(function(res) {
            if (!res.ok) {
                // save failed...show error message
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            else {
                model.years[index] = model.years[index + offset];
                model.years[index + offset] = temp;
                context.props.waitHandler(false);
                context.props.errorHandler(null);
                this.setState({model: model});
            }
        });
    };

    editAttributes = (index:number) => {
        this.props.history.push(`/admin/models/${this.state.model.id}/${this.state.model.years[index].id}`);
    };

    handleInputChange = (event:any) => {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        let editYear = this.state.editYear;
        if (event.target.type == "checkbox") {
            fieldVal = event.target.checked;
        }
        if (fieldName == "year") {
            fieldVal = (fieldVal.length > 0) ? parseInt(fieldVal) : "";
        }

        editYear[fieldName] = fieldVal;
        this.setState({editYear: editYear});
    };

    // renders the component
    render() {
        if (!this.state.model)
            return (<></>);
        else {
            let rows:any = this.state.model.years.map((year:any, index:number) => { 
                let attributes = year.attributes.map((a:any, i:number) => (
                    <li>{a.displayName} ({this.attributeTypes[a.attributeType-1]})</li>
                ));
                return (
                    <tr>
                        <td>
                            <i className="fas fa-copy" onClick={this.cloneYear.bind(this, index)} style={{cursor: "pointer", float: "left", padding: "0px 4px"}}></i>
                            <i className="fas fa-edit" onClick={this.editYear.bind(this, index)} style={{cursor: "pointer", float: "left", padding: "0px 4px"}}></i>
                            <i className="fas fa-trash-alt" onClick={this.deleteYear.bind(this, index)} style={{cursor: "pointer", float: "left", padding: "0px 4px"}}></i>

                            <i className="fas fa-caret-up" onClick={this.reorder.bind(this, index, -1)} style={{cursor: "pointer", float: "left", padding: "0px 4px"}}></i>
                            <i className="fas fa-caret-down" onClick={this.reorder.bind(this, index, 1)} style={{cursor: "pointer", float: "left", padding: "0px 4px"}}></i>
                        </td>
                        <td>{year.year} {(year.active) ? "(active)" : "(inactive)"}</td>
                        <td>{year.serialRegEx}</td>
                        <td>
                            <div style={{cursor: "pointer"}} onClick={this.editAttributes.bind(this, index)}><i className="fas fa-edit"></i>&nbsp;Edit attributes</div>
                            <ul>
                                {attributes}
                            </ul>
                        </td>
                    </tr>);
            });
            if (this.state.model.years.length == 0)
                rows = (<tr><td colSpan={4} style={{textAlign: "center"}}>No years exist</td></tr>);

            let modelTitle = (this.state.cloneFrom != null) 
                ? `Clone from ${this.state.cloneFrom.year}`
                : ((this.state.editIndex == -1) ? "Add Year" : `Edit ${this.state.editYear.year}`);

            return (
            <div className="page">
                <h1>{this.state.model.make} {this.state.model.model}</h1>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th style={{width: "150px"}}>Actions</th>
                            <th>Year</th>
                            <th>Serial Range</th>
                            <th>Attributes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={4} style={{textAlign: "center", cursor: "pointer"}} onClick={this.addYear.bind(this)}>
                                <i className="fas fa-plus"></i>&nbsp;&nbsp;Add Year
                            </td>
                        </tr>
                        {rows}
                    </tbody>
                </table>
                <div>&nbsp;</div>

                <div className="modal fade" id="modalConfirm" role="dialog" aria-labelledby="modalConfirmTitle" aria-hidden="false">
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modalConfirmTitle">{modelTitle}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                        <label htmlFor="ctlYear">Year&nbsp;</label>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6">
                                        <input type="text" className="form-control" id="ctlYear" placeholder="Enter model year" name="year" value={this.state.editYear.year} onChange={this.handleInputChange.bind(this)} tabIndex={1} />
                                        <div className="invalid-feedback">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                        <label htmlFor="ctlSerialRegEx">Serial RegEx&nbsp;</label>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6">
                                        <input type="text" className="form-control" id="ctlSerialRegEx" placeholder="Enter serial number RegEx" name="serialRegEx" value={this.state.editYear.serialRegEx} onChange={this.handleInputChange.bind(this)} tabIndex={2} />
                                        <div className="invalid-feedback">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                        <label>Active&nbsp;</label>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-6">
                                        <div className="custom-control custom-switch" style={{paddingTop: "7px", paddingBottom: "7px"}}>
                                            <input type="checkbox" className="custom-control-input" id="ctrlActive" name="active" checked={this.state.editYear.active} onChange={this.handleInputChange.bind(this)} tabIndex={3} />
                                            <label className="custom-control-label" htmlFor="ctrlActive">active/inactive</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={this.save.bind(this)}>Save</button>
                                <button type="button" className="btn btn-secondary" onClick={this.close.bind(this)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
    };
}
 
export default Model;