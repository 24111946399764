import * as React from "react";
import {Link} from "react-router-dom";

// Terms component
class Terms extends React.Component {
    // renders the component
    render() {
        return (
        <div className="page">
            <h1>Terms of use</h1>
            <div className="row">
                <div className="col-sm-12">
                    <p>The information contained in this website is community-sourced. The data accuracy of this information cannot be guaranteed. This website and content, including but not limited to data, is meant for hobbyist purposes only. By submitting an entry or information to the site or administrator, you are agreeing to the terms and conditions.</p>
                    <p>This site is in no way afiliated with Fender Musical Instruments Corporation. FENDER®, STRATOCASTER®, STRAT®, TELECASTER®, TELE®, P BASS®, PRECISION BASS®, JAZZ BASS®, JAGUAR®, JAZZMASTER®, MUSTANG®, and NOCASTER® are registered trademarks of Fender Musical Instruments Corporation.</p>
                </div>
            </div>
        </div>
        );
    };
}
 
export default Terms;