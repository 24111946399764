import * as React from "react";
import ImageUploader from "../General/ImageUploader";
import {
    RouteComponentProps
} from "react-router-dom";
import { Gear } from "../../Models/Gear";
 
// route parameters
type RouteParams = {
    id: string;
}

// component properties
export interface ClaimProps extends RouteComponentProps<RouteParams> {
    waitHandler: any;
    errorHandler: any;
}

// component state
export interface ClaimState {
    validatePhoto:any;
    isManualValidation:boolean;
    validateAutoCss:string;
    validateManualCss:string;
    validateManualDisable:boolean;
    validateAutoDisable:boolean;
    refresh:boolean;
    formValid:boolean;
    manualSource:string;
    photos:any[];
    confirmMsg:string;
    gearItem:Gear;
}

// Claim component
class Claim extends React.Component<ClaimProps, ClaimState> {
    username = (window as any).username;
    constructor(props:ClaimProps, context:ClaimState) {
        super(props, context);
        this.state = { 
            isManualValidation: false,
            validateManualCss: "validate-option active",
            validateManualDisable: false,
            validateAutoCss: "validate-option",
            validateAutoDisable: true,
            refresh: false,
            formValid: false,
            manualSource: null,
            validatePhoto: null,
            photos: [],
            confirmMsg: null,
            gearItem: null
        };
    };

    // componentDidMount
    componentDidMount() {
        // prep popovers
        (window as any).popoverPrep();

        // ensure user context
        if (!this.username) { 
            this.props.history.replace("/"); 
        }
        else {
            this.props.waitHandler(true);
            fetch(`/api/gear/${this.props.match.params.id}`).then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((gearItem: Gear) => {
                this.setState({gearItem: gearItem})
                this.props.waitHandler(false);
            });
        }
    };

    // handles input changes
    handleInputChange(event:any) {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        this.setState({manualSource: fieldVal}, this.validateForm);
    };

    // validates the form and is called after any other set state
    validateForm() {
        var isValid = (this.state.isManualValidation && this.state.manualSource != null && this.state.manualSource != "") || 
        (!this.state.isManualValidation && this.state.validatePhoto != null);
        this.setState({formValid: isValid});
    };

    // validation changed event
    validationApproachChanged(isManual:boolean) {
        this.setState({validateManualCss: (isManual) ? "validate-option active" : "validate-option", 
            validateAutoCss: (isManual)? "validate-option" : "validate-option active", 
            validateManualDisable: !isManual, 
            validateAutoDisable: isManual, 
            isManualValidation: isManual
        }, this.validateForm);
    };

    // forces a re-render
    refreshImages = () => this.setState({refresh: !this.state.refresh});

    // handles single file selected in the photo validation option
    singleFilePicked(fileInfo:any) {
        this.setState({validatePhoto: fileInfo}, this.validateForm);
    };

    // processes a ownership claim submission
    claim() {
        this.props.waitHandler(true);

        // initialize the form data
        let formData = new FormData();
        const blob = new Blob([JSON.stringify({
            gearId: this.props.match.params.id,
            isManualValidation: this.state.isManualValidation,
            validationSource: this.state.manualSource
        })], {
            type: "application/json"
        });
        formData.append("document", blob);
        
        // get file data if applicable
        if (!this.state.validateAutoDisable && this.state.validatePhoto != null)
            formData.append(this.state.validatePhoto.name, this.state.validatePhoto.file);

        // submit the creation
        let context = this;
        fetch(`/api/gear/${this.props.match.params.id}/claim`, {
            method: "POST",
            body: formData
        }).then(function(res) {
            if (!res.ok) {
                // save failed...show error message
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            return res.json();
        }).then(function(jsonResponse) {
            // check if we were able to auto validate
            console.log(jsonResponse);
            context.props.waitHandler(false);
            context.props.errorHandler(null);

            var msg = "";
            if (!context.state.isManualValidation) {
                if (jsonResponse)
                    msg = `Our advanced algorithms were able to validate the ownership information you provided and you have been set as the owner of this item.`;
                else
                    msg = `Our advanced algorithms were unable to automatically validate the ownership of this item. The information has been sent to a site administrator to validate manually. Thanks in advance for your patience in this validation process.`;
            }
            else
                msg = `We have sent the information you provided to a site administrator to validate. We will message you an outcome of your ownership claim shortly.`;

            context.setState({confirmMsg: msg});
            ($ as any)("#modalConfirm").modal({backdrop: "static"}, "show");
        });
    };

    // fires when the confirmation modal is closed
    closeConfirmation() {
        ($ as any)("#modalConfirm").modal("hide");

        var context = this;
        $("#modalConfirm").on('hidden.bs.modal', function (e) {
            context.props.waitHandler(false);
            context.props.errorHandler(null);
            context.props.history.push(`/gear/detail/${context.props.match.params.id}`);
          })
        
    };

    // delete function that does nothing, but required on the image component
    delete() {};

    // renders the component
    render() {
        if (!this.state.gearItem)
            return (<></>);

        return (
        <div className="page">
            <h1>Claim Ownership of {this.state.gearItem.year} {this.state.gearItem.make} {this.state.gearItem.model} ({this.state.gearItem.serialNumber})</h1>
            <div className="form-group row">
                <div className="col-md-12">
                    <p>To claim ownership of an existing entry you must provide ownership proof. This can be a verifiable online source (ex: Instagram, Dropbox, OneDrive, etc) or by providing a close-up photo with the serial number clearly visible.</p>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-md-5">
                    <div className={this.state.validateManualCss} onClick={this.validationApproachChanged.bind(this, true)}>
                        <h4>Manual Validation</h4>
                        <p>Provide an online source that can be used to manually validate the submission by a site administrator. Could be sale listing, private photos, etc.</p>
                        <div className="form-group">
                            <input type="url" className="form-control" id="ctrlsource" placeholder="Enter URL address of online source" tabIndex={1} disabled={this.state.validateManualDisable} name="source" value={this.state.manualSource} onChange={this.handleInputChange.bind(this)} onFocus={() => {this.setState({validateManualCss: "validate-option active", validateAutoCss: "validate-option", validateManualDisable: false, validateAutoDisable: true})}} />
                            <small id="ctrlsourceHelp" className="form-text text-muted">e.g. https://www.gbase.com/gear/some-listing</small>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"><h2 style={{textAlign: "center"}}>OR</h2></div>
                <div className="col-md-5">
                    <div className={this.state.validateAutoCss} onClick={this.validationApproachChanged.bind(this, false)}>
                        <h4>Auto Validation <a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title="Serial Number" data-html="true" data-content="The earliest Fender guitars had serial numbers on the bridge plate. By 1954 serial numbers relocated to neck plate, except a very small number of Stratocaster models with serial numbers stamped into the plastic trem cavity cover.<br/><img src='/images/help/serial.png' class='img-fluid'/>" tabIndex={2}>?</a></h4>
                        <p>Upload a photo of the item that clearly shows the serial number. Don't worry, the photo is only used for validation and won't be made public.</p>
                        <div className="form-group">
                            <ImageUploader filesPicked={this.singleFilePicked.bind(this)} images={this.state.photos} refresh={this.refreshImages} multiple={false} header={""} allowDelete={false} onDelete={this.delete.bind(this)} onReorder={() => {}} immediateUpload={false} />
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group row" style={{paddingBottom: "20px"}}>
                <div className="col-xl-12" style={{textAlign: "center"}}>
                    <button className="btn btn-primary" onClick={this.claim.bind(this)} tabIndex={3} disabled={!this.state.formValid}>Next</button>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" role="dialog" aria-labelledby="modalConfirmTitle" aria-hidden="false">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalConfirmTitle">Ownership Claim Confirmation</h5>
                        </div>
                        <div className="modal-body">
                            {this.state.confirmMsg}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeConfirmation.bind(this)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    };
}
 
export default Claim;