import * as React from "react";
import {
    RouteComponentProps,
    Link
} from "react-router-dom";
import { Gear } from "../../Models/Gear";
import { ModelConfig } from "../../Models/ModelConfig";
import ImageUploader from "../General/ImageUploader";

// used for form validation style/messages
type FormValidation = {
    serialCss:string;
    serialMsg:any;
    yearCss:string;
    yearMsg:string;
    modelCss:string;
    modelMsg:string;
    catCss:string;
    catMsg:string;
}
 
// component properties
export interface AddProps extends RouteComponentProps {
    waitHandler: any;
    errorHandler: any
}

// component state
export interface AddState {
    logReadOnly: boolean;
    models: ModelConfig[];
    selectedModelIndex: number;
    selectedYearIndex: number;
    gear:Gear;
    serialValid:boolean;
    validatePhoto:any;
    formValid:boolean;
    validation:FormValidation;
    validateAutoCss:string;
    validateManualCss:string;
    validateManualDisable:boolean;
    validateAutoDisable:boolean;
    refresh:boolean;
    photos:any[];
    confirmMsg:string;
    noSerial:boolean;
}

const VALID:string = "form-control is-valid";
const INVALID:string = "form-control is-invalid";
const SERIAL_MSG:string = "Serial number is required";
const YEAR_MSG:string = "Year is required";
const MODEL_MSG:string = "Model is required";
const CAT_MSG:string = "Category is required";

// add component for adding a new gear entry
class Add extends React.Component<AddProps, AddState> {
    username = (window as any).username;
    user = (window as any).user;
    constructor(props:AddProps, context:AddState) {
        super(props, context);
        this.state = {
            logReadOnly: false,
            models: null,
            selectedModelIndex: -1,
            selectedYearIndex: -1,
            gear: {
                serialNumber: "",
                category: "",
                make: "",
                model: "",
                isOwner: false,
                isManualValidation: true,
                source: "",
                photos: [],
                publications: []
            },
            serialValid: false,
            formValid: false,
            validation: {
                serialCss: INVALID,
                serialMsg: SERIAL_MSG,
                yearCss: INVALID,
                yearMsg: YEAR_MSG,
                modelCss: INVALID,
                modelMsg: MODEL_MSG,
                catCss: INVALID,
                catMsg: CAT_MSG
            },
            validateManualCss: "validate-option active",
            validateManualDisable: false,
            validateAutoCss: "validate-option",
            validateAutoDisable: true,
            refresh: false,
            photos: [],
            validatePhoto: null,
            confirmMsg: null,
            noSerial: false
        } 
    };

    // componentDidMount
    componentDidMount() {
        // prep popovers
        (window as any).popoverPrep();

        // ensure user context
        if (!this.username) { 
            this.props.history.replace("/"); 
        }
        else {
            // fetch models
            this.props.waitHandler(true);
            fetch("/api/modelconfig/lite").then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((jsonResponse: any) => {
                if (jsonResponse) {
                    // save state
                    this.setState({models: jsonResponse});
                    this.props.waitHandler(false);

                    //HACK
                    //if (!this.user.IsAdmin) {
                    //    this.props.errorHandler("The log is current read-only during the soft launch. New submissions will be enabled in the next few days. Please check back and thanks for supporting the log.");
                    //    this.setState({logReadOnly: true});
                    //}
                }
            });
        }
    };

    // handles input changes
    handleInputChange(event:any) {
        var gear = this.state.gear;
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        if (event.target.type == "checkbox") {
            fieldVal = event.target.checked;
            // TODO: handle no-serial
        }
        this.setState({gear: {...gear, [fieldName]: fieldVal}}, this.validateForm);
    };

    handleCategoryInputChange = (event:any) => {
        var category = event.target.value;
        this.setState(prevState => ({gear: { ...prevState.gear, category: category, make: null, model: null, modelId: null, year: null, yearId: null}, selectedModelIndex: -1, selectedYearIndex: -1}), this.validateForm);
    };

    handleModelInputChange = (event:any) => {
        let index = parseInt(event.target.value);
        let gear = this.state.gear;
        let model = this.state.models.filter(i => i.category == this.state.gear.category)[index];
        gear.category = model.category;
        gear.model = model.model;
        gear.modelId = model.id;
        gear.make = model.make;
        gear.year = null;
        gear.yearId = null;
        this.setState({gear: gear, selectedModelIndex: index, selectedYearIndex: -1}, this.validateForm);
    };

    handleYearInputChange = (event:any) => {
        let index = parseInt(event.target.value);
        let gear = this.state.gear;
        let year = this.state.models.filter(i => i.category == this.state.gear.category)[this.state.selectedModelIndex].years[index];
        console.log(year);
        gear.year = year.year;
        gear.yearId = year.id;
        this.setState({gear: gear, selectedYearIndex: index}, this.validateForm);
    };

    // validates the form and is called after any other set state
    validateForm() {
        var validation = this.state.validation;

        var categoryValid = (this.state.gear.category && this.state.gear.category != "");
        validation.catMsg = (categoryValid) ? "" : CAT_MSG;
        validation.catCss = (categoryValid) ? VALID : INVALID;

        var modelValid = (this.state.gear.model && this.state.gear.model != "");
        validation.modelMsg = (modelValid) ? "" : MODEL_MSG;
        validation.modelCss = (modelValid) ? VALID : INVALID;

        var yearValid = (this.state.gear.year && this.state.gear.year > 1900) || this.state.gear.category === "Loose Serial Numbers" || this.state.gear.category === "Limited Edition Books";
        validation.yearMsg = (yearValid) ? "" : YEAR_MSG;
        validation.yearCss = (yearValid) ? VALID : INVALID;

        var serialValid = (this.state.noSerial || this.state.serialValid);
        validation.serialCss = (serialValid) ? VALID : INVALID;
        
        var isValid = (
            categoryValid && serialValid && yearValid && modelValid &&
            (
                (this.state.gear.isManualValidation && this.state.gear.source != null && this.state.gear.source != "") || 
                (!this.state.gear.isManualValidation && this.state.validatePhoto != null)
            ));
        this.setState({formValid: isValid, validation: validation});
    };

    // validation changed event
    validationApproachChanged(isManual:boolean) {
        var g = this.state.gear;
        g.isManualValidation = isManual;

        this.setState({validateManualCss: (isManual) ? "validate-option active" : "validate-option", 
            validateAutoCss: (isManual)? "validate-option" : "validate-option active", 
            validateManualDisable: !isManual, 
            validateAutoDisable: isManual, 
            gear: g
        }, this.validateForm);
    };

    // saves the gear
    save() {
        this.props.waitHandler(true);

        // initialize the form data
        let formData = new FormData();
        var gear = this.state.gear;
        if (this.state.noSerial)
            gear.serialNumber = "No SN"
        const blob = new Blob([JSON.stringify(gear)], {
            type: "application/json"
        });
        formData.append("document", blob);
        
        // get file data if applicable
        if (!this.state.validateAutoDisable && this.state.validatePhoto != null)
            formData.append(this.state.validatePhoto.name, this.state.validatePhoto.file);

        // submit the creation
        let context = this;
        fetch("/api/gear", {
            method: "POST",
            body: formData
        }).then(function(res) {
            if (!res.ok) {
                // save failed...show error message
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            else {
                return res.json();
            }
        }).then(function(gear: Gear) {
            // check if we were able to auto validate
            console.log(gear);
            context.props.waitHandler(false);
            context.props.errorHandler(null);

            var msg = "";
            if (!context.state.validateAutoDisable) {
                if (gear.approvalStatus != 0)
                    msg = `Our advanced algorithms were able to validate the information you provided. Please continue and provide more details.`;
                else
                    msg = `Our advanced algorithms were unable to automatically validate the details. The information has been sent to a site administrator to validate manually, but in the meantime, please continue and provide more details.`;
            }
            else
                msg = `We have sent the information you provided to a site administrator to validate the information. Please continue and provide more details.`;

            context.setState({confirmMsg: msg, gear: gear});
            ($ as any)("#modalConfirm").modal({backdrop: "static"}, "show");
        });
    };

    // fired when the confirmation dialog is closed
    closeConfirmation() {
        ($ as any)("#modalConfirm").modal("hide");

        var context = this;
        $("#modalConfirm").on('hidden.bs.modal', function (e) {
            context.props.waitHandler(false);
            context.props.errorHandler(null);
            context.props.history.push(`/gear/edit/${context.state.gear.id}`);
        });
    };

    // this is a toggle used to help re-render the component
    refreshImages = () => this.setState({refresh: !this.state.refresh});

    // handles single file selected in the photo validation option
    singleFilePicked(fileInfo:any) {
        this.setState({validatePhoto: fileInfo}, this.validateForm);
    };

    // not used
    delete(index:number) {
    };

    noSerialChecked = (evt:any) => {
        let fieldVal = evt.target.checked;
        let msg = (fieldVal) ? "" : SERIAL_MSG;
        this.setState(prevState => ({gear: { ...prevState.gear, serialNumber: ""}, validation: {...prevState.validation, serialMsg: msg}, noSerial: fieldVal}), this.validateForm);
    };

    validateSerial = () => {
        if (this.state.gear.serialNumber.length < 3) {
            let v = this.state.validation;
            v.serialCss = INVALID;
            v.serialMsg = (<span>The serial number {this.state.gear.serialNumber} is invalid. It should be at least 3 characters (include preceeding 0's or characters)</span>);
            this.setState({validation: v, serialValid: false}, this.validateForm);
        }
        else {
            if (!this.state.gear.year)
            {
                let v = this.state.validation;
                v.serialCss = VALID;
                this.setState({validation: v, serialValid: true}, this.validateForm);
            }
            else
            {
                // query to see already logged
                fetch(`/api/gear/lookup?year=${this.state.gear.year}&model=${this.state.gear.model}&serial=${this.state.gear.serialNumber}`).then((res: any) => {
                    if (!res.ok) {
                        this.props.errorHandler("Unable to validate serial number.");
                        this.setState({serialValid: false}, this.validateForm);
                    }
                    else if (res.status === 204) {
                        let v = this.state.validation;
                        v.serialCss = VALID;
                        this.setState({validation: v, serialValid: true}, this.validateForm);
                    }
                    else
                        return res.json();
                }).then((jsonResponse: Gear) => {
                    if (jsonResponse) {
                        let v = this.state.validation;
                        v.serialCss = INVALID;
                        v.serialMsg = (<span>The serial number {this.state.gear.serialNumber} for {this.state.gear.year} is already represented in the log. If you are the rightful owner, validate your ownership <Link to={`/gear/detail/${jsonResponse.id}`}>HERE</Link></span>);
                        this.setState({validation: v, serialValid: false}, this.validateForm);
                    }
                });
            }
        }
    };

    // renders the component
    render() {
        if (!this.state.models)
            return (<></>);

        // build category
        let cats = this.state.models.map(item => item.category)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .sort();
        let categories = cats.map((val:string, index:number) => (
            <option value={val}>{val}</option>
        ));

        // build models
        let models = [];
        if (this.state.gear.category) {
            models = this.state.models.filter(i => i.category == this.state.gear.category).map((item:any, index:number) => (
                <option value={index}>{item.make} {item.model}</option>
            ));
        }

        // build years dropdown
        let years = (this.state.selectedModelIndex == -1) ? (<></>) : (
            this.state.models.filter(i => i.category == this.state.gear.category)[this.state.selectedModelIndex].years.map((year:any, index:number) => (
                <option value={index}>{year.year}</option>
            ))
        );

        let yearRow = (<></>);
        if (this.state.gear.category != "Loose Serial Numbers" && this.state.gear.category != "Limited Edition Books") {
                yearRow = (<div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label htmlFor="ctrlyear">What is year of the gear?&nbsp;</label>
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <select className={this.state.validation.yearCss} id="ctrlyear" name="year" value={this.state.selectedYearIndex} onChange={this.handleYearInputChange.bind(this)} onBlur={this.validateSerial.bind(this)} tabIndex={3} disabled={this.state.logReadOnly}>
                            <option></option>
                            {years}
                        </select>
                        <small id="ctrlyearHelp" className="form-text text-muted">Based on latest dateable part or marking. <Link to="/threads/create/richdizz/New%20year%20request">Don't see your year?</Link></small>
                        <div className="invalid-feedback">
                            {this.state.validation.yearMsg}
                        </div>
                    </div>
                </div>);
        };

        let title = "Register Gear";
        let ctrl = (
        <div>
            <div className="form-group row">
                <label htmlFor="ctrlcategory" className="col-xl-3 col-lg-4 col-md-5 col-form-label">Gear category</label>
                <div className="col-xl-9 col-lg-8 col-md-7">
                    <select className={this.state.validation.catCss} id="ctrlcategory" name="category" value={this.state.gear.category} onChange={this.handleCategoryInputChange.bind(this)} tabIndex={1} disabled={this.state.logReadOnly}>
                        <option></option>
                        {categories}
                    </select>
                    <div className="invalid-feedback">
                        {this.state.validation.catMsg}
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="ctrlmodel" className="col-xl-3 col-lg-4 col-md-5 col-form-label">What is the model?</label>
                <div className="col-xl-9 col-lg-8 col-md-7">
                    <select className={this.state.validation.modelCss} id="ctrlmodel" name="model" value={this.state.selectedModelIndex} onChange={this.handleModelInputChange.bind(this)} tabIndex={2} disabled={this.state.logReadOnly}>
                        <option></option>
                        {models}
                    </select>
                    <small id="ctrlmodelHelp" className="form-text text-muted">ex: Telecaster, Stratocaster, etc. <Link to="/threads/create/richdizz/New%20model%20request">Don't see your model?</Link></small>
                    <div className="invalid-feedback">
                        {this.state.validation.modelMsg}
                    </div>
                </div>
            </div>
            {yearRow}
            <div className="form-group row">
                <label htmlFor="ctrlserialNumber" className="col-xl-3 col-lg-4 col-md-5 col-form-label">Serial Number:</label>
                <div className="col-xl-9 col-lg-8 col-md-7">
                    
                    <input type="text" className={this.state.validation.serialCss} id="ctrlserialNumber" placeholder="Enter serial number" tabIndex={4} name="serialNumber" onBlur={this.validateSerial.bind(this)} value={this.state.gear.serialNumber} onChange={this.handleInputChange.bind(this)} required disabled={this.state.noSerial || this.state.logReadOnly} />
                    <small id="ctrlmodelHelp" className="form-text text-muted">Please include any prefix characters such as "0", "-", "L", etc</small>
                    <div className="form-check" style={(this.state.gear.category == "Loose Serial Numbers" || this.state.gear.category == "Limited Edition Books") ? {display: "none"} : {}}>
                        <input type="checkbox" className="form-check-input" id="ctrlNoSerial" name="noSerial" tabIndex={5} checked={this.state.noSerial} onChange={this.noSerialChecked.bind(this)} disabled={this.state.logReadOnly} />
                        <label className="form-check-label" htmlFor="ctrlNoSerial">No serial number present</label>
                    </div>
                    <div className="invalid-feedback">
                        {this.state.validation.serialMsg}
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="ctrlisOwner" className="col-xl-3 col-lg-4 col-md-5 col-form-label">Do you currently own this gear?</label>
                <div className="col-xl-9 col-lg-8 col-md-7">
                    <select className="form-control" id="ctrlisOwner" name="isOwner" value={this.state.gear.isOwner.toString()} onChange={this.handleInputChange.bind(this)} tabIndex={6} required disabled={this.state.logReadOnly}>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-md-5">
                    <div className={this.state.validateManualCss} onClick={this.validationApproachChanged.bind(this, true)}>
                        <h4>Manual Validation</h4>
                        <p>Provide an online source that can be used to manually validate the submission by a site administrator. Could be sale listing, private photos, etc.</p>
                        <div className="form-group">
                            <input type="url" className="form-control" id="ctrlsource" placeholder="Enter URL address of online source" tabIndex={7} disabled={this.state.validateManualDisable} name="source" value={this.state.gear.source} onChange={this.handleInputChange.bind(this)} onFocus={() => {this.setState({validateManualCss: "validate-option active", validateAutoCss: "validate-option", validateManualDisable: false, validateAutoDisable: true})}} />
                            <small id="ctrlsourceHelp" className="form-text text-muted">e.g. https://www.gbase.com/gear/some-listing</small>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"><h2 style={{textAlign: "center"}}>OR</h2></div>
                <div className="col-md-5">
                    <div className={this.state.validateAutoCss} onClick={this.validationApproachChanged.bind(this, false)}>
                        <h4>Auto Validation <a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title="Serial Number" data-html="true" data-content="The earliest Fender guitars had serial numbers on the bridge plate. By 1954 serial numbers relocated to neck plate, except a very small number of Stratocaster models with serial numbers stamped into the plastic trem cavity cover.<br/><img src='/images/help/serial.png' class='img-fluid'/>" tabIndex={8}>?</a></h4>
                        <p>Upload a photo that clearly shows the serial number. Don't worry, the photo is only used for validation and won't be made public.</p>
                        <div className="form-group">
                            <ImageUploader filesPicked={this.singleFilePicked.bind(this)} images={this.state.photos} refresh={this.refreshImages} multiple={false} header={""} allowDelete={false} onDelete={this.delete.bind(this)} onReorder={() => {}} immediateUpload={false} />
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group row" style={{paddingBottom: "20px"}}>
                <div className="col-xl-12" style={{textAlign: "center"}}>
                    <button className="btn btn-primary" onClick={this.save.bind(this)} tabIndex={9} disabled={!this.state.formValid || this.state.logReadOnly}>Next</button>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" role="dialog" aria-labelledby="modalConfirmTitle" aria-hidden="false">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalConfirmTitle">Ownership Claim Confirmation</h5>
                        </div>
                        <div className="modal-body">
                            {this.state.confirmMsg}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeConfirmation.bind(this)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );

        return (
        <div className="page">
            <h1>{title}</h1>
            {ctrl}
        </div>
        );
    };
}
 
export default Add;