import * as React from "react";
import {
    Route,
    HashRouter,
    Router
} from "react-router-dom";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

// Shared Components
import Alert from "./General/Alert";
import Waiting from "./General/Waiting";
import RouteListener from "./General/RouteListener";
import TopNav from "./General/TopNav";
import FooterNav from "./General/FooterNav";
import CookieWarning from "./General/CookieWarning";

// Home Views
import Home from "./Home/Home";
import Advisors from "./Home/Advisors";
import Supporters from "./Home/Supporters";
import Contact from "./Home/Contact";
import Faq from "./Home/Faq";
import Privacy from "./Home/Privacy";
import Terms from "./Home/Terms";
import Help from "./Home/Help";
import Calendar from "./Home/Calendar";

// Profile Views
import Profile from "./Profile/Profile";

// Gear Views
import Add from "./Gear/Add";
import Edit from "./Gear/Edit";
import Detail from "./Gear/Detail";
import Results from "./Gear/Results";
import Claim from "./Gear/Claim";
import Info from "./Thread/Info";
import Admin from "./Gear/Admin";

// Thread views
import Threads from "./Thread/Threads";
import Thread from "./Thread/Thread";

// Admin View
import Models from "./Model/Models";
import Model from "./Model/Model";
import Year from "./Model/Year";
import Approvals from "./Admin/Approvals";
import Feature from "./Home/Feature";
import UpgradeWarning from "./General/UpgradeWarning";

// component properties
export interface AppProps {
}

// component state
export interface AppState {
    waiting: boolean;
    error: string;
}

// App component
export default class App extends React.Component<AppProps, AppState> {
    constructor(props:AppProps, context:AppState) {
        super(props, context);
        this.state = {
            waiting: false,
            error: null
        };
    };

    // fires when then route is changed
    onRouteChanged = () => {
        // clear out the current error
        this.setState({ error: null });
    };

    // toggles the waiting indicator
    onWait = (wait:boolean) => {
        this.setState({ waiting: wait });
    };

    // toggles the error message
    onError = (er:string) => {
        this.setState({ error: er });
    };

    // dismisses the error message
    onErrorDismiss = () => {
        this.setState({ error: null });
    };

    // renders the component
    render() {
        return (
        <Router history={history}>
            <TopNav></TopNav>
            {this.state.error && <Alert error={this.state.error} errorDismissHandler={this.onErrorDismiss.bind(this)} />}
            {this.state.waiting && <Waiting />}
            <RouteListener routeChangedHandler={this.onRouteChanged.bind(this)}>
                <Route exact path="/" component={Home}/>
                <Route exact path="/_=_" component={Home}/>
                <Route exact path="/profile" render={(props)=><Profile waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route path="/profile/:username" render={(props)=><Profile waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/home/advisors" render={(props)=><Advisors waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/home/supporters" render={(props)=><Supporters waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/home/contact" component={Contact}/>
                <Route exact path="/home/faq" component={Faq}/>
                <Route exact path="/home/privacy" component={Privacy}/>
                <Route exact path="/home/terms" component={Terms}/>
                <Route exact path="/help" render={(props)=><Help waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/help/:topic" render={(props)=><Help waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/calendar" component={Calendar}/>

                <Route exact path="/gear" render={(props)=><Results waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/gear/create" render={(props)=><Add waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/gear/claim/:id" render={(props)=><Claim waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/gear/edit/:id" render={(props)=><Edit waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/gear/detail/:id" render={(props)=><Detail waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/gear/info/:id" render={(props)=><Info waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/gear/admin/:id" render={(props)=><Admin waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/threads" render={(props)=><Threads waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/threads/create/:recipient/:subject" render={(props)=><Thread waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/threads/create/:recipient" render={(props)=><Thread waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/threads/detail/:id" render={(props)=><Thread waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/admin/models" render={(props)=><Models waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/admin/models/:id" render={(props)=><Model waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/admin/models/:modelId/:yearId" render={(props)=><Year waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
                <Route exact path="/admin/submissions" render={(props)=><Approvals waitHandler={this.onWait.bind(this)} errorHandler={this.onError.bind(this)} {...props} />}/>
            </RouteListener>
            <FooterNav></FooterNav>
            <CookieWarning></CookieWarning>
            <UpgradeWarning></UpgradeWarning>
        </Router>
        );
    };
}