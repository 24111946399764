import * as React from "react";
import {
    RouteComponentProps
} from "react-router-dom";
import { ThreadMessage } from "../../Models/ThreadMessage";
import * as T from "../../Models/Thread";

// route parameters
type RouteParams = {
    id: string;
    recipient: string;
    subject: string;
}

// component properties
export interface ThreadProps extends RouteComponentProps<RouteParams> {
    waitHandler: any;
    errorHandler: any;
}

// component state
export interface ThreadState {
    thread: T.Thread;
    threadMessage: ThreadMessage;
    formValid: boolean;
}

// Thread component
class Thread extends React.Component<ThreadProps, ThreadState> {
    username = (window as any).username;
    constructor(props:ThreadProps, context:ThreadState) {
        super(props, context);
        this.state = {
            thread: {
                senderUsername: this.username,
                recipientUsername: null,
                subject: "",
                messages: []
            },
            threadMessage: {
                senderUsername: this.username,
                messageText: null
            },
            formValid: false
        };
    };

    // componentDidMount
    componentDidMount() {
        // ensure user context
        if (!this.username) { 
            this.props.history.replace("/"); 
        }
        else {
            if (this.props.match.params.subject && this.props.match.params.subject != "" &&
                this.props.match.params.recipient && this.props.match.params.recipient != "") {
                this.setState({thread: {...this.state.thread, 
                    subject: this.props.match.params.subject, 
                    recipientUsername: this.props.match.params.recipient
                }});
            }
            else if (this.props.match.params.recipient && this.props.match.params.recipient != "") {
                this.setState({thread: {...this.state.thread, 
                    recipientUsername: this.props.match.params.recipient
                }});
            }
            
            if (this.props.match.params.id && this.props.match.params.id != "") {
                this.props.waitHandler(true);
                let context = this;

                // fetch the thread details
                fetch(`/api/thread/${this.props.match.params.id}`).then((res: any) => {
                    if (!res.ok) {
                        this.props.waitHandler(false);
                        this.props.errorHandler(res.statusText);
                    }
                    else if (res.status === 204) {
                        // no content returned...thread does not exist
                        this.props.waitHandler(false);
                        this.props.errorHandler((<span>Sorry, the requested thread does not exist.</span>));
                    }
                    else
                        return res.json();
                }).then((thread: T.Thread) => {
                    if (thread) {
                        this.setState({thread: thread});
                        this.props.waitHandler(false);
                    }
                });
            }
        }
    };

    // handleThreadChange
    handleThreadChange(event:any) {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        this.setState({thread: {...this.state.thread, [fieldName]: fieldVal}}, this.validateForm);
    };

    // handleThreadMessageChange
    handleThreadMessageChange(event:any) {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        this.setState({threadMessage: {...this.state.threadMessage, [fieldName]: fieldVal}}, this.validateForm);
    };

    // validates the form and is called after any other set state
    validateForm() {
        var isValid = ((this.state.thread.subject && this.state.thread.subject != "") &&
            (this.state.threadMessage.messageText && this.state.threadMessage.messageText != ""))
        this.setState({formValid: isValid});
    };

    // sends the message
    send() {
        let thread = this.state.thread;
        let msg = this.state.threadMessage;
        this.props.waitHandler(true);
        let context = this;

        // determine update or create
        if (this.props.match.params.id && this.props.match.params.id != "") {
            // perform update
            fetch(`/api/thread/${this.props.match.params.id}`, {
                method: "PUT",
                body: JSON.stringify(msg),
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            }).then(function(res) {
                if (!res.ok) {
                    // save failed...show error message
                    context.props.waitHandler(false);
                    context.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((threadMessage: ThreadMessage) => {
                if (threadMessage) {
                    thread.messages.unshift(threadMessage);
                    this.setState({thread: thread, threadMessage: {
                        senderUsername: this.username,
                        messageText: ""
                    }});
                    context.props.waitHandler(false);
                    context.props.errorHandler(null);
                }
            });
        }
        else {
            //perform create
            thread.messages.unshift(msg);
            fetch("/api/thread", {
                method: "POST",
                body: JSON.stringify(thread),
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            }).then(function(res) {
                if (!res.ok) {
                    // save failed...show error message
                    context.props.waitHandler(false);
                    context.props.errorHandler(res.statusText);
                }

                return res.json();
            }).then((thread: T.Thread) => {
                if (thread) {
                    context.props.waitHandler(false);
                    context.props.errorHandler(null);
                    context.props.history.push(`/threads/detail/${thread.id}`);
                }
            });
        }
    };

    // renders the component
    render() {
        const rows = this.state.thread.messages.map((msg:any) => (
        <div className={(msg.senderUsername == this.username) ? "msg-row" : "msg-row alt"}>
            <div className="msg-row-social">
                <img src={(msg.senderUsername == this.state.thread.senderUsername) ? this.state.thread.senderProfilePic : this.state.thread.recipientProfilePic} className="img-fluid" />
            </div>
            <div className="msg-row-msg">
                <span className="un">{msg.senderUsername}</span>&nbsp;<span className="dt">{(new Date(msg.messageDate)).toLocaleString()}</span><br/>
                <p>{msg.messageText}</p>
            </div>
        </div>
        ));

        return (
            <div className="page">
            <h1>{(this.props.match.params.id) ? `RE: ${this.state.thread.subject}` : "New Thread"}</h1>
            <div className="form-group">
                <label htmlFor="ctrlRecipientUsername">Recipient</label>
                <input type="text" name="recipientUsername" value={this.state.thread.recipientUsername} onChange={this.handleThreadChange.bind(this)} className="form-control" id="ctrlRecipientUsername" placeholder="Recipient username" disabled={(this.props.match.params.id || this.props.match.params.recipient) ? true : false} required />
            </div>
            <div className="form-group" style={(this.props.match.params.id) ? {display: "none"} : {display: "block"}}>
                <label htmlFor="ctrlMsgSubject">Subject</label>
                <input type="text" name="subject" value={this.state.thread.subject} onChange={this.handleThreadChange.bind(this)} className="form-control" id="ctrlMsgSubject" placeholder="Message subject" disabled={(this.props.match.params.id) ? true : false} required />
            </div>
            <div className="form-group">
                <label htmlFor="ctrlMsgBody">Message</label>
                <textarea name="messageText" value={this.state.threadMessage.messageText} onChange={this.handleThreadMessageChange.bind(this)} className="form-control" id="ctrlMsgBody" placeholder="Message body" required></textarea>
            </div>
            <div className="form-group" style={{textAlign: "right"}}>
                <button className="btn btn-primary" onClick={this.send.bind(this)} disabled={!this.state.formValid}><i className="fas fa-envelope"></i></button>
            </div>
            {rows}
            <div>&nbsp;</div>
        </div>
        );
    };
}
 
export default Thread;