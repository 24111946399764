import * as React from "react";
import { GearAttribute } from "../../Models/GearAttribute";

// component properties for the Field comonent
export interface FieldProps { 
    attribute: GearAttribute;
    attributeIndex: number;
    value: string;
    onChangeHandler: any;
}

// state for the Field component
export interface FieldState {
    suggestions:string[];
    ditry:boolean;
}
 
// field component
class Field extends React.Component<FieldProps, FieldState> {
    constructor(props:FieldProps, context:FieldState) {
        super(props, context);
        this.state = {
            suggestions: [],
            ditry: false
        };
    };

    textInputChanged = (evt:any) => {
        let val = evt.target.value;
        this.setState({ditry: true }, this.showSuggestions);
        this.props.onChangeHandler(this.props.attributeIndex, evt);
    };

    choiceWriteInChanged = (evt:any) => {
        this.props.onChangeHandler(this.props.attributeIndex, evt);
    };

    showSuggestions = () => {
        let suggestions:string[] = [];
        if (this.props.attribute.options.length > 0) {
            if (this.props.value.length >= 2) {
                suggestions = this.props.attribute.options.filter(i => i.toLocaleLowerCase().indexOf(this.props.value.toLocaleLowerCase()) != -1);
            }
        }
        this.setState({suggestions: suggestions});
    };

    suggestionClicked = (val:string, evt:any) => {
        let e = {
            target: {
                type: "text",
                value: val
            }
        }
        this.props.onChangeHandler(this.props.attributeIndex, e);
        this.setState({suggestions: []});
    };

    // renders the component
    render() {
        let val = this.props.value;
        let checkVal = false;
        if (this.props.value == "" && this.props.attribute.defaultValue != "" && !this.state.ditry)
            val = this.props.attribute.defaultValue;
        if (this.props.attribute.attributeType == 2) {
            checkVal = (this.props.value != "") ? (this.props.value == "true") : ((this.props.attribute.defaultValue != "") ? (this.props.attribute.defaultValue == "true") : checkVal);
        }

        let tip = (this.props.attribute.tooltip && this.props.attribute.tooltip.length > 0) 
            ? ((this.props.attribute.tooltipPhotoUri && this.props.attribute.tooltipPhotoUri.length > 0) 
                ? (<a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title={this.props.attribute.displayName} data-html="true" data-content={this.props.attribute.tooltip + "<br/><img src='" + this.props.attribute.tooltipPhotoUri + "' class='img-fluid'/>"} tabIndex={this.props.attributeIndex}>?</a>) 
                : (<a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title={this.props.attribute.displayName} data-html="true" data-content={this.props.attribute.tooltip} tabIndex={this.props.attributeIndex}>?</a>)) 
            : (<></>);

        let examples = (this.props.attribute.examples && this.props.attribute.examples.length > 0) ? this.props.attribute.examples : "";

        // determine control to render based on attribute type
        if (this.props.attribute.attributeType == 1) {
            let suggestions = (<></>);
            if (this.state.suggestions && this.state.suggestions.length > 0) {
                let items = this.state.suggestions.map((suggestion:string, index:number) => (
                    <div className="suggestionItem" onMouseDown={this.suggestionClicked.bind(this, suggestion)}>{suggestion}</div>
                ));
                suggestions = (
                    <div className="suggestionBox">
                        {items}
                    </div>
                );
            };

            return (
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label>{this.props.attribute.displayName}&nbsp;</label>
                        {tip}
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <input type="text" className="form-control" id={this.props.attribute.id} name={this.props.attribute.id} value={val} onChange={this.textInputChanged.bind(this)} onBlur={() => this.setState({suggestions: []})} onFocus={this.showSuggestions.bind(this)} onKeyUp={(e:any) => {if (e.key == "Escape") { this.setState({suggestions: []}) }}} />
                        {suggestions}
                        <small id="ctrlweightHelp" className="form-text text-muted">{examples}</small>
                    </div>
                </div>
            );
        }
        else if (this.props.attribute.attributeType == 2) {
            return (
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label>{this.props.attribute.displayName}&nbsp;</label>
                        {tip}
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <div className="custom-control custom-switch" style={{paddingTop: "7px", paddingBottom: "7px"}}>
                            <input type="checkbox" className="custom-control-input" id={this.props.attribute.id} name={this.props.attribute.id} checked={checkVal} onChange={this.props.onChangeHandler.bind(this, this.props.attributeIndex)} />
                            <label className="custom-control-label" htmlFor={this.props.attribute.id}>{this.props.attribute.displayName}</label>
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.props.attribute.attributeType == 3) {
            let options = this.props.attribute.options.map((val:string, index:number) => (
                <option value={val}>{val}</option>
            ));

            return (
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label>{this.props.attribute.displayName}&nbsp;</label>
                        {tip}
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <select className="form-control" id={this.props.attribute.id} name={this.props.attribute.id} value={val} onChange={this.props.onChangeHandler.bind(this, this.props.attributeIndex)}>
                            <option></option>
                            {options}
                        </select>
                        <small id={this.props.attribute.id + "Help"} className="form-text text-muted">{examples}</small>
                        <div className="invalid-feedback">
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.props.attribute.attributeType == 4) {
            // update value to "Other" if the selection isn't an option
            let altVal = "";
            if (this.props.attribute.options.lastIndexOf(val) == -1) {
                altVal = (val == "Other") ? "" : val;
                val = "Other";
            }
            let options = this.props.attribute.options.map((val:string, index:number) => (
                <option value={val}>{val}</option>
            ));
            options.push(<option value="Other">Other</option>);
            let writeIn = (val == "Other") ? (<input type="text" className="form-control" style={{marginTop: "10px"}} placeholder="Write-in value" id={this.props.attribute.id + "_txt"} name={this.props.attribute.id + "_txt"} value={altVal} onChange={this.choiceWriteInChanged.bind(this)} />) : (<></>);

            return (
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label>{this.props.attribute.displayName}&nbsp;</label>
                        {tip}
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <select className="form-control" id={this.props.attribute.id} name={this.props.attribute.id} value={val} onChange={this.choiceWriteInChanged.bind(this)}>
                            <option></option>
                            {options}
                        </select>
                        {writeIn}
                        <small id={this.props.attribute.id + "Help"} className="form-text text-muted">{examples}</small>
                        <div className="invalid-feedback">
                        </div>
                    </div>
                </div>
            );
        }
    };
}
 
export default Field;