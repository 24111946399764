import * as React from "react";
 
// Waiting component
class Waiting extends React.Component {
    // renders the component
    render() {
        return (
            <div className="fullscreen">
                <div className="spinner">
                    <div className="spinnerIcon sp1"></div>
                    <div className="spinnerIcon sp2"></div>
                    <div className="spinnerIcon sp3"></div>
                    <div className="spinnerIcon sp4"></div>
                </div>
                <div className="spinnerfull"></div>
            </div>
        );
    };
}
 
export default Waiting;