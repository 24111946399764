import * as React from "react";
import { Link } from "react-router-dom";
import  *  as B from "../../Models/BoardMember";

// properties for the BoardMember component
export interface BoardMemberProps {
    boardMember: B.BoardMember;
}
  
// state for the BoardMember component
export interface BoardMemberState {
}
 
// alert component for displaying error messages
class BoardMember extends React.Component<BoardMemberProps, BoardMemberState> {
    constructor(props:BoardMemberProps, context:BoardMemberState) {
        super(props, context);
        this.state = {

        };
    };
    
    componentDidMount = () => {
        
    };

    // renders the component
    render() {
        if (!this.props.boardMember)
            return (<></>);

        return (
            <div className="profile-container">
                <div className="photo-container">
                    <img src={this.props.boardMember.photo} />
                </div>
                <div className="profile-details">
                    <div className="profile-details-inner">
                        <h4 style={{paddingTop: "0px"}}>{this.props.boardMember.displayName} (<Link to={"/profile/" + this.props.boardMember.username}>{this.props.boardMember.username}</Link>) - {this.props.boardMember.title}</h4>
                        <p>{this.props.boardMember.bio}</p>
                    </div>
                </div>
            </div>
        );
    };
}
 
export default BoardMember;