import * as React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import { HelpItem } from "../../Models/HelpItem";

// route parameters
type RouteParams = {
    topic: string;
}

// component properties
export interface HelpProps extends RouteComponentProps<RouteParams> {
    waitHandler: any;
    errorHandler: any;
}

// component state
export interface HelpState {
    help:HelpItem[];
    selectedHelp?:HelpItem;
}

// Help component
class Help extends React.Component<HelpProps, HelpState> {
    search:string = null;
    constructor(props:HelpProps, context:HelpState) {
        super(props, context);
        this.state = {
            help: []
        };
    }

    componentDidMount = () => {
        // perform the sponsors
        fetch("/data/help.json").then((res: any) => {
            return res.json();
        }).then((items: HelpItem[]) => {
            let selectedHelp = null;
            if (this.props.match.params.topic && this.props.match.params.topic.length > 0) {
                selectedHelp = items.find(i => i.key == this.props.match.params.topic);
            }
            this.setState({help: items, selectedHelp: selectedHelp});
        });
    };

    // componentWillReceiveProps...load is done here to handle different filters
    componentWillReceiveProps(nextProps:any) {
        if (nextProps.match.params.topic) {
            let selectedHelp = this.state.help.find(i => i.key == nextProps.match.params.topic);
            this.setState({selectedHelp: selectedHelp});
        }
    }

    videoSelected = (topic:string) => {
        this.props.history.push(`/help/${topic}`);
    };

    // renders the component
    render() {
        let title = "Select a help topic";
        let desc = "";
        let video = (<></>);
        if (this.state.selectedHelp) {
            title = this.state.selectedHelp.title;
            desc = this.state.selectedHelp.description;
            video = (
                <div className="videocontainer">
                    <iframe src={`https://www.youtube.com/embed/${this.state.selectedHelp.video}`} frameBorder="0" className="video"></iframe>
                </div>
            );
        }
        else {
            title = "GuitarLogs.com welcome";
            desc = "GuitarLogs.com is a community-sourced online repository for early Fender guitars. Please use the help topics to learn more about the log and how to use it. If you have additional questions, please see the FAQ section or use the Contact link to send us a message.";
            video = (
                <div className="videocontainer">
                    <iframe src="https://www.youtube.com/embed/biW5lC1QGEk" frameBorder="0" className="video"></iframe>
                </div>
            );
        }


        let helps = this.state.help.map((helpItem:HelpItem, index:number) => (
            <li className="media" onClick={this.videoSelected.bind(this, helpItem.key)}>
                <img src={`https://img.youtube.com/vi/${helpItem.video}/default.jpg`} className="mr-3" alt="..."/>
                <div className="media-body">
                    <h5 className="mt-0 mb-1">{helpItem.title}</h5>
                    <div className="descWrapper">
                        <p className="descWrapperText">{helpItem.description}</p>
                    </div>
                </div>
            </li>
        ));



        return (
        <div className="page">
            <h1>Help Topics</h1>
            <div className="row">
                <div className="col-lg-8 col-md-12">
                    {video}
                    <h3>{title}</h3>
                    <p>{desc}</p>
                </div>
                <div className="col-lg-4 col-md-12">
                    <ul className="list-unstyled">
                    {helps}
                    </ul>
                </div>
            </div>
        </div>
        );
    };
}
 
export default Help;