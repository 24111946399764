import * as React from "react";
import { Link } from "react-router-dom";
 
// top navigation component
class TopNav extends React.Component {
    auth:boolean = (window as any).userStatus;
    user:any = (window as any).user;

    // renders the component
    render() {
        let profileMenu = (
        <div className="identity d-md-none" style={{float: "right"}}>
            <div className="nav-item">
                <span className="nav-link" style={{padding: "0px", cursor: "pointer"}} id="profileSmall"><img src={this.user.Photo} alt="" /></span>
                <div className="newThreadIndicator"></div>
            </div>
            <div className="user-menu-xs">
                <div className="user-menu-xs-bg"></div>
                <div className="user-menu-xs-container">
                    <h5 id="profileSmallClose" style={{cursor: "pointer"}}>Close</h5>
                    <div className="content">
                        <div className="identity">
                            <div>
                                <img src={this.user.Photo} alt={this.user.DisplayName}/>
                            </div>
                            <div>
                                <h3>{this.user.DisplayName}</h3>
                                <h4>{this.user.Username}</h4>
                            </div>
                        </div>
                        <div className="menu">
                            <Link className="dropdown-item" to="/profile">Profile</Link>
                            <Link className="dropdown-item" to="/threads">Messages <span className="badge badge-secondary threadCnt"></span></Link>
                            <Link className="dropdown-item" to="/guitars/create">Register Guitar</Link>
                            {(this.user.MemberType == "Admin") ? (<Link className="dropdown-item" to="/admin/models">Model Config (admin)</Link>) : ""}
                            {(this.user.MemberType == "Admin") ? (<Link className="dropdown-item" to="/admin/submissions">Submissions (admin)</Link>) : ""}
                            <a className="dropdown-item" href="/auth/signout">Sign-out</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
        let profileMenuXl = (
        <div className="identity d-none d-md-block" style={{float: "right"}}>
            <span className="nav-link" style={{cursor: "pointer"}} id="navbarDropdownProfileBig" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <Link className="nav-link" to="/profile" style={{padding: "0px"}}><img src={this.user.Photo} alt={this.user.DisplayName} /></Link>
                <div className="newThreadIndicator"></div>
            </span>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownProfileBig">
                <h4>{this.user.DisplayName}</h4>
                <Link className="dropdown-item" to="/profile">Profile</Link>
                <Link className="dropdown-item" to="/threads">Messages <span className="badge badge-secondary threadCnt"></span></Link>
                <Link className="dropdown-item" to="/gear/create">Register Gear</Link>
                {(this.user.MemberType == "Admin" && this.user.Username == "richdizz") ? (<Link className="dropdown-item" to="/admin/models">Model Config (admin)</Link>) : ""}
                {(this.user.MemberType == "Admin") ? (<Link className="dropdown-item" to="/admin/submissions">Submissions (admin)</Link>) : ""}
                <a className="dropdown-item" href="/auth/signout">Sign-out</a>
            </div>
        </div>
        );

        if (!this.auth) {
            profileMenu = (
            <div className="identity d-md-none" style={{float: "right"}}>
                <div className="nav-item">
                    <a className="nav-link" href={(window as any).login}>Sign-in</a>
                </div>
            </div>
            );

            profileMenuXl = (
            <div className="identity d-none d-md-block" style={{float: "right"}}>
                <div className="nav-item">
                    <a className="nav-link" href={(window as any).login}>Sign-in</a>
                </div>
            </div>
            );
        }

        

        return (
        <div className="navbar fixed-top navbar-expand-md navbar-light bg-light">
            <div className="container">
                <Link className="navbar-brand d-none d-md-block" to="/">
                    <img src="/images/logo_rgb.png" alt="GuitarLogs.com logo" className="logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-bars"></i>
                </button>
                <div className="logo-container d-md-none" style={{verticalAlign: "top"}}>
                    <Link to="/">
                        <img src="/images/logo_rgb.png" alt="GuitarLogs.com logo" className="logo" />
                    </Link>
                </div>  
                {profileMenu}
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" style={{cursor: "pointer"}} id="navbarDropdownModel" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                MODELS
                            </span>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownModel">
                                <div className="dropdown-menu-2col">
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Bass%20VI">Bass VI</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Mandocaster">Mandocaster</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Broadcaster">Broadcaster</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Musicmaster">Musicmaster</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Duo-Sonic">Duo-Sonic</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Mustang">Mustang</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Electric%20XII">Electric XII</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Nocaster">Nocaster</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Esquire">Esquire</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Precision%20Bass">Precision Bass</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Jaguar">Jaguar</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Stratocaster">Stratocaster</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Jazz%20Bass">Jazz Bass</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Telecaster">Telecaster</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?make=Fender&model=Jazzmaster">Jazzmaster</Link>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" style={{cursor: "pointer"}} id="navbarDropdownYear" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                YEARS
                            </span>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownYear">
                                <div className="dropdown-menu-2col">
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear">All</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1958&yearMax=1958">1958</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1950&yearMax=1950">1950</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1959&yearMax=1959">1959</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1951&yearMax=1951">1951</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1960&yearMax=1960">1960</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1952&yearMax=1952">1952</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1961&yearMax=1961">1961</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1953&yearMax=1953">1953</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1962&yearMax=1962">1962</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1954&yearMax=1954">1954</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1963&yearMax=1963">1963</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1955&yearMax=1955">1955</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1964&yearMax=1964">1964</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1956&yearMax=1956">1956</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1965&yearMax=1965">1965</Link>
                                    <Link className="dropdown-item dropdown-item-2col" to="/gear?yearMin=1957&yearMax=1957">1957</Link>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" style={{cursor: "pointer"}} id="navbarDropdownStatus" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                POPULAR
                            </span>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownStatus">
                                <Link className="dropdown-item" to="/gear?collection=Blackguards">Blackguards</Link>
                                <Link className="dropdown-item" to="/gear?model=Stratocaster&yearMin=1954&yearMax=1954">1954 Stratocasters</Link>
                                <Link className="dropdown-item" to="/gear?status=For%20sale">For Sale</Link>
                                <Link className="dropdown-item" to="/gear?sortBy=createdDate%20DESC">Newly Added</Link>
                                <Link className="dropdown-item" to="/gear?collection=Custom%20Colors">Custom Colors</Link>
                                <Link className="dropdown-item" to="/gear?collection=2021%20BlackguardLogs%20Calendar">2021 BlackguardLogs Calendar</Link>
                                <Link className="dropdown-item" to="/gear?collection=2022%20GuitarLogs%20Calendar">2022 GuitarLogs Calendar</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" style={{cursor: "pointer"}} id="navbarDropdownStatus" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                ABOUT
                            </span>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownStatus">
                                <Link className="dropdown-item" to="/home/faq">FAQ</Link>
                                <Link className="dropdown-item" to="/help">Help</Link>
                                <Link className="dropdown-item" to="/home/supporters">Supporters</Link>
                                <Link className="dropdown-item" to="/home/privacy">Privacy</Link>
                                <Link className="dropdown-item" to="/home/terms">Terms of use</Link>
                                <Link className="dropdown-item" to="/home/contact">Contact</Link>
                            </div>
                        </li>
                    </ul>
                </div>
                {profileMenuXl}
            </div>
        </div>
        );
    };
}
 
export default TopNav;