import * as React from "react";
import { Link } from "react-router-dom";
 
// footer navigation component
class FooterNav extends React.Component {
    // renders the component
    render() {
        return (
        <div className="footer">
            <div className="footer1">
                <div className="footer-logo">
                    <img src="/images/logo_white.png" alt="GuitarLogs.com logo"/>
                </div>
                <div className="footer-social">
                    <div className="social-icon">
                        <a href="https://www.facebook.com/guitarlogs"><i className="fab fa-facebook-f"></i></a>
                    </div>
                    <div className="social-icon">
                        <a href="https://www.youtube.com/channel/UCIgRQq9MQKX1IxzMaH-Gawg"><i className="fab fa-youtube"></i></a>
                    </div>
                    <div className="social-icon">
                        <a href="https://twitter.com/GuitarLogs"><i className="fab fa-twitter"></i></a>
                    </div>
                    <div className="social-icon">
                        <a href="https://www.instagram.com/officialguitarlogs/"><i className="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
            <div className="footer2">
                <div className="container d-md-none">
                    <div className="accordion" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button className="btn btn-lin collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    About
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="footer-map-item"><Link to="/home/faq">FAQ</Link></div>
                                <div className="footer-map-item"><Link to="/help">Help</Link></div>
                                <div className="footer-map-item"><Link to="/home/supporters">Supporters</Link></div>
                                <div className="footer-map-item"><Link to="/home/privacy">Privacy</Link></div>
                                <div className="footer-map-item"><Link to="/home/terms">Terms of use</Link></div>
                                <div className="footer-map-item"><Link to="/home/contact">Contact</Link></div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Models
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div className="footer-map-item"><Link to="/gear">All</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Bass%20VI">Bass VI</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Broadcaster">Broadcaster</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Duo-Sonic">Duo-Sonic</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Electric%20XII">Electric XII</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Esquire">Esquire</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Jaguar">Jaguar</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Jazz%20Bass">Jazz Bass</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Jazzmaster">Jazzmaster</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Mandocaster">Mandocaster</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Musicmaster">Musicmaster</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Mustang">Mustang</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Nocaster">Nocaster</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Precision%20Bass">Precision Bass</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Stratocaster">Stratocaster</Link></div>
                                <div className="footer-map-item"><Link to="/gear?make=Fender&model=Telecaster">Telecaster</Link></div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Years
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="footer-map-item"><Link to="/gear">All</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1950&yearMax=1950">1950</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1951&yearMax=1951">1951</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1952&yearMax=1952">1952</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1953&yearMax=1953">1953</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1954&yearMax=1954">1954</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1955&yearMax=1955">1955</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1956&yearMax=1956">1956</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1957&yearMax=1957">1957</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1958&yearMax=1958">1958</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1959&yearMax=1959">1959</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1960&yearMax=1960">1960</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1961&yearMax=1961">1961</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1962&yearMax=1962">1962</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1963&yearMax=1963">1963</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1964&yearMax=1964">1964</Link></div>
                                <div className="footer-map-item"><Link to="/gear?yearMin=1965&yearMax=1965">1965</Link></div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingFour">
                                <h2 className="mb-0">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Popular
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div className="footer-map-item"><Link to="/gear?collection=Blackguards">Blackguards</Link></div>
                                <div className="footer-map-item"><Link to="/gear?model=Stratocaster&yearMin=1954&yearMax=1954">1954 Stratocasters</Link></div>
                                <div className="footer-map-item"><Link to="/gear?status=For%20sale">For Sale</Link></div>
                                <div className="footer-map-item"><Link to="/gear?sortBy=createdDate%20DESC">Newly Added</Link></div>
                                <div className="footer-map-item"><Link to="/gear?collection=Custom%20Colors">Custom Colors</Link></div>
                                <div className="footer-map-item"><Link to="/gear?collection=2021%20BlackguardLogs%20Calendar">2021 BlackguardLogs Calendar</Link></div>
                                <div className="footer-map-item"><Link to="/gear?collection=2022%20GuitarLogs%20Calendar">2022 GuitarLogs Calendar</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container d-none d-md-block d-lg-block d-xl-block">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-map-head">About</div>
                            <div className="footer-map-item"><Link to="/home/faq">FAQ</Link></div>
                            <div className="footer-map-item"><Link to="/help">Help</Link></div>
                            <div className="footer-map-item"><Link to="/home/sponsors">Supporters</Link></div>
                            <div className="footer-map-item"><Link to="/home/privacy">Privacy</Link></div>
                            <div className="footer-map-item"><Link to="/home/terms">Terms of use</Link></div>
                            <div className="footer-map-item"><Link to="/home/contact">Contact</Link></div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-map-head">Models</div>
                            <div className="footer-map-item"><Link to="/gear">All</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Bass%20VI">Bass VI</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Broadcaster">Broadcaster</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Duo-Sonic">Duo-Sonic</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Electric%20XII">Electric XII</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Esquire">Esquire</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Jaguar">Jaguar</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Jazz%20Bass">Jazz Bass</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Jazzmaster">Jazzmaster</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Mandocaster">Mandocaster</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Musicmaster">Musicmaster</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Mustang">Mustang</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Nocaster">Nocaster</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Precision%20Bass">Precision Bass</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Stratocaster">Stratocaster</Link></div>
                            <div className="footer-map-item"><Link to="/gear?make=Fender&model=Telecaster">Telecaster</Link></div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-map-head">Years</div>
                            <div className="footer-map-item"><Link to="/gear">All</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1950&yearMax=1950">1950</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1951&yearMax=1951">1951</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1952&yearMax=1952">1952</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1953&yearMax=1953">1953</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1954&yearMax=1954">1954</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1955&yearMax=1955">1955</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1956&yearMax=1956">1956</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1957&yearMax=1957">1957</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1958&yearMax=1958">1958</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1959&yearMax=1959">1959</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1960&yearMax=1960">1960</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1961&yearMax=1961">1961</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1962&yearMax=1962">1962</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1963&yearMax=1963">1963</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1964&yearMax=1964">1964</Link></div>
                            <div className="footer-map-item"><Link to="/gear?yearMin=1965&yearMax=1965">1965</Link></div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-map-head">Popular</div>
                            <div className="footer-map-item"><Link to="/gear?collection=Blackguards">Blackguards</Link></div>
                            <div className="footer-map-item"><Link to="/gear?model=Stratocaster&yearMin=1954&yearMax=1954">1954 Stratocasters</Link></div>
                            <div className="footer-map-item"><Link to="/gear?status=For%20sale">For Sale</Link></div>
                            <div className="footer-map-item"><Link to="/gear?sortBy=createdDate%20DESC">Newly Added</Link></div>
                            <div className="footer-map-item"><Link to="/gear?collection=Custom%20Colors">Custom Colors</Link></div>
                            <div className="footer-map-item"><Link to="/gear?collection=2021%20BlackguardLogs%20Calendar">2021 BlackguardLogs Calendar</Link></div>
                            <div className="footer-map-item"><Link to="/gear?collection=2022%20GuitarLogs%20Calendar">2022 GuitarLogs Calendar</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        );
    };
}
 
export default FooterNav;