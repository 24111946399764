import { info } from "console";
import * as React from "react";
import {RouteComponentProps,Link} from "react-router-dom";
import { Change } from "../../Models/Change";
import { ChangeLog } from "../../Models/ChangeLog";
import { Gear } from "../../Models/Gear";
import { GearAttribute } from "../../Models/GearAttribute";
import { InfoSubmission } from "../../Models/InfoSubmission";
import { ModelConfig } from "../../Models/ModelConfig";

// route parameters
type RouteParams = {
    id: string;
}

// component properties
export interface DetailProps extends RouteComponentProps<RouteParams> {
    waitHandler: any;
    errorHandler: any
}

// component state
export interface DetailState {
    info: InfoSubmission[];
    infoLoaded: boolean;
    changeLog: ChangeLog[];
    changeLogLoaded: boolean;
    zoom: boolean;
    spotlight: string;
}

// Admin component
class Admin extends React.Component<DetailProps, DetailState> {
    username = (window as any).username;
    user = (window as any).user;
    constructor(props:DetailProps, context:DetailState) {
        super(props, context);
        this.state = {
            info: [],
            infoLoaded: false,
            changeLog: [],
            changeLogLoaded: false,
            zoom: false,
            spotlight: ""
        };
    };

    // componentDidMount
    componentDidMount() {
        if (!this.user || !this.user.IsAdmin) { 
            this.props.history.replace("/"); 
        }
        else {
            this.props.waitHandler(true);
            
            // load the info submitted
            fetch(`/api/info/${this.props.match.params.id}`).then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((info: InfoSubmission[]) => {
                this.setState({info: info, infoLoaded: true});
                if (this.state.changeLogLoaded)
                    this.props.waitHandler(false);
            });

            // load the changelog
            fetch(`/api/changelog/${this.props.match.params.id}`).then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((changeLog: ChangeLog[]) => {
                this.setState({changeLog: changeLog, changeLogLoaded: true});
                if (this.state.infoLoaded)
                    this.props.waitHandler(false);
            });
        }
    };

    // renders the component
    render() {
        if (!this.state.infoLoaded || !this.state.changeLogLoaded)
            return (<></>);

        const infoRows = this.state.info.map((info:InfoSubmission, index:number) => {
            let imgs = info.photos.map((img:string, index:number) => ( 
                <li>
                    <div className="img-wrapper">
                        <div className="b-block gallery-img" style={{backgroundImage: `url(https://guitarlogsv2.blob.core.windows.net/gear/${info.gearId}/thumb_${img})`}} onClick={() => this.setState({zoom: true, spotlight: `https://guitarlogsv2.blob.core.windows.net/gear/${info.gearId}/${img}`})}></div>
                    </div> 
                </li>
            ));
            return (<div className={(index % 2 === 0) ? "msg-row" : "msg-row alt"}>
                    <div className="msg-row-msg">
                        <span className="un">{info.createdBy}</span>&nbsp;<span className="dt">{(new Date(info.createdDate)).toLocaleString()}</span><br/>
                        <p>{info.message}</p>
                        <ul className="info-pics">
                            {imgs}
                        </ul>
                    </div>
                </div>);
            });
        if (infoRows.length == 0)
            infoRows.push(<div className="msg-row" style={{textAlign: "center"}}>No information submitted</div>);

        let changeRows = [];
        this.state.changeLog.forEach((item:ChangeLog, index:number) => {
            let rowColor = (index % 2 === 0) ? "rgba(0,0,0,.05)" : "inherit";
            item.changes.forEach((change:Change, i:number) => {
                changeRows.push(<tr style={{backgroundColor: rowColor}}>
                    <td>{new Date(item.createdDate).toLocaleString()}</td>
                    <td>{item.createdBy}</td>
                    <td>{change.field}</td>
                    <td>{change.before}</td>
                    <td>{change.after}</td>
                </tr>)
            });
        });
        if (changeRows.length == 0)
            changeRows.push(<tr><td colSpan={5} style={{textAlign: "center"}}>No changes</td></tr>);
        
        return (
        <div className="page">
            <h1>Submissions</h1>
            <h3>Unsolicited Info</h3>
            <div className="imgFullContainer" style={{display: (this.state.zoom) ? "block" : "none"}}>
                <div className="imgFull" style={{backgroundImage: "url(" + this.state.spotlight + ")"}}></div>
                <div className="imgClose" onClick={() => this.setState({zoom: false})}><i className="far fa-times-circle"></i></div>
                <div className="imgDownload" onClick={() => window.location.href = this.state.spotlight }><i className="fas fa-download"></i></div>
            </div>
            {infoRows}
            <h3>Change Log</h3>
            <table className="table">
                <thead>
                    <tr>
                        <th style={{width: "25%"}}>Date</th>
                        <th style={{width: "15%"}}>User</th>
                        <th style={{width: "15%"}}>Field</th>
                        <th style={{width: "25%"}}>From</th>
                        <th style={{width: "25%"}}>To</th>
                    </tr>
                </thead>
                <tbody>
                    {changeRows}
                </tbody>
            </table>
            <div>&nbsp;</div>
        </div>
        );
    };
}
 
export default Admin;