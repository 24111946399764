import * as React from "react";

// properties for the ImageUploaderItem component
export interface ImageUploaderItemProps { 
    disabled?: boolean;
    image: string;
    onDelete: any;
    onReorder: any;
    allowDelete: boolean;
    style: any;
    index: number;
    size: number;
}
  
// state for the ImageUploaderItem
export interface ImageUploaderItemState {
}
 
// ImageUploaderItem
class ImageUploaderItem extends React.Component<ImageUploaderItemProps, ImageUploaderItemState> {
    constructor(props:ImageUploaderItemProps, context:ImageUploaderItemState) {
        super(props, context);
    };

    // renders the component
    render() {
        const overlay = (this.props.disabled) ? (<><div className="img-uploader-img-overlay" ></div><div className="spinner-border text-primary" role="status"></div></>) : (<></>);
        const style = { backgroundImage: "url(" + this.props.image +")"};
        const btns = (this.props.allowDelete) ? (
        <div style={{textAlign: "center", fontSize: "10px"}}>
            <button className="btn btn-light" onClick={this.props.onReorder.bind(this, this.props.index, 1)} disabled={this.props.disabled || this.props.index == 0}><i className="fas fa-angle-double-left" title="Move to front"></i></button>&nbsp;
            <button className="btn btn-light" onClick={this.props.onReorder.bind(this, this.props.index, 2)} disabled={this.props.disabled || this.props.index == 0}><i className="fas fa-angle-left" title="Move forward one"></i></button>&nbsp;
            <button className="btn btn-light" onClick={this.props.onDelete} disabled={this.props.disabled}><i className="fas fa-trash" title="Delete"></i></button>&nbsp;
            <button className="btn btn-light" onClick={this.props.onReorder.bind(this, this.props.index, 3)} disabled={this.props.disabled || this.props.index == this.props.size - 1}><i className="fas fa-angle-right" title="Move back one"></i></button>&nbsp;
            <button className="btn btn-light" onClick={this.props.onReorder.bind(this, this.props.index, 4)} disabled={this.props.disabled || this.props.index == this.props.size - 1}><i className="fas fa-angle-double-right" title="Move to back"></i></button>
        </div>
        ) : null;
        return (
            <div className="img-uploader-item" style={this.props.style}>
                {overlay}
                <div className="img-uploader-img" style={style}></div>
                {btns}
            </div>
        );
    };
}
 
export default ImageUploaderItem;