import * as React from "react";
import {
    RouteComponentProps
} from "react-router-dom";
import { Gear } from "../../Models/Gear";
import { InfoSubmission } from "../../Models/InfoSubmission";
import ImageUploader from "../General/ImageUploader";

// route parameters
type RouteParams = {
    id: string;
}

// component properties
export interface InfoProps extends RouteComponentProps<RouteParams> {
    waitHandler: any;
    errorHandler: any;
}

// component state
export interface InfoState {
    info: InfoSubmission;
    formValid: boolean;
    photoUris: string[];
}

// Info component
class Info extends React.Component<InfoProps, InfoState> {
    username = (window as any).username;
    constructor(props:InfoProps, context:InfoState) {
        super(props, context);
        this.state = {
            info: null,
            formValid: false,
            photoUris: []
        };
    };

    // componentDidMount
    componentDidMount() {
        this.props.waitHandler(true);
        let context = this;

        // fetch the gear details
        fetch(`/api/gear/${this.props.match.params.id}`).then((res: any) => {
            if (!res.ok) {
                this.props.waitHandler(false);
                this.props.errorHandler(res.statusText);
            }
            else if (res.status === 204) {
                // no content returned...thread does not exist
                this.props.waitHandler(false);
                this.props.errorHandler((<span>Sorry, the requested thread does not exist.</span>));
            }
            else
                return res.json();
        }).then((gearItem: Gear) => {
            if (gearItem) {
                let info = {
                    gearId: gearItem.id,
                    category: gearItem.category,
                    make: gearItem.make,
                    model: gearItem.model,
                    year: gearItem.year,
                    serialNumber: gearItem.serialNumber,
                    createdBy: (this.username && this.username.length > 0) ? this.username : "",
                    message: "",
                    photos: []
                }
                this.setState({info: info});
                this.props.waitHandler(false);
            }
        });
    };

    // handleMessageChange
    handleMessageChange(event:any) {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        this.setState({info: {...this.state.info, [fieldName]: fieldVal}}, this.validateForm);
    };

    // validates the form and is called after any other set state
    validateForm() {
        this.setState({formValid: (this.state.info.message && this.state.info.message != "")});
    };

    // submit the message
    submit() {
        this.props.waitHandler(true);
        let context = this;

        fetch("/api/info", {
            method: "POST",
            body: JSON.stringify(this.state.info),
            headers: {
                "accept": "application/json",
                "content-type": "application/json"
            }
        }).then(function(res) {
            if (!res.ok) {
                // save failed...show error message
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }

            return res.json();
        }).then((info: InfoSubmission) => {
            if (info) {
                context.props.waitHandler(false);
                context.props.errorHandler(null);
                ($ as any)("#modalConfirm").modal({backdrop: "static"}, "show");
            }
        });
    };

    multiFilesPicked = (fileInfo:any) => {
        // add the new file to state using the local URI
        let photoUris = this.state.photoUris;
        let photosLength = photoUris.length;
        photoUris.push(fileInfo.photo);
        this.setState({photoUris: photoUris});
        
        // initialize the form data
        let formData = new FormData();
        formData.append(fileInfo.name, fileInfo.file);
        let context = this;
        fetch(`/api/gear/${this.state.info.gearId}/photo`, {
            method: "POST",
            body: formData
        }).then(function(res) {
            if (!res.ok) {
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            else {
                return res.text();
            }
        }).then((photoId) => {
            let photoUris = context.state.photoUris;
            let info = context.state.info;
            info.photos.push(photoId);
            photoUris[photosLength] = `https://guitarlogsv2.blob.core.windows.net/gear/${this.state.info.gearId}/thumb_${photoId}`;
            this.setState({info: info, photoUris: photoUris});
        });
    };

    fileDeleted = (index:number) => {
        var photos = this.state.photoUris;
        var info = this.state.info;
        photos.splice(index, 1);
        info.photos.splice(index, 1);
        this.setState({photoUris: photos, info: info});
    };

    reorderPic = (index:number, type:number) => {
        var photoUris = this.state.photoUris;
        var info = this.state.info;

        // get instance of item in array
        let item = photoUris[index];
        let id = info.photos[index];

        // remove existing item from array
        photoUris.splice(index, 1);
        info.photos.splice(index, 1);

        // determine new index for item
        let newIndex:number = 0;
        if (type == 1)
            newIndex = 0;
        else if (type == 2)
            newIndex = index - 1;
        else if (type == 3)
            newIndex = index + 1;
        else if (type == 4)
            newIndex = photoUris.length;

        // add the item back in the correct location
        console.log(`Move ${index} to ${newIndex}`);
        photoUris.splice(newIndex, 0, item);
        info.photos.splice(newIndex, 0, id);
        this.setState({photoUris: photoUris, info: info});
    };

    // this is a toggle used to help re-render the component
    refreshImages = () => {};//this.setState({refresh: !this.state.refresh});

    // closes the dialog and navigates back to the gear item
    closeConfirmation = () => {
        ($ as any)("#modalConfirm").modal("hide");

        var context = this;
        $("#modalConfirm").on('hidden.bs.modal', function (e) {
            context.props.waitHandler(false);
            context.props.errorHandler(null);
            context.props.history.push(`/gear/detail/${context.state.info.gearId}`);
        });
    };

    // renders the component
    render() {
        if (!this.state.info)
            return (<></>);
        return (
            <div className="page">
            <h1>Info on {this.state.info.year} {this.state.info.make} {this.state.info.model} ({this.state.info.serialNumber})</h1>
            <p>If you have useful information on this entry but are not the current owner, use this form to provide that information to log administrators. Thanks!</p>
            <div className="form-group">
                <label htmlFor="ctrlCreatedBy">From</label>
                <input type="text" name="createdBy" value={this.state.info.createdBy} onChange={this.handleMessageChange.bind(this)} className="form-control" id="ctrlCreatedBy" placeholder="Email in case with need to follow-up" disabled={(this.username && this.username.length > 0)} required />
            </div>
            <div className="form-group">
                <label htmlFor="ctrlMsgBody">Message</label>
                <textarea name="message" value={this.state.info.message} onChange={this.handleMessageChange.bind(this)} className="form-control" id="ctrlMsgBody" placeholder="Message body" required></textarea>
            </div>
            <div className="form-group">
                <ImageUploader filesPicked={this.multiFilesPicked.bind(this)} images={this.state.photoUris} refresh={this.refreshImages} multiple={true} header={"Photos"} allowDelete={true} onDelete={this.fileDeleted.bind(this)} onReorder={this.reorderPic.bind(this)} immediateUpload={true} />
            </div>
            <div className="form-group" style={{textAlign: "right"}}>
                <button className="btn btn-primary" onClick={this.submit.bind(this)} disabled={!this.state.formValid}><i className="fas fa-envelope"></i></button>
            </div>
            <div>&nbsp;</div>

            <div className="modal fade" id="modalConfirm" role="dialog" aria-labelledby="modalConfirmTitle" aria-hidden="false">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalConfirmTitle">Info Submission</h5>
                        </div>
                        <div className="modal-body">
                            Thank you for providing additional information on {this.state.info.year} {this.state.info.make} {this.state.info.model} ({this.state.info.serialNumber}). The admins will review the information and take appropriate action. Thanks!
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeConfirmation.bind(this)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    };
}
 
export default Info;