import * as React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import { PayPalButton } from "react-paypal-button-v2";

// component properties
export interface CalendarProps extends RouteComponentProps<any> {
    waitHandler: any;
    errorHandler: any;
}

// component state
export interface CalendarState {
    selectedIndex:number;
    quantity:number;
    complete: boolean;
    price:number;
}

// Calendar component
class Calendar extends React.Component<CalendarProps, CalendarState> {
    username = (window as any).username;
    constructor(props:CalendarProps, context:CalendarState) {
        super(props, context);
        this.state = {
            selectedIndex: -1,
            quantity: 1,
            complete: false,
            price: 0.0
        }
    };

    // renders the component
    render() {
        return (
        <div className="page">
            <h1>2022 GuitarLogs.com Calendar</h1>
            <div className="row">
                <div className="col-sm-6">
                    <img src="/images/CalendarPromo.png" className="img img-fluid" alt="2021 GuitarLogs Calendar"/>
                </div>
                <div className="col-sm-6">
                    <p>GuitarLogs is proud to announce the limited edition 2022 GuitarLogs calendar. The calendar features 12 stunning months of pre-CBS Fender guitars from Fender's golden era. It has a little of everthing...blackguards, whiteguards, Stratocasters, offsets, and some amazingly rare custom colors like Roman Red, Shell Pink, Foam Green, and Inca Silver. <b>SPOILER ALERT</b>: If you want to see all the guitars featured in the calendar, look at the <Link to="/gear?collection=2022%20GuitarLogs%20Calendar">2022 GuitarLogs Calendar</Link> collection. This limited run calendar is 11 x 8.5 inches (opens to 11 x 17 inches) and printed on high-quality gloss paper with aqueous coating. Calendar prices vary by location to offset the additional shipping cost (it is $11.26 to ship a calendar internationally vs $2.40 domestic). 100% of the proceeds will go towards working musician(s) impacted by COVID-19. Calendars will ship in late November.</p>
                    <p>SOLD OUT</p>
                    <label style={(this.state.complete) ? {display: "none"} : {display: "block"}}>Ship to location:</label>
                    <div className={(this.state.selectedIndex == 1) ? "calOption selected" : "calOption"} style={(this.state.complete || (this.state.selectedIndex != -1 && this.state.selectedIndex != 1)) ? {display: "none"} : {display: "block"}} onClick={() => (this.state.selectedIndex == 1) ? this.setState({selectedIndex: -1}) : this.setState({selectedIndex: 1, price: 23.95})}>
                        <span>United States</span>
                        <span style={{float: "right"}}>$23.95</span>
                    </div>
                    <div className={(this.state.selectedIndex == 2) ? "calOption selected" : "calOption"} style={(this.state.complete || (this.state.selectedIndex != -1 && this.state.selectedIndex != 2)) ? {display: "none"} : {display: "block"}} onClick={() => (this.state.selectedIndex == 2) ? this.setState({selectedIndex: -1}) : this.setState({selectedIndex: 2, price: 26.95})}>
                        <span>Canada</span>
                        <span style={{float: "right"}}>$26.95</span>
                    </div>
                    <div className={(this.state.selectedIndex == 3) ? "calOption selected" : "calOption"} style={(this.state.complete || (this.state.selectedIndex != -1 && this.state.selectedIndex != 3)) ? {display: "none"} : {display: "block"}} onClick={() => (this.state.selectedIndex == 3) ? this.setState({selectedIndex: -1}) : this.setState({selectedIndex: 3, price: 29.95})}>
                        <span>Other countries</span>
                        <span style={{float: "right"}}>$29.95</span>
                    </div>
                    
                    <div style={(this.state.selectedIndex == -1 || this.state.complete) ? {display: "none"} : {display: "block"}}>
                        <label htmlFor="ctrlQty">Quantity</label>
                        <select className="form-control" style={{fontSize: "18px", fontWeight: "bold"}} value={this.state.quantity} onChange={(e) => this.setState({quantity: parseInt(e.target.value)})}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                        </select>
                    </div>
                    
                    <div className="totals" style={(this.state.selectedIndex == -1 || this.state.complete) ? {display: "none"} : {display: "block"}}>
                        <span>Amount due:</span>
                        <span style={{float: "right"}}>${(this.state.quantity * this.state.price).toFixed(2)}</span>
                    </div>
                    
                    <div style={(this.state.selectedIndex == -1 || this.state.complete) ? {display: "none"} : {display: "block"}}>
                    </div>
                </div>
            </div>
            <div className="row">
                &nbsp;
            </div>
        </div>
        );
    };
}
 
export default Calendar;