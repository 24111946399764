import * as React from "react";
import {RouteComponentProps,Link} from "react-router-dom";
import { Gear } from "../../Models/Gear";
import { GearAttribute } from "../../Models/GearAttribute";
import { GearAttributeValue } from "../../Models/GearAttributeValue";
import { ModelConfig } from "../../Models/ModelConfig";
import { YearConfig } from "../../Models/YearConfig";
import Field from "../General/Field";
import ImageUploader from "../General/ImageUploader";

// route parameters
type RouteParams = {
    id: string;
}

// component properties
export interface EditProps extends RouteComponentProps<RouteParams> {
    waitHandler: any;
    errorHandler: any;
}

// component state
export interface EditState {
    gearItem: Gear;
    modelConfig: ModelConfig;
    photoUris: string[];
}

// Edit component
class Edit extends React.Component<EditProps, EditState> {
    username = (window as any).username;
    user = (window as any).user;
    constructor(props:EditProps, context:EditState) {
        super(props, context);
        this.state = {
            gearItem: null,
            modelConfig: null,
            photoUris: []
        };
    };

    // componentDidMount
    componentDidMount() {
        // ensure user context
        if (!this.username) { 
            this.props.history.replace("/"); 
        }
        else {
            // fetch the gear item and then get the model details
            this.props.waitHandler(true);
            fetch(`/api/gear/${this.props.match.params.id}`).then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                else
                    return res.json();
            }).then((gearItem: Gear) => {
                // ensure the user can edit this record
                if (this.user.IsAdmin || 
                    (gearItem.ownedBy && gearItem.ownedBy == this.username) ||
                    (!gearItem.ownedBy && gearItem.createdBy && gearItem.createdBy == this.username)) {
                    if (gearItem) {
                        // save state
                        let photoUris:string[] = [];
                        gearItem.photos.forEach((id:string, i:number) => {
                            photoUris.push(`https://guitarlogsv2.blob.core.windows.net/gear/${gearItem.id}/thumb_${id}`);
                        });

                        // fetch the modelConfig
                        fetch(`/api/modelconfig/${gearItem.modelId}`).then((r: any) => {
                            if (!r.ok) {
                                this.props.waitHandler(false);
                                this.props.errorHandler(r.statusText);
                            }
                            else
                                return r.json();
                        }).then((modelConfig: ModelConfig) => {
                            if (modelConfig) {
                                // initialize default values on gear item
                                let year = modelConfig.years.find(i => i.id == gearItem.yearId);
                                if (year) {
                                    year.attributes.forEach((attr:GearAttribute, i:number) => {
                                        // look for existing item if attribute has default value
                                        if (attr.defaultValue && attr.defaultValue.length > 0) {
                                            let found = false;
                                            for (var j = 0; j < gearItem.attributes.length; j++) {
                                                if (gearItem.attributes[j].attributeId == attr.id) {
                                                    found = true;
                                                    break;
                                                }
                                            }
                                            if (!found) {
                                                gearItem.attributes.push({
                                                    attributeId: attr.id,
                                                    attributeName: attr.displayName,
                                                    attributeValue: attr.defaultValue
                                                })
                                            }
                                        }
                                    });
                                }

                                // save the state
                                this.setState({
                                    modelConfig: modelConfig, 
                                    gearItem: gearItem, 
                                    photoUris: photoUris
                                });
                                this.props.waitHandler(false);
                            }
                        });
                    }
                }
                else {
                    this.props.waitHandler(false);
                    (window as any).history.go(-1);
                }
            });
        }
    };

    getValue = (attributeId:string) => {
        for (var i = 0; i < this.state.gearItem.attributes.length; i++) {
            if (this.state.gearItem.attributes[i].attributeId == attributeId)
                return this.state.gearItem.attributes[i].attributeValue;
        }
        return "";
    };

    // handles input changes
    handleInputChange(event:any) {
        let fieldName = event.target.name;
        let fieldVal = event.target.value;
        var gearItem = this.state.gearItem;
        if (event.target.type == "checkbox") {
            fieldVal = event.target.checked;
        }
            
        gearItem[fieldName] = fieldVal;
        this.setState({gearItem: gearItem});
    };

    fieldChangeHandler = (attributeIndex:number, event:any) => {
        let gearItem:Gear = this.state.gearItem;
        let attribute:GearAttribute = this.state.modelConfig.years.find(i => i.id == gearItem.yearId).attributes[attributeIndex];

        // look for existing attribute value
        let val:GearAttributeValue = gearItem.attributes.find(i => i.attributeId == attribute.id);
        if (val) {
            // update value
            val.attributeValue = (event.target.type == "checkbox") ? event.target.checked + "" : event.target.value;
        }
        else {
            // add the value
            gearItem.attributes.push({
                attributeId: attribute.id,
                attributeName: attribute.displayName,
                attributeValue: (event.target.type == "checkbox") ? event.target.checked + "" : event.target.value
            });
        }

        // save the state
        this.setState({gearItem: gearItem});
    };

    update = () => {
        this.props.waitHandler(true);
        let context = this;
        fetch(`/api/gear/${this.state.gearItem.id}`, {
            method: "PATCH",
            body: JSON.stringify(this.state.gearItem),
            headers: {
                "accept": "application/json",
                "content-type": "application/json"
            }
        }).then(function(res) {
            if (!res.ok) {
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            else {
                context.props.waitHandler(false);
                context.props.history.push(`/gear/detail/${context.state.gearItem.id}`);
            }
        });
    };

    // this is a toggle used to help re-render the component
    refreshImages = () => {};//this.setState({refresh: !this.state.refresh});

    multiFilesPicked = (fileInfo:any) => {
        // add the new file to state using the local URI
        let photoUris = this.state.photoUris;
        let photosLength = photoUris.length;
        photoUris.push(fileInfo.photo);
        this.setState({photoUris: photoUris});
        
        // initialize the form data
        let formData = new FormData();
        formData.append(fileInfo.name, fileInfo.file);
        let context = this;
        fetch(`/api/gear/${this.state.gearItem.id}/photo`, {
            method: "POST",
            body: formData
        }).then(function(res) {
            if (!res.ok) {
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            else {
                return res.text();
            }
        }).then((photoId) => {
            let photoUris = context.state.photoUris;
            let gearItem = context.state.gearItem;
            gearItem.photos.push(photoId);
            photoUris[photosLength] = `https://guitarlogsv2.blob.core.windows.net/gear/${this.state.gearItem.id}/thumb_${photoId}`;
            this.setState({gearItem: gearItem, photoUris: photoUris});
        });
    };

    fileDeleted = (index:number) => {
        var photos = this.state.photoUris;
        var gearItem = this.state.gearItem;
        photos.splice(index, 1);
        gearItem.photos.splice(index, 1);
        this.setState({photoUris: photos, gearItem: gearItem});
    };

    reorderPic = (index:number, type:number) => {
        var photoUris = this.state.photoUris;
        var gearItem = this.state.gearItem;

        // get instance of item in array
        let item = photoUris[index];
        let id = gearItem.photos[index];

        // remove existing item from array
        photoUris.splice(index, 1);
        gearItem.photos.splice(index, 1);

        // determine new index for item
        let newIndex:number = 0;
        if (type == 1)
            newIndex = 0;
        else if (type == 2)
            newIndex = index - 1;
        else if (type == 3)
            newIndex = index + 1;
        else if (type == 4)
            newIndex = photoUris.length;

        // add the item back in the correct location
        console.log(`Move ${index} to ${newIndex}`);
        photoUris.splice(newIndex, 0, item);
        gearItem.photos.splice(newIndex, 0, id);
        this.setState({photoUris: photoUris, gearItem: gearItem});
    };

    // renders the component
    render() {
        if (!this.state.gearItem || !this.state.modelConfig)
            return (<></>);

        let yearConfig:YearConfig = this.state.modelConfig.years.find(i => i.id == this.state.gearItem.yearId);
        let fields = [];
        if (yearConfig) {
            fields = yearConfig.attributes.map((gearAttribute:GearAttribute, index:number) => (
                <Field attribute={gearAttribute} attributeIndex={index} value={this.getValue(gearAttribute.id)} onChangeHandler={this.fieldChangeHandler}></Field>
            ));
        }
        let title = (this.state.gearItem.year) ? `${this.state.gearItem.year} ${this.state.gearItem.make} ${this.state.gearItem.model} (${this.state.gearItem.serialNumber})` : `${this.state.gearItem.make} ${this.state.gearItem.model} (${this.state.gearItem.serialNumber})`;
        
        let statusOptions = [];
        statusOptions = [
            <option></option>,
            <option value="Stolen">Stolen</option>,
            <option value="Destroyed">Destroyed</option>,
            <option value="Parted out">Parted out</option>,
            <option value="Unknown">Unknown</option>,
        ];
        if (this.state.gearItem.ownedBy == this.username) {
            statusOptions.push(<option value="Not for sale">Not for sale</option>);
            statusOptions.push(<option value="Entertaining offers">Entertaining offers</option>);
            statusOptions.push(<option value="For sale">For sale</option>);
        }

        let price = (<></>);
        if (this.state.gearItem.status == "For sale") {
            price = (
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label htmlFor="ctrlPrice">Asking price&nbsp;</label>
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <input className="form-control" id="ctrlPrice" name="price" value={this.state.gearItem.price} onChange={this.handleInputChange.bind(this)} />
                    </div>
                </div>);
        }

        return (
        <div className="page">
            <h1>{title}</h1>
            <div>
                {fields}
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label htmlFor="ctrldescription">Description&nbsp;</label>
                        <a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title="Description" data-html="true" data-content="Should include anything changed or modified on the item, including but not limited to finish, decals, frets, pickups, wiring, etc." tabIndex={0}>?</a>
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <textarea className="form-control" id="ctrldescription" name="description" value={this.state.gearItem.description} onChange={this.handleInputChange.bind(this)}></textarea>
                        <small id="ctrldescriptionHelp" className="form-text text-muted">e.g. Body refinished, refret, changed pots, etc</small>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label htmlFor="ctrlNotableOwnership">Noteable Ownership&nbsp;</label>
                        <a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title="Noteable ownership" data-html="true" data-content="List any noteable owners in the items history" tabIndex={0}>?</a>
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <textarea className="form-control" id="ctrlNotableOwnership" name="notableOwnership" value={this.state.gearItem.notableOwnership} onChange={this.handleInputChange.bind(this)}></textarea>
                        <small id="ctrlNotableOwnershipHelp" className="form-text text-muted">e.g. Jimi Hendrix, Eric Johnson, etc</small>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label htmlFor="ctrlStatus">What is the status of the item?&nbsp;</label>
                        <a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title="Item status" data-html="true" data-content="Let members of the site know the status of this item. If selling or willing to entertain offers, this could alert thousands of interested buyers." tabIndex={0}>?</a>
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <select className="form-control" id="ctrlStatus" name="status" value={this.state.gearItem.status} onChange={this.handleInputChange.bind(this)}>
                            {statusOptions}
                        </select>
                        <small id="ctrlStatusHelp" className="form-text text-muted"></small>
                        <div className="invalid-feedback">
                            
                        </div>
                    </div>
                </div>
                {price}
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label htmlFor="ctrlvideoLink">Video Link&nbsp;</label>
                        <a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title="Video Link" data-html="true" data-content="Link to video featuring the item such as YouTube" tabIndex={0}>?</a>
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <input type="text" className="form-control" id="ctrlvideoLink" name="videoLink" value={this.state.gearItem.videoLink} onChange={this.handleInputChange.bind(this)} />
                        <small id="ctrlvideoLinkHelp" className="form-text text-muted">e.g. YouTube link, etc</small>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-form-label">
                        <label>Apply watermark&nbsp;</label>
                        <a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title="Watermarks" data-html="true" data-content="If you are worried about the photos you upload being used in malicious ways, we can apply a watermark to them. Please note that the we store the original photos and apply a dynamic watermark in real-time. This means you can easily toggle the watermark on/off at your discretion." tabIndex={0}>?</a>
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7">
                        <div className="custom-control custom-switch" style={{paddingTop: "7px", paddingBottom: "7px"}}>
                            <input type="checkbox" className="custom-control-input" id="ctrlapplyWatermark" name="applyWatermark" checked={this.state.gearItem.applyWatermark} onChange={this.handleInputChange.bind(this)} />
                            <label className="custom-control-label" htmlFor="ctrlapplyWatermark">Turn on/off watermark</label>
                        </div>
                    </div>
                </div>
                <div className="form-group row" style={{padding: "15px"}}>
                    <ImageUploader filesPicked={this.multiFilesPicked.bind(this)} images={this.state.photoUris} refresh={this.refreshImages} multiple={true} header={"Photos"} allowDelete={true} onDelete={this.fileDeleted.bind(this)} onReorder={this.reorderPic.bind(this)} immediateUpload={true} />
                </div>
                <div className="form-group row" style={{paddingBottom: "30px", marginBottom: "0px"}}>
                    <div className="col-xl-12" style={{textAlign: "center"}}>
                        <button className="btn btn-primary" onClick={this.update.bind(this, false)}>Save</button>
                    </div>
                </div>
            </div>
        </div>
        );
    };

    componentDidUpdate() {
        // prep popovers
        (window as any).popoverPrep();
    };
}
 
export default Edit;