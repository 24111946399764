import * as React from "react";
import { Link } from "react-router-dom";

// properties for the CoverageMap component
export interface CoverageMapProps {
    
}
  
// state for the CoverageMap component
export interface CoverageMapState {
    serialNumbers: any[];
    selectedView:string;
    selectedQuery:any;
}
 
// alert component for displaying error messages
class CoverageMap extends React.Component<CoverageMapProps, CoverageMapState> {
    private blackguards = {
        min: 1,
        max: 5500,
        tickSize: 500,
        query: { collection: "Blackguards" }
    };
    private strats54 = {
        min: 1,
        max: 1200,
        tickSize: 100,
        query: { model: "Stratocaster", yearMin: 1954, yearMax: 1954, serialMin: "0001", serialMax: "1200" }
    };
    constructor(props:CoverageMapProps, context:CoverageMapState) {
        super(props, context);
        this.state = {
            serialNumbers: [],
            selectedView: "Blackguards",
            selectedQuery: this.blackguards
        };
    };
    
    componentDidMount = () => {
        this.performQuery(this.state.selectedView);
    };

    performQuery = (view:string) => {
        let gearQuery = (view == "Blackguards") ? { collection: "Blackguards" } : { model: "Stratocaster", yearMin: 1954, yearMax: 1954, serialMin: "0001", serialMax: "1200" };
        fetch("/api/gear/queryserial", {
            method: "POST",
            body: JSON.stringify(gearQuery),
            headers: {
                "accept": "application/json",
                "content-type": "application/json"
            }
        }).then(function(res) {
            if (res.ok) {
                return res.json();
            }
        }).then((results:any[]) => {
            this.setState({serialNumbers: results});
        });
    };

    viewChanged = (view:string) => {
        this.setState({selectedView: view, selectedQuery: (view == "Blackguards") ? this.blackguards : this.strats54 });
        this.performQuery(view);
    };

    // renders the component
    render() {
        let tickCount = this.state.selectedQuery.max / this.state.selectedQuery.tickSize;
        let ticks = [];
        let legend = [];
        for (var i = 0; i <= tickCount; i++) {
            let x = (i * this.state.selectedQuery.tickSize) / this.state.selectedQuery.max * 1000 + 20;
            let text = (i * this.state.selectedQuery.tickSize) + "";
            while (text.length < 4)
                text = "0" + text;
            
            ticks.push(<line x1={x + "px"} y1="10px" x2={x + "px"} y2="114px" style={{strokeWidth: "1", stroke: "red"}} />)
            legend.push(<text x={(x - 15) + "px"} y="125px" style={{fontSize: "12px", stroke: "#252525"}}>{text}</text>)
        }

        let recs = this.state.serialNumbers.map((serial:string, index:number) => {
            let width = 1.0 / this.state.selectedQuery.max * 1000;
            let x = (parseInt(serial) - 1.0) * width + 20.0;
            return (<rect x={x + "px"} y="10px" width={width + "px"} height="100px" style={{fill: "#f3c057", strokeWidth: "0px"}}/>)
        });

        return (
        <div>
            <div className="btn-group" role="group" style={{marginBottom: "10px"}}>
                <button type="button" className={this.state.selectedView == "Blackguards" ? "btn btn-primary" : "btn btn-light" } onClick={this.viewChanged.bind(this, "Blackguards")}>Blackguards</button>
                <button type="button" className={this.state.selectedView == "1954 Stratocasters" ? "btn btn-primary" : "btn btn-light" } onClick={this.viewChanged.bind(this, "1954 Stratocasters")}>1954 Stratocasters</button>
            </div>
            <svg id="myCanvas" width="100%" height="120px" viewBox="0 0 1040 120">
                <g>
                <rect x="20px" y="10px" width="1000px" height="100px" style={{fill: "#111111", strokeWidth: "0px"}} />
                <line x1="20px" y1="10px" x2="1020px" y2="10px" style={{strokeWidth: "1", stroke: "#252525"}} />
                <line x1="20px" y1="110px" x2="1020px" y2="110px" style={{strokeWidth: "1", stroke: "#252525"}} />
                <line x1="20px" y1="10px" x2="20px" y2="110px" style={{strokeWidth: "1", stroke: "#252525"}} />
                <line x1="1020px" y1="10px" x2="1020px" y2="110px" style={{strokeWidth: "1", stroke: "#252525"}} />
                {recs}
                {ticks}
                {legend}
                </g>
            </svg>
            <p>This graphic illustrates serial number coverage in the log, where yellow represents serial numbers logged and black represents gaps in the log.</p>
        </div>);
    };
}
 
export default CoverageMap;