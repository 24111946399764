import * as React from "react";
import {Link} from "react-router-dom";

// Privacy component
class Privacy extends React.Component {
    // renders the component
    render() {
        return (
        <div className="page">
            <h1>Privacy</h1>
            <div className="row">
                <div className="col-sm-12">
                    <p>The privacy of our members and their information is hugely important to us. In short, GuitarLogs.com would not exist without the information our members provide. This page outlines specific details of our privacy policy.</p>
                    <h3>Information we collect on you</h3>
                    <p>We collect several types of information from and about users of this website, including information by which you may be personally identified, such as name, e-mail address, and profile photo. All of this information is used to identify members and is not used in for-profit purposes. Throughout the site members are referred to by their selected username and actual name/email are never exposed.</p>
                    <h3>Information you provide us</h3>
                    <p>Registry information, including data, photos, sources, and ownership are collected to provide guitar registry information to visitors and members. Images uploaded to the site are stored in their original format, but members can optionally apply a watermark to protect them as personal property. Images can also be permanently removed from a log entry. The data represented is for hobbyist purposes only.  The site and site owner cannot be held responsible for data inaccuracies.  By using this website and its data, you are accepting all liability for any action and/or transaction.</p>
                    <h3>Use of Cookies</h3>
                    <p>This site uses cookies to provide a good user experience to members. Cookies are small chunks of data a website can store on your device to help identify you between visits. This prevents you from having to sign-in at each visit. You can delete cookies using your browser settings or simply sign-out of the site to delete the cookies specific to this site.</p>
                    <h3>Data Security</h3>
                    <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls and transported securely over encrypted secure socket layers (SSL). Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our site. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the site. Although the site provides a traditional username/password login, you should consider using one of the social login options (Facebook, Google, Microsoft, Twitter) as the security of those platforms extends to this site and it gets us out of the business of managing passwords.</p>
                    <h3>General Data Protection Regulation (GDPR)</h3>
                    <p>If you want your account or data deleted, email me at <a href="mailto:officialguitarlogs@gmail.com">officialguitarlogs@gmail.com</a> and I will delete everything related to your account.</p>
                </div>
            </div>
        </div>
        );
    };
}
 
export default Privacy;