import * as React from "react";
import {
    RouteComponentProps,
    Link
} from "react-router-dom";
import { Account } from "../../Models/Account";
import { GearLite } from "../../Models/GearLite";
 
// route parameters
type RouteParams = {
    username: string;
}

// component properties
export interface ProfileProps extends RouteComponentProps<RouteParams> {
    waitHandler: any;
    errorHandler: any;
}

// component state
export interface ProfileState {
    account:Account;
    isSelf:boolean;
    dirty:boolean;
    accountPhoto:any;
    existingIG:string;
}

// Profile component
class Profile extends React.Component<ProfileProps, ProfileState> {
    username = (window as any).username;
    constructor(props:ProfileProps, context:ProfileState) {
        super(props, context);
        this.state = { 
            account: {
                username: "",
                memberType: "",
                createdDate: null,
                points: 0,
                ownedGear: [],
                submittedGear: [],
            },
            isSelf: false,
            dirty: false,
            accountPhoto: null,
            existingIG: "" 
        };
    };

    // componentDidMount
    componentDidMount() {
        // prep popovers
        (window as any).popoverPrep();

        // ensure user context
        if (!this.username && !this.props.match.params.username) { 
            this.props.history.replace("/"); 
        }
        else {
            // toggle waiting indicator while we fetch account
            this.props.waitHandler(true);

            // check which account to lookup
            var endpoint = "/api/account";
            if (this.props.match.params.username) {
                endpoint += `/${this.props.match.params.username}`;
            }
            else {
                this.setState({isSelf: true});
            }

            // perform the fetch
            fetch(endpoint).then((res: any) => {
                if (!res.ok) {
                    this.props.waitHandler(false);
                    this.props.errorHandler(res.statusText);
                }
                return res.json();
            }).then((account: Account) => {
                this.setState({account: account, existingIG: account.instagramUsername});
                this.props.waitHandler(false);
            });
        }
    };

    // gets the approval status text
    getApprovalStatus(n:number) {
        if (n == 0)
            return "Pending";
        else if (n == 1)
            return "Approved";
        else
            return "Rejected";
    };

    // fires when a file is picked
    filePicked(evt:any) {
        let context = this;
        for (var i = 0; i < evt.target.files.length; i++) {
            this.readFile(evt.target.files[i]).then((photo:any) => {
                this.setState({account: {...this.state.account, photo: photo}, dirty: true});
            });

            let file = {
                name: evt.target.files[0].name,
                file: evt.target.files[0]
            };
            this.setState({accountPhoto: file});
        }
    };

    // reads a file into a base64 encoded string
    readFile(file:any) {
        return new Promise(async (resolve, reject) => {
            var reader = new FileReader();
            reader.onloadend = function() {
                resolve(reader.result);
            }
            reader.readAsDataURL(file);
        });
    };

    // saves the new account picture
    save() {
        this.props.waitHandler(true);

        // initialize the form data
        let formData = new FormData();
        formData.append(this.state.accountPhoto.name, this.state.accountPhoto.file);

        // submit the creation
        let context = this;
        fetch(`/api/account/${this.username}/upload`, {
            method: "POST",
            body: formData
        }).then(function(res) {
            if (!res.ok) {
                // save failed...show error message
                context.props.waitHandler(false);
                context.props.errorHandler(res.statusText);
            }
            return res.json();
        }).then(function(jsonResponse) {
            console.log(jsonResponse);
            context.props.waitHandler(false);
            context.props.errorHandler(null);
            context.setState({dirty: false});
        });
    };

    // handles input change
    handleInputChange(event:any) {
        let fieldName = event.target.name;
        let fieldVal = (fieldName == "instagramUsername") ? event.target.value : event.target.checked;
        var account = this.state.account;
        account[fieldName] = fieldVal;
        this.setState({account: account});

        if (fieldName != "instagramUsername") {
            let payload = {};
            payload[fieldName] = `${fieldVal}`;
            this.saveProp(payload);
        }
    }

    saveProp = (payload:any) => {
        // perform update
        let context = this;
        fetch(`/api/account/${this.username}/settings`, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: {
                "accept": "application/json",
                "content-type": "application/json"
            }
        }).then(function(res) {
            if (!res.ok) {
                // save failed...show error message
                context.props.errorHandler(res.statusText);
            }
        });
    };

    saveIG = () => {
        let payload = {};
        payload["instagramUsername"] = this.state.account.instagramUsername;
        this.saveProp(payload);
        this.setState({existingIG: this.state.account.instagramUsername});
        ($ as any)("#modalConfirm").modal("hide");
    };

    cancelIG = () => {
        // reset any changes to the IG value
        this.setState(prevState => ({account: { ...prevState.account, instagramUsername: this.state.existingIG}}));
        ($ as any)("#modalConfirm").modal("hide");
    };

    // renders the component
    render() {
        if (!this.state.account)
            return (<></>);

        let owned:any = this.state.account.ownedGear.map((g:GearLite) => {
            let editLink = (this.username  && this.username  == this.state.account.username) ? (<Link to={`/gear/edit/${g.id}`} style={{paddingLeft: "8px"}}><i className="fas fa-edit"></i></Link>) : (<></>);
            return (<tr>
                <td><Link to={`/gear/detail/${g.id}`}>{g.year}&nbsp;{g.make}&nbsp;{g.model}&nbsp;({g.serialNumber})</Link>{editLink}</td>
                <td>{(new Date(g.createdDate)).toLocaleString()}</td>
                <td>{this.getApprovalStatus(g.approvalStatus)}</td>
            </tr>)
        });
        if (this.state.account.ownedGear.length == 0)
            owned = (<tr><td colSpan={3} style={{textAlign: "center"}}>No items exist</td></tr>);

        let submitted:any = this.state.account.submittedGear.map((g:GearLite) => {
            let editLink = (this.username  && this.username  == this.state.account.username) ? (<Link to={`/gear/edit/${g.id}`} style={{paddingLeft: "8px"}}><i className="fas fa-edit"></i></Link>) : (<></>);
            return (<tr>
                <td><Link to={`/gear/detail/${g.id}`}>{g.year}&nbsp;{g.make}&nbsp;{g.model}&nbsp;({g.serialNumber})</Link>{editLink}</td>
                <td>{(new Date(g.createdDate)).toLocaleString()}</td>
                <td>{this.getApprovalStatus(g.approvalStatus)}</td>
            </tr>)
        });
        if (this.state.account.submittedGear.length == 0)
            submitted = (<tr><td colSpan={3} style={{textAlign: "center"}}>No items exist</td></tr>);

        let filePickStyle = {};
        if (this.props.match.params.username)
            filePickStyle = {display: "none"};
        else
            filePickStyle = {cursor: "pointer"};

        let settings = (
            <div className="detail-row">
                <div className="profile-prop">Account Settings&nbsp;<a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title="Account Settings" data-html="true" data-content="<p><b>Email Info</b>: Want us to update you on general site news and offers?</p><p><b>Email Notifications</b>: Want us to email you when you have a new site notification such as someone interested in your guitar or a submission approval?</p><p><b>Allow Photos Featured</b>: Want to allow your guitar photos to be featured by the site?</p>" tabIndex={2}>?</a></div>
                <div className="profile-prop-val">
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="ctrlEmailInfo" name="emailInfo" checked={this.state.account.emailInfo} onChange={this.handleInputChange.bind(this)} tabIndex={3} />
                        <label className="custom-control-label" htmlFor="ctrlEmailInfo">Email Info</label>
                    </div>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="ctrlEmailNotifications" name="emailNotifications" checked={this.state.account.emailNotifications} onChange={this.handleInputChange.bind(this)} tabIndex={4} />
                        <label className="custom-control-label" htmlFor="ctrlEmailNotifications">Email Notifications</label>
                    </div>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="ctrlAllowPhotoUse" name="allowPhotoUse" checked={this.state.account.allowPhotoUse} onChange={this.handleInputChange.bind(this)} tabIndex={5} />
                        <label className="custom-control-label" htmlFor="ctrlAllowPhotoUse">Allow Photos Featured</label>
                    </div>
                </div>
            </div>
        );
        let ig = (<div className="profile-prop">Instagram Username&nbsp;<a className="badge badge-pill badge-secondary" style={{cursor: "pointer"}} data-toggle="popover" title="What are points?" data-html="true" data-content="If you have &quot;Allow Photos Featured&quot; set to Yes, we'd like to credit you in social posts. It also allows members to find/follow you." tabIndex={1}>?</a></div>);
        if (this.props.match.params.username) {
            settings = null;
            ig = (<div className="profile-prop">Instagram Username</div>);
        }

        return (
        <div className="page">
            <div className="profile-container">
                <div className="photo-container">
                    <img src={this.state.account.photo} />
                    <input id="imgPick" type="file" accept="image/x-png,image/jpeg" style={filePickStyle} onChange={this.filePicked.bind(this)} />
                    <label htmlFor="imgPick" className="btn btn-link" style={filePickStyle}>change</label>
                    <button className="btn btn-link" onClick={this.save.bind(this)} style={{display: (this.state.dirty) ? "block" : "none"}}>save</button>
                </div>
                <div className="profile-details">
                    <div className="profile-details-inner">
                        <div className="detail-row">
                            <div className="profile-prop">Log Username</div>
                            <div className="profile-prop-val">{(this.username && this.username != null && this.username  != this.state.account.username) ? (<Link to={"/threads/create/" + this.state.account.username}>{this.state.account.username}&nbsp;<i className="far fa-envelope"></i></Link>) : this.state.account.username}</div>
                        </div>
                        <div className="detail-row">
                            {ig}
                            <div className="profile-prop-val">{this.state.account.instagramUsername}&nbsp;{(this.username && this.username != null && this.username == this.state.account.username) ? <i className="fas fa-edit" onClick={() => ($ as any)("#modalConfirm").modal({backdrop: "static"}, "show")}></i> : <></>}</div>
                        </div>
                        <div className="detail-row">
                            <div className="profile-prop">Member Type</div>
                            <div className="profile-prop-val">{this.state.account.memberType}</div>
                        </div>
                        <div className="detail-row">
                            <div className="profile-prop">Joined</div>
                            <div className="profile-prop-val">{(new Date(this.state.account.createdDate)).toLocaleString()}</div>
                        </div>  
                        <div className="detail-row">
                            <div className="profile-prop">Points</div>
                            <div className="profile-prop-val">{this.state.account.points}</div>
                        </div>
                        {settings}
                    </div>
                </div>
            </div>

            <h3>Owned gear</h3>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th style={{width: "50%"}}>Gear</th>
                        <th style={{width: "30%"}}>Date Recorded</th>
                        <th style={{width: "20%"}}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {owned}
                </tbody>
            </table>
            <h3>Submitted gear</h3>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th style={{width: "50%"}}>Gear</th>
                        <th style={{width: "30%"}}>Date Recorded</th>
                        <th style={{width: "20%"}}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {submitted}
                </tbody>
            </table>

            <div className="modal fade" id="modalConfirm" role="dialog" aria-labelledby="modalConfirmTitle" aria-hidden="false">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalConfirmTitle">Link to Instagram</h5>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-xl-4 col-lg-5 col-md-6 col-form-label">
                                    <label htmlFor="ctrlDisplayName">Instagram Username&nbsp;</label>
                                </div>
                                <div className="col-xl-8 col-lg-7 col-md-6">
                                    <input type="text" className="form-control" id="ctrlInstagramUsername" placeholder="Instagram Username" name="instagramUsername" value={this.state.account.instagramUsername} onChange={this.handleInputChange.bind(this)} />
                                    <div className="invalid-feedback">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.saveIG.bind(this)}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={this.cancelIG.bind(this)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>
        </div>
        );
    };
}
 
export default Profile;