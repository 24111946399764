import * as React from "react";
import {Link} from "react-router-dom";
import About from "../General/About";

// Faq component
class Faq extends React.Component {
    // renders the component
    render() {
        return (
        <div className="page">
            <h1>FAQ</h1>
            <div className="row">
                <div className="col-sm-12">
                    <h3>What is GuitarLogs.com</h3>
                    <About/>
                    <h3>How can I submit an entry?</h3>
                    <p>To submit entries into the log, you must register/sign-in and then use the <Link to="/gear/create">Register Gear</Link> link. Depending on the detail of information you provide, the submission might require approval from a site administrator. See the <Link to="/help/new">Create new entry</Link> help topic for details.</p>
                    <h3>How can I submit info on a guitar that isn't mine?</h3>
                    <p>If the guitar is not in the log already, you can still submit the data through the <Link to="/gear/create">Register Gear</Link> link as a "non-owner" (must be signed in). If the guitar is already in the log, you can anonymously provide additional info by clicking the "got info?" link at the top of the guitar's detail page. See the <Link to="/help/provide">Provide info on entry</Link> help topic for details.</p>
                    <h3>What if my model/year available for registration?</h3>
                    <p>For the log to support a new model/year, we need to configure the attributes relevant to that model/year (which can take time). If you would like a new model/year represented in the log, please send the details to the site administrator <Link to="/threads/create/richdizz">richdizz</Link> (must be signed in) or email <a href="mailto:officialguitarlogs@gmail.com">officialguitarlogs@gmail.com</a>.</p>
                    <h3>What if I don't want to disclose my serial number?</h3>
                    <p>While we respect your privacy, we don't understand serial number secrecy. The more serial numbers are out in the open, the harder it is for someone the fraudulently represent it as theirs. A scammer is going to go after a serial number NOT out in public. Don't believe me? The Les Paul world has burstserial.com with a very high coverage of bursts...scammers go after the holes in this registry.</p>
                    <h3>Will someone use my photos maliciously?</h3>
                    <p>Yes, there are assholes in this world that will try to take photos of nice guitars and try to represent them as their own in online auctions and other malicious ways. GuitarLogs.com offers a permanent watermark feature if you really have this concern. In the end, an asshole will find a way to be an asshole and we shouldn't let that stop the good-willed efforts of an enthusiast community.</p>
                    <h3>My submission isn't showing up in the log</h3>
                    <p>If the information you provided in the submission can't be automatically verified, it might require manual approval by a site administrator. This shouldn't take longer than a few days, so thanks in advance for you patience.</p>
                    <h3>What if my guitar/gear is already registered in the site?</h3>
                    <p>If your guitar or gear is already represented in the site, it was likely submitted by a previous owner or enthusiast that found the guitar somewhere online or in a book. You can "claim ownership" of the entry if you register/sign-in and click the "claim" link on the item's detail page. Claiming ownership requires that you provide some information to validate the ownership. See the <Link to="/help/claim">Claim ownership</Link> help topic for details.</p>
                    <h3>What if someone posted my/copyright photos?</h3>
                    <p>This is a community-source log, so it is possible for a community member to post photos from any source. If you have a problem with something that is posted, please send the details to the site administrator <Link to="/threads/create/richdizz">richdizz</Link> (must be signed in) or email <a href="mailto:officialguitarlogs@gmail.com">officialguitarlogs@gmail.com</a>.</p>
                </div>
            </div>
        </div>
        );
    };
}
 
export default Faq;