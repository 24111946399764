import * as React from "react";
import {
    RouteComponentProps,
    Link
} from "react-router-dom";
import { Supporter } from "../../Models/Supporter";
 
// component properties
export interface SupportersProps extends RouteComponentProps<any> {
    waitHandler: any;
    errorHandler: any;
}

// component state
export interface SupportersState {
    supporters:Supporter[];
}

// Sponsors component
class Supporters extends React.Component<SupportersProps, SupportersState> {
    constructor(props:SupportersProps, context:SupportersState) {
        super(props, context);
        this.state = {
            supporters: []
        }
    };

    // componentDidMount
    componentDidMount() {
        this.props.waitHandler(true);
        // fetch the sponsors
        fetch("/api/sponsor").then((res: any) => {
            if (!res.ok) {
                this.props.waitHandler(false);
                this.props.errorHandler(res.statusText);
            }
            else
                return res.json();
        }).then((supporters:Supporter[]) => {
            if (supporters) {
                this.setState({supporters: supporters});
                this.props.waitHandler(false);
            }
        });
    };

    // renders the component
    render() {
        let supporters = this.state.supporters.map((sponsor:any) => (
            <div className="col-lg-4 col-md-6 col-sm-12" style={{paddingBottom: "30px"}}>
                <h4>{sponsor.name}</h4>
                <a href={sponsor.uri}><img src={sponsor.photo} className="img img-fluid" alt={sponsor.name}/></a>
                <div>{sponsor.description}</div>
            </div>
        ));

        return (
        <div className="container">
            <h1>Supporters</h1>
            <div className="row">
                {supporters}
            </div>
            &nbsp;
        </div>
        );
    };
}
 
export default Supporters;